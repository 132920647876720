import { getRequest, postRequest } from '@/helper'

let widgetsModule = {
    state:{},
    getters:{},
    actions:{
        getUserWidgets: async ({ commit }, {page = 1, limit = '',field = '' ,direction = '', search = '', category_id = ''}) => {
            if(category_id == null)
            {
                category_id = ''
            }

            let resp = await getRequest(`users/widgets?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}&category_id=${category_id}`)
            return resp;
        },
        getUserWidget: async ({ commit }, id) => {
            let resp = await getRequest(`users/widget/view/${id}`)
            return resp
        },
        addUserWidget: async({commit}, data) => {
            let { title, config } = data
            let resp = await postRequest(`users/widget/add`, {title, config})
            return resp;
        },
        previewUserWidget: async({commit}, data) => {
            let { title, config } = data
            let resp = await postRequest(`users/widget/preview`, {title, config})
            return resp;
        },
        updateUserWidget: async({commit}, data) => {
            let { id,  title ,config, status } = data
            let resp = await postRequest(`users/widget/${id}/update`, {title ,config, status})
            return resp;
        },
        updateUserWidgetStatus: async({commit}, data) => {
            let { id, status } = data
            let resp = await postRequest(`users/widget/${id}/update-status`, { status})
            return resp;
        },
        deleteUserWidget: async ({ commit }, id) => {
            let resp = await getRequest(`users/widget/${id}/delete`)
            return resp
        }
    },
    mutations:{}
}

export default widgetsModule