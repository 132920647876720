import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Register from '../components/user/register'
import Login from '../components/user/login'
import Dashboard from '../components/user/dashboard'
import ForgetPassword from '../components/user/forgetpassword'
import ResetPassword from '../components/user/resetpassword'
import MyProfile from '../components/user/myprofile'
import Settings from '../components/admin/settings'
import UserList from '../components/admin/userlist'
import AdminProfile from '../components/admin/adminProfile'
import PageNotFound from '../components/pageNotFound'
import Success from '../components/success'
import Cancel from '../components/cancel'
import PaymentStatus from '../components/paymentStatus'
import AddUser from '../components/admin/add-user'
import VerifyOtp from '../components/user/verify'
import AddPost from '../components/user/addPost'
import EditPost from '../components/user/editPost'
import History from '../components/user/history'
import MonthlyFeed from '../components/admin/monthly_feed/index'
import tagsCategoriesRoutes from './admin/tagsCategories'
import tagsRoutes from './admin/tags'
import smartTagsRoutes from './admin/smartTags'
import reviewsRoutes from './admin/reviews'
import businessRoutes from './user/business'
import userReviewsRoutes from './user/reviews'
import userTagsRoutes from './user/tags'
import userPostTemplate from './user/postTemplate'
import widgetRoutes from "./user/widget"

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      authOnly: true,
      showDashLayout: true,
      userOnly: true,
      roles: ['User']
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      showFrontLayout: true,
      auth: false,
      guestOnly: true
    }
  },
  {
    path: '/signup',
    name: 'Register',
    component: Register,
    meta: {
      showFrontLayout: true,
      auth: false,
      guestOnly: true
    }
  },
  {
    path: "/forget-password",
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      showFrontLayout: true,
      auth: false,
      guestOnly: true
    }
  },
  {
    path: "/reset-password",
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      showFrontLayout: true,
      auth: false,
      guestOnly: true
    }
  },
  {
    path: "/verify-otp",
    name: 'VerifyOtp',
    component: VerifyOtp,
    meta: {
      showFrontLayout: true,
      auth: false,
      guestOnly: true
    }
  },
  {
    path: "/profile",
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      auth: true,
      authOnly: true,
      showDashLayout: true,
      roles: ['User']
    }
  },
  {
    path: "/add/post",
    name: 'AddPost',
    component: AddPost,
    meta: {
      auth: true,
      authOnly: true,
      showDashLayout: true,
      roles: ['User']
    }
  },
  {
    path: "/edit/post/:id",
    name: 'EditPost',
    component: EditPost,
    meta: {
      auth: true,
      authOnly: true,
      showDashLayout: true,
      roles: ['User']
    }
  },
  {
    path: "/scheduled-post-history",
    name: 'History',
    component: History,
    meta: {
      auth: true,
      authOnly: true,
      showDashLayout: true,
      roles: ['User']
    }
  },
  {
    path: "/success",
    name: 'Success',
    component: Success,
    meta: {
      auth: true,
      showFrontLayout: true,
      authOnly: true,
      roles: ['User', 'Admin']
    }
  },
  {
    path: "/cancel",
    name: 'Cancel',
    component: Cancel,
    meta: {
      auth: true,
      showFrontLayout: true,
      authOnly: true,
      roles: ['User', 'Admin']
    }
  },
  {
    path: "/payments/due",
    name: 'PaymentStatus',
    component: PaymentStatus,
    meta: {
      auth: true,
      showFrontLayout: true,
      authOnly: true,
      roles: ['User', 'Admin']
    }
  },
  {
    path: "/admin/profile",
    name: 'AdminProfile',
    component: AdminProfile,
    meta: {
      auth: true,
      admin: true,
      authOnly: true,
      showDashLayout: true,
      roles: ['Admin']
    }
  },
  {
    path: "/admin/users",
    name: 'UserList',
    component: UserList,
    meta: {
      admin: true,
      auth: true,
      authOnly: true,
      showDashLayout: true,
      adminOnly: true,
      roles: ['Admin']
    }
  },
  {
    path: "/admin/add/user",
    name: 'AddUser',
    component: AddUser,
    meta: {
      admin: true,
      auth: true,
      authOnly: true,
      showDashLayout: true,
      adminOnly: true,
      roles: ['Admin']
    }
  },
  {
    path: "/admin/monthly-feed",
    name: 'MonthlyFeedList',
    component: MonthlyFeed,
    meta: {
      admin: true,
      auth: true,
      authOnly: true,
      showDashLayout: true,
      adminOnly: true,
      roles: ['Admin']
    }
  },
  {    
    path: "/admin/settings",
    name: 'Settings',
    component: Settings,
    meta: {
      admin: true,
      auth: true,
      authOnly: true,
      showDashLayout: true,
      adminOnly: true,
      roles: [
        'Admin'
      ]
    }
  },
  ...tagsCategoriesRoutes,
  ...tagsRoutes,  
  ...smartTagsRoutes,
  ...reviewsRoutes,
  ...businessRoutes,
  ...userReviewsRoutes,
  ...userTagsRoutes,
  ...userPostTemplate,
  ...widgetRoutes,
  {
    path: "*",
    component: PageNotFound,
    meta: {
      showFrontLayout: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


function isLoggedIn() {
  return localStorage.getItem("token");
}

function userRole() {
  // console.log(localStorage.getItem("role"))
  return localStorage.getItem("role");
}


router.beforeEach((to, from, next) => { // This way, you don't need to write hook for each route
  // get where user being stored ex:
  // console.log(to)
  const user = {
    role: userRole()
  } // assume user have a role with `user.role`
  if (to.meta.authOnly) {
    // console.log(to.meta.authOnly)
    if (isLoggedIn()) {
      if (to.meta?.roles?.includes(user.role)) {
        next()
      } else {
        next('/404-page')
      }
    }
    else {
      next('/login')
    }
  }
  else {
    if (to.fullPath == '/') {
      next('/login')
    } else {
      next()
    }

  }
})
export default router