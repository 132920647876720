import { getRequest, postRequest } from '@/helper'

let smartTagsModule = {
    state:{
        smartTags: {},
        smartTag: [],
        smartTagsDropDown:{},
    },
    getters:{
        smartTags: (state) => {
            return state.smartTags
        },
        smartTag: (state) => {
            return state.smartTag
        },
        smartTagsDropDown: (state) => {
            return state.smartTagsDropDown
        },
    },
    actions:{
        getSmartTags: async ({ commit }, {page = 1, limit = '',field = '' ,direction = '', search = '',category_id = '', tag_id = ''}) => {
            if(category_id == null)
            {
                category_id = ''
            }
            if(tag_id == null)
            {
                tag_id = ''
            }
            
            let resp = await getRequest(`smart-tags?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}&category_id=${category_id}&tag_id=${tag_id}`)
            if(resp && resp.status)
            {
                commit('setSmartTags', resp.data)
                return resp.data
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getSmartTagsDropDown: async ({ commit }, {id = ''}) => {
            
            let resp = await getRequest(`smart-tags/drop-down?tag_id=${id}`)
            if(resp && resp.status)
            {
                commit('setSmartTagsDropDown', resp.data)
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getSmartTag: async ({ commit }, id) => {
            let resp = await getRequest(`smart-tags/view/${id}`)
            commit('setSmartTag', resp.data)
            return resp
        },
        addSmartTag: async({commit}, data) => {
            let {category_id,tag_id ,title, status} = data
            let resp = await postRequest(`smart-tags/add`, {category_id, tag_id, title, status})
            return resp;
        },
        updateSmartTag: async({commit}, data) => {
            let {id, tag_id, category_id, title, status} = data
            let resp = await postRequest(`smart-tags/${id}/edit`, {tag_id, category_id, title, status})
            return resp;
        },
        deleteSmartTag: async ({ commit }, id) => {
            let resp = await getRequest(`smart-tags/${id}/delete`)
            if(resp && resp.status)
            {
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
    },
    mutations:{
        setSmartTags: (state, data) => {
            return state.smartTags = data
        },
        setSmartTag: (state, data) => {
            return state.smartTag = data
        },
        setSmartTagsDropDown: (state, data) => {
            return state.smartTagsDropDown = data
        }
    }
}

export default smartTagsModule