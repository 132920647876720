import tagsClient from '../../components/user/tags/index.vue'

let tagsRoutes = [
	{
        path: '/my-tags',
        name: 'TagsClient',
        component: tagsClient,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
]

export default tagsRoutes
