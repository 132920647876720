import { getRequest, postRequest } from '@/helper'

let tagsCategoriesModule = {
    state:{
        tagCategories: {},
        tagCategoriesDropDown:{},
        tagCategory:[],
    },
    getters:{
        tagCategories: (state) => {
            return state.tagCategories
        },
        tagCategory: (state) => {
            return state.tagCategory
        },
        tagCategoriesDropDown: (state) => {
            return state.tagCategoriesDropDown
        },
    },
    actions:{
        getTagsCategories: async ({ commit }, {page = 1, limit = '',field = '' ,direction = '', search = ''}) => {
            
            let resp = await getRequest(`tags-categories?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}`)
            if(resp && resp.status)
            {
                commit('setTagCategories', resp.data)
                return resp.data
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getTagsCategoriesDropDown: async ({ commit }) => {
            
            let resp = await getRequest(`tags-categories/drop-down`)
            if(resp && resp.status)
            {
                commit('setTagCategoriesDropDown', resp.data)
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getTagCategory: async ({ commit }, id) => {
            let resp = await getRequest(`tags-categories/view/${id}`)
            commit('setTagCategory', resp.data)
            return resp
        },
        addTagCategory: async({commit}, data) => {
            let {title, status} = data
            let resp = await postRequest(`tags-categories/add`, {title, status})
            return resp;
        },
        updateTagCategory: async({commit}, data) => {
            let {id, title, status} = data
            let resp = await postRequest(`tags-categories/${id}/edit`, {title, status})
            return resp;
        },
        deleteTagCategory: async ({ commit }, id) => {
            let resp = await getRequest(`tags-categories/${id}/delete`)
            if(resp && resp.status)
            {
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
    },
    mutations:{
        setTagCategories: (state, data) => {
            return state.tagCategories = data
        },
        setTagCategory: (state, data) => {
            return state.tagCategory = data
        },
        setTagCategoriesDropDown: (state, data) => {
            return state.tagCategoriesDropDown = data
        },
    }
}

export default tagsCategoriesModule