<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding">
                <div class="row container d-flex justify-content-center">
                    <div class="col-xl-12 col-md-12">
                        <div class="card user-card-full">
                            <div class="row m-l-0 m-r-0">
                                <form @submit.prevent="update">
									<div class="card-block">
										<div class="d-flex justify-content-between">
                                            <h6 class="f-w-600" >
                                                Settings
                                            </h6>
                                            <a href="#" @click="downloadLog"> Download Log File</a>
                                        </div>
                                        <hr>
										<div class="row">
											<div class="col-4">
												<label class="m-b-10 f-w-600" for="country">
													Set minimum reviews rating (Bright Local).
													<span class="required">*</span>
												</label>
												<v-select
													id="reviews_min"
                                                    label="value"
													v-model="formFields.reviews_min"
													placeholder="Select Reviews Min"
                                                    :options="reviewsRating"
                                                    :reduce="reviewsRating => reviewsRating.value"
													:class="[$v.formFields.reviews_min.$error ? 'is-invalid' : '']"
												></v-select>
											</div>

											<div class="col-4">
												<label class="m-b-10 f-w-600" for="country">
													Select Social Platforms for reviews Fetch.
													<span class="required">*</span>
												</label>
												<v-select
													id="social_platforms"
													v-model="formFields.social_platforms"
													placeholder="Select social platforms"
													:multiple="true"
													:options="socialPlatforms"
                                                    :reduce="socialPlatforms => socialPlatforms.value"
													:class="[$v.formFields.social_platforms.$error ? 'is-invalid' : '']"
												></v-select>
											</div>

                                            <div class="col-4">
												<label class="m-b-10 f-w-600" for="country">
													Minimum Time Interval Between Review Syncs (minutes)
													<span class="required">*</span>
												</label>
												<input 
                                                    type="text"
                                                    class="form-control"
                                                    v-model="formFields.min_diff_in_fetch_review_request"
													:class="[$v.formFields.min_diff_in_fetch_review_request.$error ? 'is-invalid' : '']"
                                                    />
											</div>
										</div>
                                        <div class="row mt-4 mb-0 d-flex justify-content-center">
                                            <label for="" class="m-b-10 f-w-600">Default Review Post Templates</label>
                                            <div 
                                                class="col-md-2 mb-1 template-card"
                                                @click="() => { formFields.default_review_post_template_id = template.id }"
                                                :class="template.id == formFields.default_review_post_template_id ? 'active' : ''" 
                                                v-for="(template, index) in  templates"
                                                >
                                                <img :src="template.url" class="w-100">
                                            </div>
                                        </div>
                                        
                                        <div class="row mt-2">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label for="color" class="m-b-10 f-w-600">Default Background Color</label>
                                                    <input type="color" id="color" class="form-control" v-model="formFields.default_review_post_background_color" />
                                                </div>
                                            </div>
                                            <div class="col-md-4 d-none">
                                                <div class="mb-3">
                                                    <label for="default_scheduled_type" class="m-b-10 f-w-600">Default Scheduled Monthly/Weekly ?</label>
                                                    <v-select 
                                                        id="default_scheduled_type"
                                                        :clearable="false"
                                                        :options="scheduleType"
                                                        placeholder="Please Select"
                                                        v-model="formFields.default_review_post_schedule_type"
                                                        :reduce="scheduleType => scheduleType.value"
                                                        :class="$v.formFields.default_review_post_schedule_type.$error ? 'is-invalid' : ''"
                                                        />
                                                </div>
                                            </div>
                                            <div class="col-md-4" v-if="formFields.default_review_post_schedule_type == 'week'">
                                                <div class="mb-3" >
                                                    <label for="default_scheduled_week" class="m-b-10 f-w-600">Default Scheduled Week Day</label>
                                                    <v-select 
                                                        id="default_scheduled_week"
                                                        :clearable="false"
                                                        v-model="formFields.default_review_post_schedule_week_day"
                                                        :reduce="weekDays => weekDays.value"
                                                        :options="weekDays"
                                                        :class="$v.formFields.default_review_post_schedule_week_day.$error ? 'is-invalid' : ''"
                                                        placeholder="Please Select"
                                                        />
                                                </div>
                                            </div>
                                            <div class="col-md-4" v-if="formFields.default_review_post_schedule_type == 'month'">
                                                <div class="mb-3 month_day_select" >
                                                    <label for="default_scheduled_month" class="m-b-10 f-w-600">Default Scheduled Month Date</label>
                                                    <v-select 
                                                        id="default_scheduled_month"
                                                        :clearable="false"
                                                        v-model="formFields.default_review_post_schedule_month_date"
                                                        :options="monthsDates"
                                                        :class="$v.formFields.default_review_post_schedule_month_date.$error ? 'is-invalid' : ''"
                                                        placeholder="Please Select"
                                                        />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3 hour_select" v-if="(formFields.default_review_post_schedule_type == 'week' && formFields.default_review_post_schedule_week_day) || (formFields.default_review_post_schedule_type == 'month' && formFields.default_review_post_schedule_month_date)">
                                                    <label for="default_scheduled_hour" class="m-b-10 f-w-600">Default Scheduled Hour</label>
                                                    <v-select 
                                                        id="default_scheduled_hour"
                                                        :clearable="false"    
                                                        v-model="formFields.default_review_post_schedule_hour"
                                                        :reduce="hours => hours.value"
                                                        :options="hours"
                                                        :class="$v.formFields.default_review_post_schedule_hour.$error ? 'is-invalid' : ''"
                                                        placeholder="Please Select"
                                                        />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <div class="mb-3 admin-settings">
                                                    <label for="post_introductory_text" class="m-b-10 f-w-600">Post Introductory Text: </label>
                                                    <textarea class="form-contro d-none" id="post_introductory_text" rows="5" v-model="formFields.post_introductory_text"></textarea>
                                                    <quill-editor
                                                        v-model="formFields.post_introductory_text"
                                                        :options="editorOption"
                                                        :class="[$v.formFields.post_introductory_text.$error ? 'is-invalid' : '']"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 mt-3 d-flex">
												<button
													class="btn btn-success d-flex me-2"
													type="submit"
													:class="loading ? 'disabled' : ''"
												>
													<span>Update</span>
													<span
														v-if="loading"
														class="spinner-border spinner-border-sm m-1"
														role="status"
														aria-hidden="true"
													></span>
												</button>
											</div>
                                        </div>
									</div>
								</form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
    name: 'Settings',
    components: {
        vSelect,
		quillEditor
    },
	computed: {
		...mapGetters(['settings'])
	},
    data: function() {
        return {
            reviewsRating: [
                { labels: `1 ★☆☆☆☆`, value: '1' },
				{ labels: '2 ★★☆☆☆', value: '2' },
				{ labels: '3 ★★★☆☆', value: '3' },
				{ labels: '4 ★★★★☆',value: '4' },
				{ labels: '5 ★★★★★', value: '5' },
            ],
			socialPlatforms:[
				{ label:'Google', value:'google' },
				{ label:'FaceBook', value:'facebook' },
				{ label:'Yelp', value:'yelp' },
			],
            formFields: {
                social_platforms: '',
				reviews_min:'1',
                min_diff_in_fetch_review_request:'',
                default_review_post_template_id:'',
                default_review_post_background_color:'',
                default_review_post_schedule_type:'week',
                default_review_post_schedule_week_day:'',
                default_review_post_schedule_month_date:'',
                default_review_post_schedule_hour:'',
                post_introductory_text:''
            },
			loading:true,
            templates:[],
            scheduleType:[
                {
                    label:'Monthly',
                    value:"month"
                },
                {
                    label:'Weekly',
                    value:"week"
                }
            ],
            weekDays:[
                {
                    label:'Sunday',
                    value:'sunday'
                },
                {
                    label:'Monday',
                    value:'monday'
                },
                {
                    label:'Tuesday',
                    value:'tuesday'
                },
                {
                    label:'Wednesday',
                    value:'wednesday'
                },
                {
                    label:'Thursday',
                    value:'thursday'
                },
                {
                    label:'Friday',
                    value:'friday'
                },
                {
                    label:'Saturday',
                    value:'saturday'
                }
            ],
            hours:[
                {
                    label:'12:00 AM',
                    value:'00:00'
                },
                {
                    label:'01:00 AM',
                    value:'01:00'
                },
                {
                    label:'02:00 AM',
                    value:'02:00'
                },
                {
                    label:'03:00 AM',
                    value:'03:00'
                },
                {
                    label:'04:00 AM',
                    value:'04:00'
                },
                {
                    label:'05:00 AM',
                    value:'05:00'
                },
                {
                    label:'06:00 AM',
                    value:'06:00'
                },
                {
                    label:'07:00 AM',
                    value:'07:00'
                },
                {
                    label:'08:00 AM',
                    value:'08:00'
                },
                {
                    label:'09:00 AM',
                    value:'09:00'
                },
                {
                    label:'10:00 AM',
                    value:'10:00'
                },
                {
                    label:'11:00 AM',
                    value:'11:00'
                },
                {
                    label:'12:00 PM',
                    value:'12:00'
                },
                {
                    label:'01:00 PM',
                    value:'13:00'
                },
                {
                    label:'02:00 PM',
                    value:'14:00'
                },
                {
                    label:'03:00 PM',
                    value:'15:00'
                },
                {
                    label:'04:00 PM',
                    value:'16:00'
                },
                {
                    label:'05:00 PM',
                    value:'17:00'
                },
                {
                    label:'06:00 PM',
                    value:'18:00'
                },
                {
                    label:'07:00 PM',
                    value:'19:00'
                },
                {
                    label:'08:00 PM',
                    value:'20:00'
                },
                {
                    label:'09:00 PM',
                    value:'21:00'
                },
                {
                    label:'10:00 PM',
                    value:'22:00'
                },
                {
                    label:'11:00 PM',
                    value:'23:00'
                }
            ],
            monthsDates:[],
            editorOption: {
				placeholder: "Compose email",
				readOnly: true,
				theme: "snow",
				modules: {
                    toolbar: [
						['bold', 'italic'],
					],
                }
			}
        }
    },
	validations: {
        formFields: {
            social_platforms: {
				required
			},
            reviews_min: {
				required
			},
            min_diff_in_fetch_review_request:{
                required
            },
            default_review_post_template_id:{
                required
            },
            default_review_post_background_color:{
                required
            },
            default_review_post_schedule_type:{
                required
            },
            default_review_post_schedule_week_day:{
                
            },
            default_review_post_schedule_month_date:{
                
            },
            default_review_post_schedule_hour:{
                required
            },
            post_introductory_text:{
                required
            }
        }
    },
    created: function() {
        this.loading = true
        let settingsField = [
            'reviews_min',
            'social_platforms',
            'min_diff_in_fetch_review_request',
            'default_review_post_template_id',
            'default_review_post_background_color',
            'default_review_post_schedule_type',
            'default_review_post_schedule_week_day',
            'default_review_post_schedule_month_date',
            'default_review_post_schedule_hour',
            'post_introductory_text'
        ]
        this.getSetting(settingsField).then((resp) => {
            this.formFields = resp
        })

        this.loading = false
        this.getPostTemplates().then((resp) => {
            if(resp)
            {
                this.templates = resp.data
            }
        })

        for (let index = 1; index <= 31; index++) {
            this.monthsDates.push(index)   
        }

    },
    mounted:function(){
        let removeEventListener = function(){
            document.removeEventListener("contextmenu",window.$disableRightClick, false);
        } 
        window.onload = removeEventListener
        removeEventListener()
    },
    beforeRouteLeave:function(to, from , next) {
        document.addEventListener("contextmenu",window.$disableRightClick, false);
        next()
    },
    methods: {
        ...mapActions([
            'getSetting',
            'downloadLog',
            'updateSetting',
            'getPostTemplates',
            'getUserPostSetting',
            'updateUserPostSetting'
        ]),
        update: function(e){
            this.loading = true
            e.preventDefault()

            this.$v.$touch()
            if(!this.$v.$invalid)
            {
                let temp = {
                    reviews_min: this.formFields.reviews_min,
                    social_platforms: this.formFields.social_platforms,
                    min_diff_in_fetch_review_request: this.formFields.min_diff_in_fetch_review_request,
                    default_review_post_template_id:this.formFields.default_review_post_template_id,
                    default_review_post_background_color:this.formFields.default_review_post_background_color,
                    default_review_post_schedule_type:this.formFields.default_review_post_schedule_type,
                    default_review_post_schedule_week_day:this.formFields.default_review_post_schedule_week_day,
                    default_review_post_schedule_month_date:this.formFields.default_review_post_schedule_month_date,
                    default_review_post_schedule_hour:this.formFields.default_review_post_schedule_hour,
                    post_introductory_text:this.formFields.post_introductory_text
                }
            
                this.updateSetting(temp)
                .then((resp) => {
                    this.loading = false
                    this.$toasted.show(resp.message, {
                        theme: 'bubble',
                        type: 'success',
                        position: 'top-center',
                        duration: 4000,
                        singleton: true,
                    })
                })
                .catch(() => {
                    this.loading = false
                    this.$toasted.show(
                        `Something went wrong,plz try again later`,
                        {
                            theme: 'bubble',
                            type: 'Error',
                            position: 'top-center',
                            duration: 1500,
                            singleton: true,
                        }
                    )
                })
            }
            else
            {
            this.loading = false
                console.log('invalid')
            }
        }
    }
}
</script>

<style scoped>
body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: hidden;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}

.template-card{
    padding:1%;
    width: 200px;
    cursor: pointer;
}
.template-card.active{
    border-color: black;
    border-width: 2px;
    border-style: solid;
}

div.month_day_select ul,
div.hour_select ul {
    height: 200px !important;
}
</style>

<style>
.admin-settings .ql-editor {
    height: 10vh !important;
}
</style>