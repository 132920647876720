
import postTemplate from '../../components/user/postTemplate/index.vue'

let postTemplateRoutes = [
    {
        path: "/post-templates",
        name: 'PostTemplate',
        component: postTemplate,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
];

export default postTemplateRoutes