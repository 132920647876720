<template>
  <div class="container">
    <div class="row align-items-center lg-center mb-4">
      <div class="col-12 col-md-10 col-lg-8 mx-auto my-3 pb-5">
        <div
          class="card p-4 shadow-lg border-0 rounded mx-2 mb-5"
          style="z-index: 1"
        >
          <div class="mt-3">
            <h2 class="text-center text-info fw-semibold">Start your free 30 day trial</h2>
          </div>
          <form ref="anyName" @submit.prevent="submitForm" class="m-4">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="fname" class="form-label"
                    >First Name <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.formFields.first_name.$error }"
                    v-model="formFields.first_name"
                    id="fname"
                    placeholder="Enter First Name"
                  />
                  <!-- <span v-if="v$.first_name.$error ">{{v$.first_name.$errors[0].$message}}</span> -->
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="lname" class="form-label"
                    >Last Name <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.formFields.last_name.$error }"
                    id="lname"
                    v-model="formFields.last_name"
                    placeholder="Enter Last Name"
                  />
                  <!-- <span v-if="v$.last_name.$error ">{{v$.last_name.$errors[0].$message}}</span> -->
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="email" class="form-label"
                    >Email <span class="required">*</span></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': $v.formFields.email.$error }"
                    id="email"
                    autocomplete="username"
                    v-model="formFields.email"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
            </div>
            <div class="row d-none">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="address" class="form-label">Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    v-model="formFields.address"
                    placeholder="Enter Address"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="password" class="form-label"
                    >Password <span class="required">*</span></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.formFields.password.password.$error,
                    }"
                    id="password"
                    v-model="formFields.password.password"
                    placeholder="Enter Password"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="cpassword" class="form-label"
                    >Confirm Password <span class="required">*</span></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        $v.formFields.password.confirmPassword.$error,
                    }"
                    id="cpassword"
                    v-model="formFields.password.confirmPassword"
                    placeholder="Enter Confirm Password"
                    autocomplete="new-password"
                  />
                  <!-- <span v-if="v$.password.confirmPassword.$error">Must be same as password</span> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="country" class="form-label cursor-pointer"
                    >Country <span class="required">*</span></label
                  >
                  <v-select
                    class="select_country"
                    label="name"
                    v-model="formFields.country"
                    placeholder="Select Country"
                    :class="{ 'is-invalid': $v.formFields.country.$error }"
                    id="country"
                    :options="options"
                  ></v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="city" class="form-label"
                    >City<span class="required">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.formFields.city.$error }"
                    id="city"
                    v-model="formFields.city"
                    placeholder="Enter City"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="pno" class="form-label"
                    >Phone Number <span class="required">*</span></label
                  >
                  <input
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': $v.formFields.phone.$error }"
                    id="pno"
                    v-model="formFields.phone"
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="cmp_name" class="form-label"
                    >Company Name <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.formFields.companyName.$error }"
                    id="cmp_name"
                    v-model="formFields.companyName"
                    placeholder="Enter Company Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="website" class="form-label"
                    >Company Website <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="website"
                    :class="{
                      'is-invalid':
                        parseUrl == false ||
                        $v.formFields.companyWebsite.$error,
                    }"
                    v-model="formFields.companyWebsite"
                    placeholder="Company Website"
                  />
                  <span
                    class="text-danger"
                    style="font-size: 11px"
                    v-if="parseUrl == false"
                    >Enter a valid url</span
                  >
                </div>
              </div>
            </div>
            <div class="mt-2 text-center">
              <button
                class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-4 py-2 w-50"
                :class="isSubmitted ? 'disabled' : ''"
                type="submit"
              >
                <span
                  v-if="isSubmitted"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import {
  required,
  email,
  url,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
  name: "Register",
  components: {
    vSelect,
    StripeCheckout,
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push("/dashboard");
    } else {
      this.publishableKey = process.env.VUE_APP_PUBLISHABLE_KEY;
    }
  },

  data() {
    return {
      value: null,
      id: "",
      isSubmitted: false,
      publishableKey: "",
      options: [
        { name: "Canada", code: "CA" },
        { name: "Australia", code: "AU" },
        { name: "United States", code: "US" },
        { name: "United Kingdom", code: "UK" },
        { name: "Other", code: "other" },
      ],
      formFields: {
        first_name: "",
        last_name: "",
        email: "",
        password: {
          password: "",
          confirmPassword: "",
        },
        phone: "",
        companyName: "",
        companyWebsite: "",
        address: "",
        country: "",
        city: "",
      },
      parseUrl: true,
      loading: false,
      lineItems: [
        {
          price: process.env.VUE_APP_PRICE_WITH_TRAIL, // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: `${process.env.VUE_APP_BASEURLFRONT}success?session_id={CHECKOUT_SESSION_ID}`,
      cancelURL: `${process.env.VUE_APP_BASEURLFRONT}cancel?session_id={CHECKOUT_SESSION_ID}`,
    };
  },
  validations: {
    formFields: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: {
        password: { required, minLength: 6 },
        confirmPassword: {
          required,
          minLength: 6,
          sameAsPassword: sameAs(function () {
            return this.formFields.password.password;
          }),
        },
      },
      phone: { required },
      companyName: { required },
      companyWebsite: { required },
      address: "",
      country: { required },
      city: { required },
    },
  },

  methods: {
    onSelect(data) {},
    onChange() {},
    resetForm() {
      var self = this; //you need this because *this* will refer to Object.keys below`

      //Iterate through each object field, key is name of the object field`
      Object.keys(this.formFields).forEach(function (key, index) {
        self.formFields[key] = "";
      });
    },
    parseLink(href) {
      var res = href.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    submitForm(e) {
      e.preventDefault();
      localStorage.clear();
      this.value = this.formFields.email;

      if (this.formFields.companyWebsite != "") {
        this.parseUrl = this.parseLink(this.formFields.companyWebsite);

        if (this.parseUrl == false) {
          return false;
        }
      }
      this.$v.$touch()
      this.isSubmitted = true;      
      var temp = {
        first_name: this.formFields.first_name,
        last_name: this.formFields.last_name,
        email: this.formFields.email,
        password: this.formFields.password.password,
        company: this.formFields.companyName,
        phone: this.formFields.phone,
        country: this.formFields.country ? this.formFields.country.name : this.formFields.country,
        city: this.formFields.city,
        address: this.formFields.address,
        website: this.formFields.companyWebsite,
        password_confirmation: this.formFields.password.confirmPassword,
      };
     ;
      if (!this.$v.$invalid) {
        axios
          .post(process.env.VUE_APP_BASEURL + "/signup", temp, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((res) => {
            // console.log(res)
            if (res?.data?.token) {
              localStorage.setItem("token", res?.data?.token);
              localStorage.setItem("role", res?.data?.user?.role);
              localStorage.setItem("id", res?.data?.user?.id);
              localStorage.setItem("name", res.data?.user?.first_name);
              this.id = parseInt(res?.data?.user?.id);

              window.location.href = process.env.VUE_APP_STRIPE_PAYEMENT_TRIAL_LINK+"?prefilled_email="+res.data?.user?.email
              //this.$refs.checkoutRef.redirectToCheckout();
              this.loading = true;

              // this.resetForm()
            }
          })
          .catch((err) => {
            this.isSubmitted = false;
            let msgerr = 'Some error occurred, please try again.';
            if ( err?.response?.data?.error_message?.email ) {
              msgerr = err?.response?.data?.error_message?.email[0];
            }
            else if ( err?.response?.data?.error_message?.password ){
              msgerr = err?.response?.data?.error_message?.password[0];
            }

            this.$toasted.show(
                `${msgerr}`,
                {
                  theme: "bubble",
                  type: "error",
                  position: "top-center",
                  duration: 2000,
                  singleton: true,
                }
              );
            
          });
      } else {
        this.isSubmitted = false;
        // alert("form submission failed");
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.show-error {
  border: 1px solid red;
}
.required {
  color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.select_country input {
  cursor: pointer;
}
</style>
