<template>
  <div>
  <div class="modal fade" id="CalendarModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Upcoming Scheduled Events </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <!-- Modal Body -->
        <div class="modal-body" id="feedCalendar">
          <div v-if="isModalVisible">
            <FullCalendar
              v-if="isModalVisible"
              ref="fullCalendar"
              :options="calendarOptions"
            />
          </div>
          <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
            <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
            ..Loading
          </div>
        </div>
      </div>
    </div>   
  </div>
  <div class="modal fade" data-bs-backdrop="false" id="EventDetailsModal" tabindex="-1" aria-labelledby="EventDetailsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fs-6" id="EventDetailsModalLabel">{{ feedItems.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body"> 
          <div v-if="isDetailsVisible">
           <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="bg-secondary badge small">{{ feedItems.dateTime }}  </div>
            <a href="javascript:void(0);"  @click.prevent="cancelScheduledFeed(feedItems.id,feedItems.feed_id,feedItems.dateTime)"><div class="bg-danger badge small"> Cancel</div></a>
           </div>
           <div class="text-center">          
            <img :src="feedItems.media" class="img-fluid" alt="Event Image">
            <p class="mt-4 small" v-html="feedItems.body"></p>
          </div>
          </div> 
           <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
            <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
            ..Loading
          </div>
        </div>       
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { mapGetters, mapActions } from "vuex";
import moment from 'moment-timezone'
export default {
  components: {
    FullCalendar,
  }, 
    props: {     
      filterData: {
        type: Object,
      },      
    },
  data() {
    return {
      feedItems: {},
      isDetailsVisible: false,      
      feedMedia: {},
      isModalVisible: false,  
      selectedEventId: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',
        views: {
          dayGridMonth: {
            dayMaxEvents: 2,
            selectable: false,
            showNonCurrentDates:false
          },
          timeGridWeek: {           
            type: 'timeGrid',
            allDaySlot: false,
            eventMinHeight: 20,
            selectable: false,
            eventMaxStack: 1,
          },
          timeGridDay: {
            type: 'timeGrid',
            allDaySlot: false,
            eventMinHeight: 20,
            selectable: false,
            eventMaxStack: 1,
          }
        },
        eventContent: function (info) {      
          const eventTime  = info.event.start ? moment(info.event.start).format('hh:mm A') : '';
          let eventTitle = info.event.title;
          let time = "";
          const viewType = info.view.type;
          const type = `<div class="bg-info badge ms-1">${info.event.extendedProps.type}</div>`;
          if (viewType === 'dayGridMonth') {
            eventTitle = getShortDescription(eventTitle, 15);
            time = `<div class="bg-secondary badge ms-1">${eventTime}</div>`;
          } else if (viewType === 'timeGridWeek') {
            eventTitle = getShortDescription(eventTitle, 10);
          }
          function getShortDescription(text, maxLength) {
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          }         
          return {
            html: `
              <div style="padding: 5px; color: white;">
                ${type} ${time}
                <div class="fw-bold m-0 text-dark scheduled-event-title">${eventTitle}</div>
              </div>
            `,
          };
        },
        initialEvents: this.calendarEvent,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        selectedEvent: {},
      },
    };
  },
  computed: {
    ...mapGetters(["CalendarFeed", "CancelScheduledFeedRes"]),
  },
  
  methods: {   
    ...mapActions(["fetchCalendar", "cancelScheduledFeeds","fetchMonthlyFeeds"]),
    getShortDescription(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      } else {
        return text;
      }
    },
    getEvents() {
      this.fetchCalendar()
        .then(() => {
          const convertedEvents = this.CalendarFeed.map((event) => {
            return {
              allDay: event.allDay || false,
              id: String(event.id),
              title: event.title,
              type: event.get_feed?event.get_feed.type:'',
              feed_id: event.feed_id,
              start: event.start.replace(' ', 'T'),
              body: event.body,
              mediaUrl: event.mediaUrls,
              className: 'scheduled-event-box',
            };
          });
          this.calendarOptions.initialEvents = convertedEvents;
          this.isModalVisible = true;
        })
        .catch(() => {});
    },
    handleEventClick(eventInfo) {
      const currentEvent = eventInfo.event; 
      var data = currentEvent.extendedProps;
      const dateObject = new Date(currentEvent.start);

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return  `${year}-${month}-${day} ${
            hours && minutes && seconds ? moment(`${hours}:${minutes}:${seconds}`, 'HH:mm:ss').format('hh:mm A') : ''
        }`;

      };

      const formattedDate = formatDate(dateObject);
      this.feedItems = {
        id: currentEvent.id,
        title: currentEvent.title,
        media: JSON.parse(data.mediaUrl),
        body: data.body,
        dateTime: formattedDate,
        feed_id: data.feed_id,
      };
      $("#EventDetailsModal").modal("show");
      this.isDetailsVisible = true;
    },
    cancelScheduledFeed(eventId,feed_id, scheduled_date) {
      let obj = {
        feed_id: feed_id,
        scheduled_date: scheduled_date,
      };
      const requestData = {
          cancelFeeds: obj,
        };
      var confirmation = confirm('Are you sure you want to cancel this monthly feed ?');
      if (!confirmation) {
        return;
      }     
      this.cancelScheduledFeeds(requestData)
        .then(() => {
          this.$toasted.show(this.CancelScheduledFeedRes?.message, {
            theme: "bubble",
            type: "success",
            position: "top-right",
            duration: 2000,
            singleton: true,
          });        
          const calendarApi = this.$refs.fullCalendar.getApi();   
          const event = calendarApi.getEventById(eventId);
          event.remove();  
          $("#EventDetailsModal").modal("hide");
          this.fetchMonthlyFeeds(this.filterData); 
        })
        .catch(() => {
          this.$toasted.show(`Something Went Wrong`, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 1500,
            singleton: true,
          });
        });
    },
    
  },
  mounted() {
    const modal = document.getElementById('CalendarModal');
    modal.addEventListener('shown.bs.modal', () => {
      this.getEvents(); 
    });
    modal.addEventListener('hidden.bs.modal', () => {
      this.isModalVisible = false;
    });
  },
};
</script>

<style>
#feedCalendar a {
  color: #000;
  text-decoration: none;
}

#feedCalendar .fc-button.fc-button-primary.fc-button-active {
    background-color: #6ea8fe !important;
    border-color: #6ea8fe !important;
}

#feedCalendar .fc-button.fc-button-primary {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  margin-left:5px;
  padding: 2px 15px;
}

#feedCalendar .fc-button-group button {
  border-radius: 3px;
}

#feedCalendar .fc-toolbar button {
  text-transform: capitalize;
}


#feedCalendar .scheduled-event-box:hover, #feedCalendar .scheduled-event-box.active-event {
    background: rgba(0, 0, 0, .25) !important;
}

#feedCalendar .scheduled-event-box {
    cursor: pointer;
    margin: 0;
    border-radius: 0;
    background: transparent;
    border: none;
}
</style>
