<template>
    <vue-good-table
        :rows="reviews"
        :columns="columns"
        :isLoading="isLoading"
        :row-style-class="checkDisabled"
        :sort-options="{
            enabled: true,
            initialSortBy: {
                field: 'author',
                type: 'desc'
            },
        }"
        :search-options="{
            enabled: true,
            placeholder: 'Search'
        }"
        :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [
                10,
                20,
                30,
                40,
                50
            ],
            perPage: 10,
            mode: 'pages'
        }"
    >
        <template slot="table-row" slot-scope="props" check="props">
            <template v-if="props.column.field == 'author'">
                <div class="d-flex justify-content-center">
                    <img
                        class="img-thumbnail"
                        alt="avatar"
                        :src="props.row.avatar ? props.row.avatar : noImageUser"
                        style=" width: 40px; height: 40px; border-radius: 50% !important;"
                        />
                </div>
                <div class="d-flex justify-content-center">
                    <h6>
                        {{props.row.author}}
                    </h6>
                </div>
                <div class="d-flex justify-content-center">
                    <span v-for="n in 5" :key="n" class="star">
                        {{ n <= props.row.rating ? '★' : '☆' }}
                    </span>
                    <img v-if="props.row.platform && props.row.platform.toLowerCase() == 'yelp'" src="../../../assets/yelp.png" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />
                    <img v-if="props.row.platform && props.row.platform.toLowerCase() == 'google'" src="../../../assets/google.png" alt="Google" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />
                    <img v-if="props.row.platform && props.row.platform.toLowerCase() == 'facebook'" src="../../../assets/facebook.png" alt="Facebook" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />
                </div>
            </template>
            
            <template v-if="props.column.field == 'text'">
                {{
                    (props.row.short_description ? props.row.short_description : props.row.text).substring(0,200)
                    +
                    ((props.row.short_description ? props.row.short_description : props.row.text).length > 200 ? '....':'')
                }}
            </template>

            <template v-if="props.column.field == 'actions'">
                <template v-if="ignoreIds.length == 0 || (ignoreIds.length > 0 && !ignoreIds.includes(props.row.id))">
                    <button 
                        class="btn btn-danger remove-review "
                        :data-id="props.row.id"
                        :data-index="props.index"
                        v-tooltip="'Remove Review From Widget'"
                        >
                        <i class="bi bi-trash-fill" :data-id="props.row.id" :data-index="props.index"></i>
                    </button>
                </template>
                <button 
                    v-else
                    class="btn btn-info restore-review"
                    :data-id="props.row.id"
                    :data-index="props.index"
                    v-tooltip="'Add Review Again In Widget'"
                    >
                    <i class="bi bi-arrow-clockwise text-white" :data-id="props.row.id" :data-index="props.index"></i>
                </button>
            </template>
            
        </template>
    </vue-good-table>
</template>

<script>
import noImage from "../../../assets/user2.png"

export default {
    name: 'reviewsListings',
    components: {
    },
    props: {
        reviews:Array,
        ignoreIds:Array
    },
    data() {
        return {
            columns: [
                {
                    label: 'Author',
                    field: 'author',
					width: '25%',
                },
                {
                    label: 'Text',
                    field: 'text'
                },
                {
                    label: 'Action',
                    field: 'actions',
                    sortable: false,
					width: '25%',
                    globalSearchDisabled: true,
                }
            ],
            totalRows:null,
            isLoading:true,
            perPage:10,
            noImageUser:noImage
        }
    },
    computed: {},
    created: function(){
        this.isLoading = false
    },
    methods: {
        checkDisabled:function(row){
            return !(this.ignoreIds.length == 0 || (this.ignoreIds.length > 0 && !this.ignoreIds.includes(row.id))) ? 'disabled-tr' : ''
        }
    }
}
</script>

<style scoped>

.star {
    color: gold;
    font-size: 16px;
}

</style>

<style>

.disabled-tr {
    background-color:aquamarine;
    opacity: 0.5;
    position:relative;
}

.disabled-tr::after{
    content: 'Deleted';
    position: absolute;
    left: 45%;
    transform: rotate(-21deg);
    bottom: 40px;
    background-color: white;
    border-radius: 5px;
    width: 155px;
    text-align: center;
    color: red;
    font-weight: bolder;
}

</style>