import { getRequest } from '@/helper'

let postTemplatesModule = {
    state:{
        templates: {}
    },
    getters:{
        templates: (state) => {
            return state.templates
        }
    },
    actions:{
        getPostTemplates: async ({ commit }) => {
            let resp = await getRequest(`users/post-templates`)
            return resp
        },
        getPostTemplate: async ({ commit }, id) => {
            let resp = await getRequest(`users/post-templates/${id}/view`)
            if(resp && resp.status)
            {
                return resp.data
            }
            else
            {
                console.log(resp.message)
            }
        },
    },
    mutations:{
        setPostTemplates: (state, templates) => {
            return state.templates = templates
        }
    }
}

export default postTemplatesModule