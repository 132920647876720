
import tags from '../../components/admin/tags/index.vue'

let tagsRoutes = [
    {
        path: "/admin/tags",
        name: 'Tags',
        component: tags,
        meta: {
          admin: true,
          auth: true,
          authOnly: true,
          showDashLayout: true,
          adminOnly: true,
          roles: [
            'Admin'
          ]
        }
      },
];

export default tagsRoutes