<template>
   <div>    
      <div class="modal fade" id="CalendarModal">
         <div class="modal-dialog modal-xl">
            <div class="modal-content">
               <!-- Modal Header -->
               <div class="modal-header">
                  <h4 class="modal-title">{{ ScheduledFeed }} Scheduled Events </h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" @click="closeCalendar()"></button>
               </div>
               <!-- Modal Body -->
               <div class="modal-body" id="feedCalendar">
                  <div v-if="ScheduledFeed === 'Upcoming'">
                     <div v-if="isModalVisible">
                        <FullCalendar
                           ref="upcomingCalendar"
                           :options="calendarOptions"
                           />
                     </div>
                     <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
                        <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
                        ..Loading Upcoming Events
                     </div>
                  </div>
                  <div v-else>
                     <div v-if="isModalVisible">
                        <FullCalendar
                           ref="pastCalendar"
                           :options="calendarOptions"
                           />
                     </div>
                     <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
                        <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
                        ..Loading Past Events
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal fade" data-bs-backdrop="false" id="EventDetailsModal" tabindex="-1" aria-labelledby="EventDetailsModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title fs-6" id="EventDetailsModalLabel">{{  feedItems.title}}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div v-if="isDetailsVisible">
                     <div class="d-flex align-items-center justify-content-between mb-2">
                        <div class="bg-secondary badge small" >{{ feedItems.dateTime ? dateFormat(feedItems.dateTime) : '' }}</div>
                        <a v-if="(ScheduledFeed==='Upcoming' && feedItems.type != 'review')" href="javascript:void(0);" @click.prevent="cancelScheduledFeed(feedItems.id,feedItems.feed_id, feedItems.dateTime)">
                           <div class="bg-danger badge small">Cancel</div>
                        </a>
                        <span class="badge bg-success" v-if="ScheduledFeed==='Upcoming' && feedItems.type == 'review'">
                          Planned Review Feed
                        </span>
                        <div v-if="ScheduledFeed === 'Past'">
                           <div class="d-flex align-items-start">
                              <!-- Display Errors if exist -->
                              <div v-if="(feedItems.scheduled_status== 'Success')" class="align-items-start">
                              <span class="badge bg-success">                                    
                                   Success
                                    </span>
                              </div>
                              <div v-if="(feedItems.scheduled_status== 'Error')" class="align-items-start">
                               <span class="badge bg-danger">
                                 Error
                                  </span>
                              </div>              
                              <div v-if="(feedItems.scheduled_status== 'Cancel')" class="align-items-start">
                               <span class="badge bg-warning">
                                 Cancelled
                                  </span>
                              </div>  
                           </div>
                        </div>
                     </div>
                     <div class="text-center">
                        <img :src="feedItems.media" class="img-fluid" alt="Event Image">
                        <p class="mt-4 small" v-html="feedItems.body"></p>
                     </div>
                     <div v-if="ScheduledFeed === 'Past'">
                           <div class="d-flex align-items-start">
                              <!-- Display Errors if exist -->
                              <div v-if="feedItems.postResponse.errors" class="d-flex align-items-start">
                                 <div v-for="(client, id) in feedItems.postResponse.errors" :key="id">
                                    <div class="d-flex flex-row ms-2">
                                       <span v-for="(data, dataKey) in client" :key="dataKey">
                                       <span v-if="dataKey !== 'ClientName'">
                                       <i
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="center"
                                          data-bs-html="true"
                                          data-bs-trigger="hover"
                                          v-tooltip="data"
                                        :class="`bi bi-${dataKey === 'gmb' ? 'google' : dataKey} ${dataKey === 'gmb' ? 'google' : dataKey}-icon fs-4 text-decoration-underline`"
                                          ></i>
                                                                                  
                                       </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                              <!-- Display Successes if exist -->
                              <div v-if="feedItems.postResponse.success" class="d-flex align-items-start">
                                 <div v-for="(client, id) in feedItems.postResponse.success" :key="id">
                                    <div class="d-flex flex-row ms-3">
                                       <span v-for="(data, dataKey) in client" :key="dataKey">
                                        <span v-if="dataKey !== 'ClientName'">   
                                            <span 
                                              v-tooltip="{
                                              content: data ? tooltipData(data, dataKey) : `${dataKey} Group`,
                                              autoHide: data ? false : true,
                                              html: true,
                                              placement: 'bottom'
                                            }"  
                                        ><i :class="`bi bi-${dataKey === 'gmb' ? 'google' : dataKey} ${dataKey === 'gmb' ? 'google' : dataKey}-icon fs-4`"></i>                                
                                        &nbsp;</span>                      
                                      </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                  </div>
                  <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
                     <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
                     ..Loading
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { mapGetters, mapActions } from "vuex";
import moment from 'moment-timezone'
 // A reusable loader component

export default {
  components: {
    FullCalendar,
  },
  props: {
    ScheduledFeed: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      feedItems: {},
      isDetailsVisible: false,      
      isModalUpcomingVisible: false,  
      isModalVisible: false, 
      error:"",
   
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',        
        views: {
          dayGridMonth: {
            dayMaxEvents: 2,
            showNonCurrentDates: false,
            selectable: false,
          },
          timeGridWeek: {           
            type: 'timeGrid',
            allDaySlot: false,
            eventMinHeight: 20,
            eventMaxStack: 1,
            selectable: false,
          },
          timeGridDay: {
            type: 'timeGrid',
            selectDaySlot: false,
            eventMinHeight: 20,
            eventMaxStack: 1,
            selectable: false,
          }
        },
        eventContent: function (info) {
			let eventTime = ''
			if(info.event.extendedProps && info.event.extendedProps.monthly_review_scheduled_id)
			{
				eventTime = new Date(info.event.start).toLocaleTimeString("en-US", {
					hour: '2-digit',
					minute: '2-digit'
				});
			}
			else
			{
				eventTime = info.event.start ? moment(info.event.start).format('hh:mm A') : '';
			}

		  let eventTitle = info.event.title;
          let time = "";
          const viewType = info.view.type;
          let planned = '<div class="bg-success badge ms-1">Planned</div>'

          const type = `<div class="bg-info badge ms-1">${info.event.extendedProps.type ? String(info.event.extendedProps.type).charAt(0).toUpperCase() + String(info.event.extendedProps.type).slice(1) : ''}</div> ${ info.event.extendedProps.event_type == 'Upcoming' && info.event.extendedProps.type == 'review' ? planned : ''} `;
          if (viewType === 'dayGridMonth') {
            eventTitle = getShortDescription(eventTitle, 15);
            time = `<div class="bg-secondary badge ms-1">${eventTime}</div>`;
          } else if (viewType === 'timeGridWeek') {
            eventTitle = getShortDescription(eventTitle, 10);
          }
          function getShortDescription(text, maxLength) {
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          }

          return {
            html: `
              <div style="padding: 5px; color: white;">
                ${type} ${time}
                <div class="fw-bold m-0 text-dark scheduled-event-title">${eventTitle}</div>
              </div>
            `,
          };
        },
        initialEvents: this.calendarEvent,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        
        },   
          
    };
  },
  computed: {
    ...mapGetters(["CalendarClientFeed", "CancelScheduledFeedRes"]),  
  },
  methods: {   
    ...mapActions(["fetchClientCalendar", "cancelScheduledFeeds"]),
    
    getEvents() {
      this.fetchClientCalendar(this.ScheduledFeed)
        .then(() => {    
           const convertEvents = (events) => {
             return events.map((event) => {
				return {
                        allDay: event.allDay || false,
                        id: String(event.id),
                        title: event.title,
                        type: event.get_feed?event.get_feed.type:'',
                        feed_id: event.feed_id,
                        start: event.scheduled_date.replace(' ', 'T'),
                        body: event.post,
                        scheduled_status: event.scheduled_status,
                        post_response_data:event.post_response_data?event.post_response_data:'',
                        mediaUrl: event.mediaUrls,
                        monthly_review_scheduled_id:event.monthly_review_scheduled_id,
                        event_type:this.ScheduledFeed,
                        className: 'scheduled-event-box',
                    };
				});
           };
		   
        this.calendarOptions.initialEvents = convertEvents(this.CalendarClientFeed);
        this.isModalVisible = true;
        })
        .catch(() => {});
    },
    handleEventClick(eventInfo) {
		const currentEvent = eventInfo.event; 
		var data = currentEvent.extendedProps;    
		const dateObject = new Date(currentEvent.start);
      	const formatDate = (date) => {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      	};
      	const formattedDate = formatDate(dateObject);
      	this.feedItems = {
			id: currentEvent.id,
			title: currentEvent.title,
			media: JSON.parse(data.mediaUrl),
			body: data.body,
			dateTime: formattedDate, // Format date
			feed_id: data.feed_id,
			scheduled_status: data.scheduled_status,
			type: data.type,
			postResponse:data.post_response_data,
			monthly_review_scheduled_id:data.monthly_review_scheduled_id
      	};
      	$("#EventDetailsModal").modal("show");
      	this.isDetailsVisible = true;
    },
	cancelScheduleReview:function(id, eventId){
		if(confirm('Are you sure you want to cancel this review feed?'))
    { 
      this.$helper.getRequest(`user/monthly-reviews/${id}/cancel-schedule`).then((resp) => {
        if(resp.status)
        {
          this.$notify(resp.message)
          const calendarApi = this.$refs.upcomingCalendar.getApi();   
          const event = calendarApi.getEventById(eventId); //TODO Test
          event.remove();  
          $("#EventDetailsModal").modal("hide");
              this.$emit('getHistoryOfFeed');
        }
        else
        {
          this.$notify(resp.message ? resp.message : 'something went wrong please try in some time','danger')
        }
      })
    }
	},  
    cancelScheduledFeed(eventId,feed_id, scheduled_date) {
	  	if(this.feedItems.monthly_review_scheduled_id)
	  	{
			this.cancelScheduleReview(this.feedItems.monthly_review_scheduled_id, eventId)
	  	}
	  	else
	  	{
			let obj = { feed_id, scheduled_date };
			
			const requestData = {
				cancelFeeds: obj,
			};
      	
			var confirmation = confirm('Are you sure you want to cancel this monthly feed?');
      		if (!confirmation) return;     
      		this.cancelScheduledFeeds(requestData)
        	.then(() => {
				this.$toasted.show(this.CancelScheduledFeedRes?.message, {
					theme: "bubble",
					type: "success",
					position: "top-right",
					duration: 2000,
					singleton: true,
				});
				const calendarApi = this.$refs.upcomingCalendar.getApi();   
				const event = calendarApi.getEventById(eventId);
				event.remove();  
				$("#EventDetailsModal").modal("hide");
        		this.$emit('getHistoryOfFeed');
			})
			.catch(() => {
				this.$toasted.show(`Something Went Wrong`, {
					theme: "bubble",
					type: "error",
					position: "top-right",
					duration: 1500,
					singleton: true
				});
			});
		}
	},
    closeCalendar() {
      this.isModalUpcomingVisible = false; 
      this.isModalPastVisible = false;
    },    
    tooltipData(data, dataKey) {
      return data ? this.showLink(data, dataKey) : `${dataKey} Page`;    
    },
    showLink(link, platform) {
      let html = ``;      
    if (platform === "twitter") {
        if (Array.isArray(link) && link.length > 1) {
          link.forEach((val, index) => {
          html += `<span>Thread ${index + 1}:</span>
              <a href="${val}" target="_blank" class="px-2 text-white">${val}</a><br>`;               
          });
        } else {
          html = `<a href="${link}" target="_blank" class="px-2 text-white">${link}</a>`;
        }
    } else {
        html = `<a href="${link}" target="_blank" class="px-2 text-white">${link}</a>`;
    }

    return html;
    },
    dateFormat:function(date){
      return date ? moment(date).format('MM-DD-YYYY hh:mm A') : '--'
    },
  },
  mounted() {
    const modal = document.getElementById('CalendarModal');
    modal.addEventListener('shown.bs.modal', () => {
      this.getEvents(); // Fetch events when the modal is shown
    });
    modal.addEventListener('hidden.bs.modal', () => {
      this.isModalVisible = false;
    });
  },
};
</script>

<style>
.schedule-post-history .img-fluid {
  max-width: 100%;
    height: auto !important;

}
.clients-list-grp ul {
   flex-direction: row;
   gap: 20px;
   flex-wrap: wrap;
   }
   .clients-list-grp ul li {
   border: 1px solid #dfdfdf;
   border-top-width: 1px !important;
   width:50px
   } 
   
#feedCalendar a {
  color: #000;
  text-decoration: none;
}

#feedCalendar .fc-button.fc-button-primary.fc-button-active {
    background-color: #6ea8fe !important;
    border-color: #6ea8fe !important;
}

#feedCalendar .fc-button.fc-button-primary {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  margin-left:5px;
  padding: 2px 15px;
}

#feedCalendar .fc-button-group button {
  border-radius: 3px;
}

#feedCalendar .fc-toolbar button {
  text-transform: capitalize;
}


#feedCalendar .scheduled-event-box:hover, #feedCalendar .scheduled-event-box.active-event {
    background: rgba(0, 0, 0, .25) !important;
}

#feedCalendar .scheduled-event-box {
    cursor: pointer;
    margin: 0;
    border-radius: 0;
    background: transparent;
    border: none;
}
</style>
