<template>
    <div class="hello m-5">
        <div class="d-flex justify-content-start mb-3 d-none">
            <router-link to="/admin/tags-categories" class="m-1 btn btn-primary text-white active">Tags Categories</router-link>
            <router-link to="/admin/tags" class="m-1 btn btn-primary text-white ">Tags</router-link>
            <router-link to="/admin/smart-tags" class="m-1 btn btn-primary text-white ">Smart Tags</router-link>
        </div>
        <div class="d-flex justify-content-end">
            <h4 class="m-1 d-none">
                Tags Categories
            </h4>
            <a
                href="javascript:;"
                class="text-white btn btn-primary m-2 flex-start-end"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                @click="resetFormData"
            >
                Add
            </a>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button id="tagsCategoriesTab" data-bs-toggle="tab" data-bs-target="#tagsCategories" type="button" role="tab" aria-controls="tagsCategories" aria-selected="true" class="nav-link active">Tags Categories</button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="() => { $router.push('/admin/tags') }" class="nav-link">Tags</button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="() => { $router.push('/admin/smart-tags') }" class="nav-link">Smart Tags</button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tagsCategories" role="tabpanel" aria-labelledby="tagsCategoriesTab">
                <vue-good-table
                    mode="remote"
                    @on-search="onSearch"
                    @on-sort-change="onSortChange"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                    :rows="listing"
                    :columns="columns"
                    :isLoading="isLoading"
                    :totalRows="totalRows"
                    :sort-options="{
                        enabled: true,
                        initialSortBy: { 
                            field: 'id',
                            type: 'desc'
                        },
                    }"
                    :search-options="{ 
                        enabled: true,
                        placeholder: 'Search',
                    }"
                    :pagination-options="{
                        enabled: true,
                        dropdownAllowAll: false,
                        perPageDropdown: [
                            10,
                            20,
                            30,
                            40,
                            50
                        ],
                        perPage: 10,
                        mode: 'pages',
                    }"
                >
                    <template slot="table-row" slot-scope="props">
                        {{
                            props.column.field == 'title' || props.column.field == 'id'  ? props.formattedRow[props.column.field] : ''
                        }}
                        <template v-if="props.column.field == 'status'">
                            <div class="form-check form-switch">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="props.formattedRow.status"
                                    @change="(e) => onStatusChange(e, props.formattedRow)"
                                />
                            </div>
                        </template>
                        <template v-if="props.column.field == 'actions'">
                            <button
                                class="btn btn-success m-1"
                                data-bs-toggle="modal"
                                data-bs-target="#updateModal"
                                @click="openUpdateModal(props.formattedRow.id)"
                            >
                                <i class="bi bi-pencil"></i>
                            </button>
                            <button
                                class="btn btn-danger open_delete_modal"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                :data-id="props.formattedRow.id"
                                @click="onOpenDeleteModal(props.formattedRow.id)"
                            >
                                <i class="bi bi-trash-fill"></i>
                            </button>
                        </template>
                    </template>
                </vue-good-table>
            </div>
        </div>
		<div class="modal fade" id="deleteModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteModalLabel">
                            <i class="bi bi-person-x m-1"></i> Delete Record
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to delete?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-danger delete_record_btn" data-id="" @click="deleteRecord" data-bs-dismiss="modal">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

		<div class="modal fade" id="updateModal">
            <div class="modal-dialog">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Update
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="update">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-12">
									<div class="mb-3">
										<label for="title" class="form-label">Title</label>
										<input
                                            id="title"
										 	type="text"
											class="form-control"
                                            placeholder="Enter Tag Category "
											:class="[$v.formFields.title.$error ? 'is-invalid' : '']"
											v-model="formFields.title"
										>
									</div>
                                    <div class="mb-3">
                                        <label class="form-check-label m-b-10" for="status">
                                            Status
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                id="status"
                                                type="checkbox"
                                                class="form-check-input"
											    v-model="formFields.status"
                                            />
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="formLoading">
                                Update
                                <Loading v-if="formLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addModal">
            <div class="modal-dialog">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Add
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="add">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-12">
									<div class="mb-3">
										<label for="title" class="form-label">Title</label>
										<input
                                            id="title"
										 	type="text"
											class="form-control"
                                            placeholder="Enter Tag Category "
											:class="[$v.formFields.title.$error ? 'is-invalid' : '']"
											v-model="formFields.title"
										>
									</div>
                                    <div class="mb-3">
                                        <label class="form-check-label m-b-10" for="status">
                                            Status
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                id="status"
                                                type="checkbox"
                                                class="form-check-input"
											    v-model="formFields.status"
                                            />
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="formLoading">
                                Save
                                <Loading v-if="formLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '@/components/user/reviews/loading.vue';

export default {
    name: 'tagsCategories',
    components: {
        vSelect,
        Loading
    },
    props: {},
    data() {
        return {
            formFields: {
                id: null,
                title: '',
                status: 1
            },
            columns: [
                {
                    label: 'id',
                    field: 'id',
                    sortable: true,
					width: '25%',
                    hidden:true
                },
                {
                    label: 'Tag Category',
                    field: 'title',
                    sortable: true,
					width: '25%'
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: true,
					width: '25%',
                },
                {
                    label: 'Action',
                    field: 'actions',
                    sortable: false,
					width: '25%',
                    globalSearchDisabled: true,
                }
            ],
            listing:[],
            totalRows:null,
            isLoading:true,
            formLoading:false,
            perPage:10
        }
    },
    validations: {
        formFields: {
            title: { 
                required
            },
            status: { 
                required
            }
        }
    },
    computed: {},
    created: function(){
        this.isLoading = true
        this.fetchListing();
    },
    mounted:function(){
        let removeEventListener = function(){
            document.removeEventListener("contextmenu",window.$disableRightClick, false);
        } 
        window.onload = removeEventListener
        removeEventListener()
    },
    beforeRouteLeave:function(to, from , next) {
        document.addEventListener("contextmenu",window.$disableRightClick, false);
        next()
    },
    methods: {
        ...mapActions([
            'addTagCategory',
            'getTagCategory',
            'deleteTagCategory',
            'updateTagCategory',
            'getTagsCategories',
            'getTagsCategoriesDropDown'
        ]),
        add: function(e) {
            e.preventDefault()
            this.$v.$touch()
            if (!this.$v.$invalid)
            {
                this.formLoading = true
                this.addTagCategory(this.formFields)
                .then((resp) => {
                    if(resp.status)
                    {
                        this.resetFormData()
                        this.closeModal('#addModal')
                        this.notify(resp.message)
                        this.refresh()
                    }
                    else
                    {
                        this.notify(resp.message,'danger')
                    }
                    this.formLoading = false
                }).catch((resp) => {
                    this.formLoading = false
                    this.notify("Something went wrong please in some time",'danger')
                });
            }
        },
        update: function(e) {
            e.preventDefault()
            this.$v.$touch()
            if (!this.$v.$invalid)
            {
                this.formLoading = true
                this.updateTagCategory(this.formFields)
                .then((resp) => {
                    if(resp.status)
                    {
                        this.resetFormData()
                        this.closeModal('#updateModal')
                        this.notify(resp.message)
                        this.refresh()
                    }
                    else
                    {
                        this.notify(resp.message,'danger')
                    }
                    this.formLoading = false
                }).catch((resp) => {
                    this.formLoading = false
                    this.notify("Something went wrong please in some time",'danger')
                });
            }
        },
        openUpdateModal:function(id){
            this.getTagCategory(id).then((resp) => {
                if(resp.status)
                {
                    let {data} = resp
                    if(data)
                    {
                        this.formFields = {
                            id: data.id,
                            title: data.title,
                            status: data.status,
                        }
                    }
                }
                else
                {
                    this.notify(resp.message,'danger')
                }
            })
        },
        resetFormData:function(){
            this.formFields = {
                id:null,
                title:'',
                status:1,
            }
            this.$v.$reset()
        },
        onPageChange: function (data){
            this.fetchListing(data.currentPage)
        },
        onPerPageChange: function (data){
            this.perPage = data.currentPerPage
            this.fetchListing(1, data.currentPerPage)
        },
        onSortChange: function (data){
            this.fetchListing(1, '', data[0].field, data[0].type)
        },
        onSearch: function (data){
            this.fetchListing(1,'','','',data.searchTerm)
        },
        fetchListing: function(page = 1, limit = '', field = '', direction = '', search = ''){
            this.isLoading = true
            direction = direction != 'none' ? direction : '';
            limit = !limit ? this.perPage : limit
            this.getTagsCategories({page, limit, field, direction, search}).then((resp) => {
                this.totalRows = resp.total
                this.listing = resp.data
                this.isLoading = false
            }).catch(() => {
                console.log(" Something went wrong please try later ")
            })
        },
        deleteRecord: async function(e){
            let id = e.target.getAttribute('data-id');
            let resp = await this.deleteTagCategory(id)
            if(resp && resp.status)
            {
                let deleteBtn = document.querySelector(`button.open_delete_modal[data-id='${id}']`); 
                deleteBtn.parentElement.parentElement.remove()
                this.notify(resp.message)
            }
            else
            {
                this.notify(resp.message,'danger')
            }
        },
        onOpenDeleteModal: function(id){
            let deleteModal = document.querySelector('div#deleteModal')
            if(deleteModal)
            {
                deleteModal.querySelector('button.delete_record_btn').setAttribute('data-id', id)
            }
        },
        closeModal:function(modal){
            let closeBtn = document.querySelector(modal).querySelector('.close-icon')
            if(closeBtn)
            {
                closeBtn.click()
            }
        },
        refresh:function(){
            this.onSearch('');
        },
        notify:function(msg, type = 'success'){
            if(type)
            {
                this.$notify(msg, type)
            }
            else
            {
                this.$notify(msg)
            }
        },
        onStatusChange:function(e, row){
            let temp = {
                ...row,
                status:e.target.checked ? 1 : 0
            } 
            this.updateTagCategory(temp).then((resp) => {
                if(resp.status)
                {
                    this.$notify(resp.message)
                }
                else
                {
                    this.$notify(resp.message ? resp.message : 'something went wrong please try later' ,'danger')
                }
            })
        }
    }
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.form-switch .form-check-input {
    width: 50px !important;
    height: 2rem !important;
}
.close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
}

.openModalBusiness { 
	cursor: pointer;
}
</style>