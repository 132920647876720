import reviews from '../../components/admin/reviews/index.vue'
import monthlyReviews from '../../components/admin/monthly_reviews/index.vue'

let reviewsRoutes = [
    {
        path: '/admin/reviews',
        name: 'Reviews',
        component: reviews,
        meta: {
            admin: true,
            auth: true,
            authOnly: true,
            showDashLayout: true,
            adminOnly: true,
            roles: ['Admin'],
        }
    },
    {
        path: '/admin/monthly-reviews',
        name: 'MonthlyReviews',
        component: monthlyReviews,
        meta: {
            admin: true,
            auth: true,
            authOnly: true,
            showDashLayout: true,
            adminOnly: true,
            roles: ['Admin'],
        }
    }
]

export default reviewsRoutes
