
import tagsCategories from '../../components/admin/tagsCategories/index.vue'

let tagsRoutes = [
    {
        path: "/admin/tags-categories",
        name: 'TagsCategories',
        component: tagsCategories,
        meta: {
          admin: true,
          auth: true,
          authOnly: true,
          showDashLayout: true,
          adminOnly: true,
          roles: [
            'Admin'
          ]
        }
      },
];

export default tagsRoutes