<template>
    <nav class="navbar navbar-expand-lg navbar-light nav_class p-2">
        <div class="container-fluid">
            <button
                class="navbar-toggler border-white shadow-none bg-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav user_menu">
                    <li class="nav-item" v-if="role == 'User'">
                        <a :href="homeUrl" class="navbar-brand">
                            <img src="../assets/social-logo.png" />
                        </a>
                    </li>
                    <li class="nav-item">
                        <a v-if="role == 'Admin'" :href="homeUrl" class="navbar-brand">
                            <img src="../assets/social-logo.png" />
                        </a>
                    </li>

                    <!--<li class="nav-item" v-if="role == 'User'">
						<button
						class="nav-link text-nowrap btn btn-light"
						aria-current="page"
						@click="connectToSocial"
						v-if="pay_status != 0"
						>Connect Social Network <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button
						>
					</li>-->

                    <li class="d-flex justify-content-end w-100">
                        <ul class="navbar-nav w-100 justify-content-end">
							<!-- Users Start -->
							<li class="nav-item mt-2 mx-2" v-if="role == 'User'">
								<router-link activeClass="active" class="nav-link" aria-current="page" to="/dashboard" v-if="pay_status != 0">
									Dashboard
								</router-link>
							</li>
                            <li class="nav-item mt-2 mx-2"  v-if="role == 'User'">
                                <router-link activeClass="active" class="nav-link" aria-current="page" to="/scheduled-post-history" v-if="pay_status != 0">
                                    Scheduled Feeds
                                </router-link>
                            </li>
							
                            <li class="nav-item mt-2 mx-2" v-if="role == 'User'">
                                <router-link activeClass="active" class="nav-link" aria-current="page" to="/reviews" v-if="pay_status != 0" >
                                    Reviews
                                </router-link>
                            </li>

                            <li class="nav-item mt-2 mx-2" v-if="role == 'User'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/widgets" v-if="pay_status != 0" >
                                        Widgets
                                    </router-link>
                            </li>                                            
                            <li class="nav-item mt-2 mx-2"  v-if="role == 'User'">
                                <a
                                    activeClass="active"  class="nav-link connect_social_network_btn_navbar"
                                    aria-current="page"
                                    ref="connectSocialNetworkBtnNavbar"
                                    @click="connectToSocial"
                                    v-if="pay_status != 0"
                                >
                                    Connect Social Network 
                                    <span
                                        v-if="isLoading"
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span
                                ></a>
                            </li>
							<!-- Users END -->
	
							<!-- Admin Start -->
							<li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
								<router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/users">
									Users
								</router-link>
							</li>
							
							<li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
								<router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/monthly-feed">
									Monthly Feeds
								</router-link>
							</li>
							
							<li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
								<router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/reviews">
									Reviews
								</router-link>
							</li>

                            <li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
								<router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/monthly-reviews">
									Weekly Review Feeds
								</router-link>
							</li>
	
							<li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
								<router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/tags-categories">
									Tags
								</router-link
								>
							</li>
							<!-- Admin END -->
	
							<li style="margin-left: 10%" class="nav-item dropdown">
								<a class="nav-link dropdown-toggle ms-2 profile_name" type="button " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<img src="../assets/user2.png" class="rounded-circle me-2 profile-image"/>
									<span style="font-size: 13px">
										{{ name }}
									</span>
								</a>
								<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
									<li v-if="pay_status != 0 && role == 'User'">
										<router-link class="dropdown-item" aria-current="page" to="/profile" >
											My Account
										</router-link>
									</li>									
									<li v-if="role == 'Admin'">
										<router-link class="dropdown-item" aria-current="page" to="/admin/profile">
											My Account
										</router-link>
									</li>
									<li v-if="role == 'Admin'">
										<router-link class="dropdown-item" aria-current="page" to="/admin/settings">
											Settings
										</router-link>
									</li>
									
									<li>
										<a class="dropdown-item" @click="logout">
											Logout
										</a>
									</li>
								</ul>
							</li>

						</ul>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="modal fade"
            id="videoModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header video-tutorial-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Social Network
                        </h5>
                        <button
                            type="button"
                            class="btn-close bg-white"
                            aria-label="Close"
                            data-bs-dismiss="modal"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div
                            style="
                                position: relative;
                                padding-bottom: 85.85055643879174%;
                                height: 0;
                            "
                        >
                            <iframe :src="url"></iframe>
                        </div>
                        <div class="mt-2 text-center">
                            <button
                                type="button"
                                class="btn btn-outline-dark"
                                data-bs-dismiss="modal"
                            >
                                <i class="bi bi-x"></i> Close
                            </button>
                        </div>
                    </div>
                    <!-- <div class="modal-footer d-flex justify-content-center">
          
             <button type="button" class="btn btn-primary">Save changes</button> 
          </div> -->
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Navbar',
    data() {
        return {
            pay_status: '',
            url: '',
            role: '',
            name: '',
            isLoading: false,
            homeUrl: process.env.VUE_APP_PROMOTIONAL_PAGE_URL,
        }
    },
    created() {
        this.pay_status = localStorage.getItem('pay_status')
        this.role = localStorage.getItem('role')
        this.name = localStorage.getItem('name')
        // console.log(this.pay_status);
    },
    methods: {
        connectToSocial() {
            this.isLoading = true
            let obj = ''
            axios
                .post(
                    process.env.VUE_APP_BASEURL + '/connect-social-account',
                    obj,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            token: `${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((res) => {
                    var w = 900
                    var h = 600
                    let url
                    if (res.data?.status) {
                        url = res?.data?.url + '&logout=true'
                        window.open(url,'_blank');
                        this.isLoading = false;
                        // if (window.innerWidth > 580) {
                        //     this.edititem(url, 'web', w, h)
                        //     this.url = res.data?.url
                        //     //  $('#videoModal1').modal('show');
                        //     //   this.$router.push(res.data?.url)

                        //     this.isLoading = false
                        // } else {
                        //     window.open(url)
                        //     this.isLoading = false
                        // }
                    } else {
                        this.isLoading = false
                        this.$toasted.show(`${res.data.error_message}`, {
                            theme: 'bubble',
                            type: 'error',
                            position: 'top-center',
                            duration: 2500,
                            singleton: true,
                        })
                    }
                })
                .catch((err) => {
                    //console.log(err)
                    this.isLoading = false
                    this.$toasted.show(`Inernal Server Error`, {
                        theme: 'bubble',
                        type: 'error',
                        position: 'top-center',
                        duration: 2500,
                        singleton: true,
                    })
                })
        },
        // edititem(myURL, title, myWidth, myHeight) {
        //     var left = (screen.width - myWidth) / 2
        //     var top = screen.height - myHeight
        //        window.open(
        //         myURL,
        //         title,
        //         'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        //             myWidth +
        //             ', height=' +
        //             myHeight +
        //             ', top=' +
        //             top +
        //             ', left=' +
        //             left          
                   
        //     )
          
        // },
        logout() {
            let obj = ''
            axios
                .post(process.env.VUE_APP_BASEURL + '/logout', obj, {
                    headers: {
                        'Content-type': 'application/json',
                        token: `${localStorage.getItem('token')}`,
                    },
                })
                .then(() => {})
                .catch(() => {})
            localStorage.clear()

            this.$router.push('/login')
        },
    },
}
</script>

<style>
.bg-custom-1 {
    background-color: #85144b;
}

.bg-custom-2 {
    background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

.user_menu {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}
.dropdown-menu {
    cursor: pointer;
}
nav a.router-link-exact-active {
    color: black !important;
}
.nav_class {
    background-color: #2168c3;
}
.nav-item a {
    color: white !important ;
}
nav a.router-link-exact-active {
    color: white !important;
}
.profile_image {
    height: 36px !important;
}
.profile_name {
    color: white !important;
}
.user_menu {
    display: flex;
    align-items: center;
}
.dropdown-menu {
    background-color: #1962bf !important;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #2f82e9;
}
.navbar .dropdown .dropdown-toggle::after {
    position: absolute;
    top: 26px;
}
.navbar-brand img {
    width: auto;
    height: 80px;
    /*width: 140px;
    height: 48px !important;
    */
}

body #navbarNav .dropdown .dropdown-menu {
    padding: 5px;
}
/* @media (max-width:767px){
.navbar-light .navbar-toggler-icon {
    background-image: url(../src/assets/menu.png);
}} */

li.nav-item a.active {
    border-bottom: 2px solid;
	width: max-content
}

@media screen and (max-width:988px) {
	li.nav-item.dropdown{
		margin-left: 0 !important;
		margin-top: .5rem !important;
	}

	li.nav-item.dropdown a.nav-link.profile_name {
		margin-left:0px !important
	}
}
</style>