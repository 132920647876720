<template>
  <div>
    <div v-if="feedType == 'events'" class="mb-4 row observance_data w-100">
      <div
        class="d-flex observance_only justify-content-between bg-lights align-items-center"
      >
        <div
          class="text-align-center col-md-5"
          @click="changePlus"
          data-bs-toggle="collapse"
          :href="'#collapseExample' + ind"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <p class="mb-0">
            <a
              class="open_observance"
              data-bs-toggle="collapse"
              :href="'#collapseExample' + ind"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <span class="text-align-center">
                <span class="text-dark fw-bold">
                  {{ monthOptions && feeds.obs_created_date | moment("LL") }}
                  <!-- {{
                    dayOptions &&
                    dayOptions[new Date(feeds.obs_created_date).getDate() - 1]
                      .name
                  }} -->
                  -
                </span>
                <span class="text-dark">{{ feeds.thatEventName }}</span>
              </span>
            </a>
          </p>
        </div>
        <div class="my-2 d-flex justify-content-between col-6">
          <div class="d-flex align-items-center">
            <div class="rounded p-2 bg-xlights">
              <i class="bi bi-calendar-check"></i>
              {{ feeds.total_scheduled }} Scheduled
            </div>
          </div>

          <span
            @click="changePlus" 
            data-bs-toggle="collapse"
            :href="'#collapseExample' + ind"
            role="button"
          >
            <i :class="'bi fs-4 '+ (expand ? 'bi-dash-circle': 'bi-plus-circle')"></i>
          </span>
        </div>
      </div>

      <div class="collapse" :id="'collapseExample' + ind">
        <div v-for="(feed, index) in feeds.data" :key="index">
          <observance-card
            :total_copy_rem_tmp="total_copy_rem_tmp"
            :feeds="feed.clonned ? feed.clonned : feed"
            :ind="index"
            :upId="uploadId"
            :cancelFeed="isCancel"
            :uploadLogoShowToggle="uploadLogoShow"
            :isImageToggle="isImageLoad"
            @changeId="setId"
            @onselect="CountOfCopy"
            @showImgInPopup="showImgInPopup"
            @onSchedule="onSchedule"
            @updateLimit="updateLimit"
            @cancelFeed="cancelScheduleFeed"
            @openUploadLogoFeed="openUploadLogo"
            @LogoError="LogoErrorToggle"
			@scheduled="toggleScheduled"
          />
        </div>
      </div>
    </div>

    <div v-if="feedType != 'events' && feeds" class="observance_data w-100 p-0 mb-3">
		<div class="cards-sec"
		v-bind:style="[
			feeds.already_used
				? { 'background-color': '#f7f7e6', border: '1px solid #c6c6a3 ' }
				: { 'background-color': '#FFFFFF', border: '1px solid #cccccc' }
			]"
		>
			<div class="row m-0 d-flex px-0" :class="[feeds.scheduled_reviews_is_cancelled ? 'cancelled_feed_client' : '']">
				<div class="col-md-9 p-4">
					<h4 class="fw-bold d-flex">
						<u v-html="feeds.title"></u>
						<span class="ms-3 fs-5 cursor-pointer" v-tooltip="'Edit Feed'" title="Edit Feed">
							<a
								href="javascript:;" 
								v-if="feeds.is_scheduled"
								@click="editBtnWarningMsg"
							>
								<i class="bi bi-pencil-square"></i>
							</a>
							<router-link 
								v-else
								class=""
								aria-current="page"
								style="text-decoration: none"
								:to=" `/edit/post/${feeds.id}?IC=${isClonned}&&ITBC=${isToBeClonned}`"
							>
								<i class="bi bi-pencil-square"></i>
							</router-link>
						</span>
						
						<span
							v-if="feeds.type == 'custom' || feeds.type == 'review'" 
							class="text-danger ms-1 fs-5 cursor-pointer"
							v-tooltip="'Delete Feed'"
							title="Delete Feed"
							@click="deleteFeed(feeds)"
						>
							<i class="bi bi-trash"></i>
						</span>
					</h4>
					<!-- new Date(feeds.date).toLocaleString("en-us", { month: "short", year: "numeric", day: "numeric", }) -->
						<span class="mb-3 d-block">
							<span class="fw-bold">Type:&nbsp;</span>
							<span v-if="feeds.type == 'events'">
								Observance&nbsp;&nbsp;&nbsp;
							</span>
							<span v-if="feeds.type != 'events'">
								{{ $ucFirst(feeds.type) }}&nbsp;&nbsp;&nbsp;
							</span>
							<span v-if="feeds.type == 'events' && feeds.obs_day > 1">
								<span class="fw-bold">Date:</span>
								{{ monthOptions && monthOptions[feeds.obs_month - 1] ? monthOptions[feeds.obs_month - 1].name : '' }}
								{{ dayOptions && dayOptions[feeds.obs_day - 1] ? dayOptions[feeds.obs_day - 1].name : '' }}
							</span>
							<span v-if="feeds.type == 'events' && feeds.obs_day < 2">
								<span class="fw-bold">Date:</span>
								{{ monthOptions && monthOptions[feeds.obs_month - 1] ? monthOptions[feeds.obs_month - 1].name : '' }}
								{{ dayOptions && dayOptions[0] ? dayOptions[0].name : '' }}
							</span>
						</span>
						<div class="p-3 d-block mb-4 unselectable"
							v-bind:style="[ 
								feeds.already_used 
								? { 'background-color': '#f7f7e6', border: '2px dotted #c6c6a3 '}
								: { 'background-color': '#f7f7f7', border: '2px dotted #cccccc'}
							]"
							unselectable="on"
						>
							<div class="noselect feed-body" style="white-space: pre-line" >
							<span v-html="feeds.type != 'external' && textCount > 200 ? readMoreEnabled  ? showFeedHtml(feeds) : showFeedHtml(feeds).substr(0, 200)+'.....' : showFeedHtml(feeds)"></span>
								<template v-if="feeds.type != 'external' && textCount > 200">
									<a v-if="!readMoreEnabled" @click="readMoreEnabled = true" class="ms-2">Read More</a>
									<a v-if="readMoreEnabled" @click="readMoreEnabled = false" class="ms-2">Read Less</a>
								</template>
							</div>
						</div>
					<div class="" v-if="!feeds.is_monthly_review_scheduled">
						<!-- v-clipboard:success="onCopy"
						 v-clipboard:error="onError" -->
						<div class="d-flex flex-wrap">
							<div class="me-2 my-1">
								<button
									type="button"
									v-if="!feeds.is_scheduled"
									@click="openSchedule(feeds)"
									class="btn btn-info text-white"
								>
									Schedule
									<span v-if="isSpin" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
								<!-- <button type="button" v-if="feeds.is_scheduled" class="btn btn-secondary bg-light text-dark cursor-not-allowed"> Scheduled</button> -->
								<button type="button" v-if="feeds.is_scheduled" @click="openReSchedule(feeds)" class="btn btn-secondary bg-light text-dark">
									Re-Schedule
								</button>
								<button 
									type="button"
									v-if="feeds.is_scheduled"
									@click="cancelScheduleFeed(feeds)"
									:class="isCancel == feeds.id ? 'disabled' : ''"
									class="ms-2 btn btn-secondary text-light"
								>
									Cancel Feed
									<span v-if="isCancel == feeds.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
							</div>
						</div>
						<div class="d-flex justify-content-between" v-if="feeds.last_used || (parseInt(feeds.client_usage_count) + parseInt(feeds.used)) >= 10">
							<div class="wbox-stats">
								<span class="m-1" v-if="feeds.scheduled_date">
									<b>{{ feeds.is_scheduled == 1 ? 'Scheduled' : 'Last Scheduled' }} Date time: </b>
									<span class="lastused" style="font-size: 11px">
										{{ formatDateTime(feeds.scheduled_date) }}
									</span>
								</span>
								<span class="m-1" v-if="feeds.last_used">
									<b>Last Used: </b>
									<span class="lastused" style="font-size: 11px">
										{{feeds.last_used | moment("YYYY-MM-DD")}}
									</span>
								</span>
								<span v-if="(parseInt(feeds.client_usage_count) + parseInt(feeds.used)) >= 10">
									<b>Total Usages: </b>
									<span class="usedcount" style="font-size: 11px">
										{{ parseInt(feeds.client_usage_count) + parseInt(feeds.used) }}
									</span>
								</span>
							</div>
						</div>
					</div>
					<div class="" v-else-if="feeds.is_monthly_review_scheduled">
						<div class="d-flex justify-content-between">
							<div class="wbox-stats" style="font-size: 15px">
								<span class="m-1" v-if="feeds.scheduled_at">
									<b>Planned To Post on: </b>
									<span class="lastused" style="font-size: 13px">
										{{ formatDate(feeds.scheduled_at) }}
									</span>
								</span>
							</div>
						</div>
						<div class="d-flex justify-content-start mt-3">
							<button v-if="!feeds.scheduled_reviews_is_cancelled" @click="cancelScheduleReview(feeds.scheduled_reviews_id)" class="btn btn-danger btn-sm">Cancel Review Feed</button>
							<button v-if="feeds.scheduled_reviews_is_cancelled" @click="cancelScheduleReview(feeds.scheduled_reviews_id)" class="btn btn-primary btn-sm">Restore Cancelled Feed</button>
						</div>
						<div class="d-flex justify-content-start mt-2" v-if="feeds.scheduled_reviews_is_cancelled">
							<span class="badge bg-danger text-white">Cancelled</span>
						</div>
					</div>
				</div>
            
				<div class="col-md-3 px-4 py-2 my-auto"  v-if="feeds.type == 'external'">
					<div class="h-auto m-0">
						<videoCard :feed="feeds"></videoCard>
					</div>
				</div>

				<div class="col-md-3 px-4 py-2 my-auto" v-else-if="feeds.type != 'external' && feeds.media && feeds.media.type == 'image'">
					<div class="card-images m-0">
						<div class="text-center position-relative h-100" :class="!isImage ? 'p-2' : ''">
							<img
								v-if="feeds.media && !isImage && isImageLoad != feeds.id"
								:id="`feed-img`"
								:src="feeds.media.url"
								class="content-img1"
								alt=""
								@click="showImgInPopup($event, feeds.id)"
							/>
							<div
							v-if="isImage || isImageLoad == feeds.id"
							class="position-absolute d-flex h-100 w-100 justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							</div>
						</div>
					</div>
					<div v-if="feeds.client_logo_support && feeds.media != null" class="p-3 pb-md-0 gap-2 d-flex align-items-center justify-content-center mb-1">
						<div class="form-check form-switch position-relative">
							<span
								v-if="uploadLogoShow == feeds.id"
								:id="'logo-spinner'"
								class="spinner-border spinner-border-sm text-success custom-spinner position-absolute"
								role="status"
								aria-hidden="true"
							></span>
							<input
								v-tooltip="`Attach / Remove Logo from feed image. <br/><br/> <b>Note:</b> To change the logo, go to 'My Profile' by clicking on the dropdown in the top right corner. Then, click the 'Upload Logo' button. A popup will appear, allowing you to update the logo.`"
								v-if="uploadLogoShow != feeds.id && isImageLoad != feeds.id"
								v-bind:style="{ cursor: 'pointer' }"
								:disabled="typeof isImageLoad == 'number'"
								class="form-check-input custom-checkbox border-success mt-0 position-absolute"
								type="checkbox"
								:id="'fd-logo'"
								@change="updateLogoStatus($event, feeds)"
								v-bind:checked="feeds.logo_status == 1 ? true : false"
							/>
						</div>
						<span>{{ feeds.logo_status == 1 ? "Remove your logo" : "Display your logo"}}</span>
					</div>
				</div>

				<div class="col-md-3 px-4 py-2 my-auto" v-else-if="feeds.media.type == 'url'">
					<div class="card-images m-0" :class="[!isImage ? 'h-auto' : '']">
						<div class="text-center position-relative h-100" :class="[!isImage ? 'p-2' : '']">
							<template v-if="['gif','jpg','jpeg','png'].includes(checkUrlExtension(feeds.media.url)) || feeds.type == 'review'">
								<img 
									v-if="feeds.media && !isImage && isImageLoad != feeds.id"
									:id="`feed-img`"
									:src="feeds.media.url"
									class="content-img1"
									alt=" "
									@click="showImgInPopup($event, feeds.id)"
								/>
								<div v-if="isImage || isImageLoad == feeds.id" class="position-absolute d-flex h-100 w-100 justify-content-center align-items-center">
									<div class="spinner-border" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</div>
							</template>
							<div class="h-auto m-0" v-else-if="checkUrlExtension(feeds.media.url) == 'youtube'">
								<videoCard :feed="feeds"></videoCard>
							</div>
							<div class="h-auto m-0" v-else>
								<video :class="`video_player_${feeds.id}`" controls width="100%">
									<source :src="feeds.media.url ? feeds.media.url : getVideoUrl()" >
									Your browser does not support HTML5 video.
								</video>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-3 px-4 py-2 my-auto" v-else-if="feeds.media_type && feeds.media_type == 'video'">
					<div class="h-auto m-0" >
						<video :class="`video_player_${feeds.id}`" controls width="100%">
							<source :src="feeds.media.url ? feeds.media.url : getVideoUrl()" >
							Your browser does not support HTML5 video.
						</video>
					</div>
				</div>

        	</div>
        <div
          v-show="sampleBox[ind] && indexSamp"
          style="width: 95%; margin: 2%"
        >
          <textarea style="width: 100%; height: 200px" v-model="feeds.body">
          </textarea>
          <button
            class="btn btn-secondary bg-white text-dark"
            @click="hideSampleBox(ind)"
            v-tooltip="'Click to hide sample box this as Used'"
            id="undo_93859"
          >
            <i class="fas fa-times"></i>
            HIDE SAMPLE BOX
          </button>
        </div>
      </div>
    </div>
    
    <schedule-modal
		:connected_accounts="ayr_connected_accounts"
		:load="load"
		:feed="feeds"
		@scheduled="toggleScheduled"
		:date="schdate"
	>
	</schedule-modal>
  </div>
</template>

<script>
import videoCard from "./video.vue";
import ScheduleModal from "./scheduleModal.vue";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";
import "vue2-datepicker/index.css";
import axios from "axios";
import observanceCard from "./observance-card.vue";

moment.tz.setDefault(`${localStorage.getItem("time_zone") != '' ? localStorage.getItem("time_zone") : moment.tz.guess()}`);

export default {
	name: "Card",
	components: {
		observanceCard,
		ScheduleModal,
		videoCard
	},
	props: {
		feeds: {
			type: Object,
		},
		upId: {
			type: Object,
		},
		feedType: String,
		businessTitle: String,
		introductoryText: String,
		ind: Number,
		keyId: Number,
		total_copy_rem_tmp: Number,
		cancelFeed: [Boolean, Number],
		uploadLogoShowToggle: [Boolean, Number],
		isImageToggle: [Boolean, Number],
		// upload_status: Number,
	},
	watch: {
		feedType: function () {
			this.expand = false;
		},
		feeds: function (val, oldVal) {
			if(val.id)
			{
				let body = this.showFeedHtml(val)
				this.readMoreEnabled = false;
				if(body)
				{
					this.textCount = body.length
				}
			}
			this.isImage = true;
			setTimeout(() => {
				this.isImage = false;
			}, 1000);
		},
		total_copy_rem_tmp: function (val, oldVal) {
		this.total_copy_rem = parseInt(val);
		},
		cancelFeed: function (val, oldVal) {
		this.isCancel = val;
		},
		uploadLogoShowToggle: function (val, oldVal) {
		this.uploadLogoShow = val;
		},
		isImageToggle: function (val, oldVal) {
			this.isImageLoad = val;
		},
	},
	data() {
		return {   
			content: "",
			uploadId: {},
			total_copy_rem: "",
			imagedata: "",
			uploadLogoShow: false,
			id: "",
			scheduleDate: "",
			isSpin: false,
			fd: "",
			socialAccounts: ["Twitter", "Instgram"],
			Platforms: [],
			isCancel: false,
			expand: false,
			fed_di: "",
			schDate: "",
			load: false,
			refresh: 0,
			default: "",
			bgColor: false,
			isSample: false,
			sampleBox: [false],
			indexSamp: false,
			isSample1: false,
			ob_time_picker:"",
			checkUploadStatus: "",
			user: {},
			ayr_connected_accounts: "",
			schdate: "",
			showReset: false,    
			isImage: false,
			isImageLoad: false,
			selectedAccounts: [],     
			popupImgSrc: null,      
			randNo: Math.floor(Math.random() * 10000000),
			monthOptions: [
				{
				name: "January",
				value: 1,
				},
				{
				name: "February",
				value: 2,
				},
				{
				name: "March",
				value: 3,
				},
				{
				name: "April",
				value: 4,
				},
				{
				name: "May",
				value: 5,
				},
				{
				name: "June",
				value: 6,
				},
				{
				name: "July",
				value: 7,
				},
				{
				name: "August",
				value: 8,
				},
				{
				name: "Septempber",
				value: 9,
				},
				{ name: "October", value: 10 },
				{
				name: "November",
				value: 11,
				},
				{
				name: "December",
				value: 12,
				},
			],
			dayOptions: [
				{
					name: "1st",
					value: 1,
				},
				{
					name: "2nd",
					value: 2,
				},
				{
					name: "3rd",
					value: 3,
				},
				{ name: "4th", value: 4 },
				{
					name: "5th",
					value: 5,
				},
				{ name: "6th", value: 6 },
				{ name: "7th", value: 7 },
				{ name: "8th", value: 8 },
				{ name: "9th", value: 9 },
				{ name: "10th", value: 10 },
				{ name: "11th", value: 11 },
				{ name: "12th", value: 12 },
				{ name: "13th", value: 13 },
				{ name: "14th", value: 14 },
				{ name: "15th", value: 15 },
				{ name: "16th", value: 16 },
				{ name: "17th", value: 17 },
				{ name: "18th", value: 18 },
				{ name: "19th", value: 19 },
				{ name: "20th", value: 20 },
				{ name: "21st", value: 21 },
				{ name: "22nd", value: 22 },
				{ name: "23rd", value: 23 },
				{ name: "24th", value: 24 },
				{ name: "25th", value: 25 },
				{ name: "26th", value: 26 },
				{ name: "27th", value: 27 },
				{ name: "28th", value: 28 },
				{ name: "29th", value: 29 },
				{ name: "30th", value: 30 },
				{ name: "31st", value: 31 },
			],
			textCount:0,
			readMoreEnabled:false
		};
	},
	computed: {
		...mapGetters(["userList"]),
		isClonned:function(){
			return this.feeds && this.feeds.parent_id ? true : false;
		},
		isToBeClonned:function(){
			return this.feeds && !this.feeds.parent_id && !this.feeds.review_id && this.feeds.type != 'custom' ? true : false;
		}
	},
	created() {
		if(this.feeds.id)
		{
			let body = this.showFeedHtml(this.feeds)
			this.readMoreEnabled = false;
			if(body)
			{
				this.textCount = body.length
			}
		}
		this.total_copy_rem = parseInt(this.total_copy_rem_tmp);
		// this.uploadStatus = this.upload_status
	},
	methods: {
		...mapActions(["fetchUser", "updateUser", "deletefeeds"]),
		checkCopyLimit() {
		this.$toasted.show(`Your copy Quota limit reached`, {
			theme: "bubble",
			type: "warning",
			position: "top-center",
			duration: 2000,
			singleton: true,
		});
		},
		updateLogo(feed, status) {
		$(".custom-checkbox").prop("disabled", true);
		let obj = {
			feed_id: feed.id,
			logo_status: status,
		};
		this.isImage = obj.feed_id;
		this.uploadLogoShow = obj.feed_id;
		// console.log(checked)
		axios
			.post(process.env.VUE_APP_BASEURL + `/update-custom-media`, obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then((res) => {
			if (res.data.status == true) {
				if (status == 1) {
				var logo_text = "Logo Successfully Added";
				} else {
				var logo_text = "Logo Successfully Removed";
				}
				feed.logo_status = status;
				this.$toasted.show(logo_text, {
				theme: "bubble",
				type: "success",
				position: "top-center",
				duration: 2000,
				singleton: true,
				});
				feed.media.url = res?.data?.new_media_url;
				this.isImage = false;
				this.uploadLogoShow = false;
				//console.log('hello')
				//this.$router.go()
				$(".custom-checkbox").prop("disabled", false);
			} else {
				if (status == 1) {
				feed.logo_status = 0;
				} else {
				feed.logo_status = 1;
				}
				this.uploadLogoShow = false;
				this.isImage = false;
				this.$toasted.show(`${res.data?.error_message}`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 2000,
				singleton: true,
				});
				$(".custom-checkbox").prop("disabled", false);
			}
			})
			.catch((err) => {
			if (status == 1) {
				feed.logo_status = 0;
			} else {
				feed.logo_status = 1;
			}
			this.uploadLogoShow = false;
			this.isImage = false;
			if (err?.response?.data?.status == false) {
				if (err?.response?.status == 403) {
				this.$emit('LogoError', true);
				this.openUploadLogo(feed);
				} else {
				this.$toasted.show(`${err?.response?.data?.error_message}`, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 2000,
					singleton: true,
				});
				}
			}
			$(".custom-checkbox").prop("disabled", false);
			});
		},
		// openModalImage(obj){
		//   this.selectedFile=null
		//   this.imgSrc=null
		//   this.$emit('changeId',obj)
		//   $('#feed-id-popup').val(obj.id)
		//   $('#Image-upload').modal('show')
		// },
		ImageSet(obj) {
		this.imagedata = obj;
		},
		setId(obj) {
		this.uploadId = obj;
		},
		deleteFeed(feed) {
		this.$emit("deleteFeed", feed);
		},
		cancelScheduleFeed(feed) {
		this.$emit("cancelFeed", feed);
		},
		toggleScheduled(last_used, used_count, status = null, date = null) {
			this.feeds.is_scheduled = 1
			this.feeds.last_used = last_used
			
			if(used_count)
			{
				this.feeds.used = used_count
			} 
			
			if(status)
			{
				this.feeds.scheduled_status = status
			}
			
			if(date)
			{
				this.feeds.scheduled_date = date
			}
		},
		setSocialAccount(event) {},
		openReSchedule(feed) {
		this.$emit("Scheduling", this.feeds);    
			this.load = true;
			$(`#reScheduledModal${feed.id}`).modal("show");    
			this.schdate = feed.obs_created_date;
			this.$helper.getRequest(`get-user-data`).then((res) => {
				if(res?.status)
				{
					this.ayr_connected_accounts = res?.user_data?.ayr_connected_accounts;
				}
				else
				{
					this.$toasted.show(`Something Went Wrong`, {
						theme: "bubble",
						type: "error",
						position: "top-center",
						duration: 1500,
						singleton: true
					});
				}
				this.load = false;
			})
			.catch(() => {
				this.load = false;
			});
		},
		openSchedule(feed) {
		this.$emit("Scheduling", this.feeds);     
		this.load = true;
		let curr;
		let dif;
		let c;
		
		if (feed.scheduled_date && feed?.scheduled_status != "Cancel") {
			let sc_date = moment(feed.scheduled_date).toDate();
			curr = moment().toDate();
			let sc = moment(sc_date).add(3, "days");
			c = moment(sc_date).add(3, "days").toDate();
			dif = moment.duration(moment(c).diff(curr)).asDays();
			// console.log('diif',dif)
		}

		if (feed.scheduled_date && curr < c) {
			this.$toasted.show(
			`This Feed is already posted. You can Schedule this feed after ${moment(c).format("YYYY-MM-DD")} `,
			{
				theme: "bubble",
				type: "warning",
				position: "top-center",
				duration: 2500,
				singleton: true,
			}
			);
		} else {
			$(`#scheduledModal${feed.id}`).modal("show");
			this.schdate = feed.obs_created_date;
			axios
			.get(process.env.VUE_APP_BASEURL + "/get-user-data", {
				headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				if (res?.data?.status) {
				this.ayr_connected_accounts =
					res?.data?.user_data?.ayr_connected_accounts;
				} else {
				this.$toasted.show(`Something Went Wrong`, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 1500,
					singleton: true,
				});
				}
				this.load = false;
			})
			.catch(() => {
				this.load = false;
			});
		}
		},      
		openUploadLogo(feed) {
		this.$emit("changeId", feed);
		this.$emit('openUploadLogoFeed', feed)
		},
		changePlus() {
		this.expand = !this.expand;
		},
		onSchedule(obj) {
		this.feeds.total_scheduled += obj;
		},
		CountOfCopy(obj) {
		// console.log('obj',obj)

		this.feeds.total_copied += obj;
		},
		doCopy() {
		let obj = {
			feed_id: this.feeds.feed_id,
		};
		// console.log(obj);
		axios
			.post(process.env.VUE_APP_BASEURL + "/copyContent", obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then(() => {
			if (this.feeds.type == "external") {
				this.$copyText(this.feeds.body + " " + this.feeds.media.url);
			} else {
				this.$copyText(this.feeds.body);
			}
			// this.total_copy_rem -= 1
			this.$emit("updatedLimit");
			this.feeds.already_used = 1;
			this.feeds.used = this.feeds.used + 1;
			this.feeds.last_used = new Date();
			this.onCopy();
			//console.log(e)
			})
			.catch((err) => {
			// console.log(err.message)
			if (err.message == "Request failed with status code 422") {
				this.$toasted.show(`Your copy Quota limit reached `, {
				theme: "bubble",
				type: "warning",
				position: "top-center",
				duration: 1500,
				singleton: true,
				});
			}
			});
		},
		downloadImg(responseUrl) {
			saveAs(responseUrl);
		},
		handleReset() {
		let obj = {
			feed_id: this.feeds.feed_id,
		};
		axios
			.post(process.env.VUE_APP_BASEURL + "/resetCopyContent", obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then(() => {
			this.feeds.already_used = 0;
			this.showReset = false;
			this.indexSamp = false;
			this.bgColor = false;
			})
			.catch(() => {
			this.$toasted.show(`Something went wrong`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 1500,
				singleton: true,
			});
			});
		},
		hideSampleBox(ind) {
		// console.log( this.sampleBox[ind],ind)
		this.indexSamp = false;
		this.sampleBox[ind] = false;
		},
		addDiv(ind) {
		this.indexSamp = true;
		setTimeout(() => {
			this.indexSamp = false;
			this.sampleBox[ind] = false;
		}, 2500);
		},
		onCopy: function (e) {
		this.bgColor = true;
		this.isSample = true;
		this.sampleBox[this.ind] = true;
		setTimeout(() => {
			this.isSample = false;
			this.showReset = true;
			this.isSample1 = true;
		}, 2500);
		},
		onError: function (e) {
		alert("Failed to copy the text to the clipboard");
		// console.log(e);
		},
		showImgInPopup(event, id) {
		// console.log(id)
		this.$emit("showImgInPopup", event, id);
		// this.popupImgSrc = event.target.getAttribute('src')
		// $('#showImgInPopup'+id).modal('show')
		},
		updateLogoStatus(e, feeds) {
		if (e.target.checked) {
			this.updateLogo(feeds, 1);
		} else {
			this.updateLogo(feeds, 0);
		}
		},
		getImgUrl(src) {
		return src + "?" + this.randNo;
		},
		showFeedHtml(feed) {
			if(feed)
			{
				if(feed.type == "external")
				{
					if (feed.media.url)
					{
						return (
						feed.body +
						"<br><br>" +
						'<a target="_blank" href="' +
						feed.media.url +
						'">' +
						feed.media.url +
						"</a>"
						);
					}
					else
					{
						return feed.body;
					}
				}
				else if(feed.type == "custom")
				{
				
				if (feed.media?.url && feed.media?.type=="url" ) {
					
					return (
					feed.body +
					'<br><a target="_blank" href="' +
					feed.media.url +
					'">' +
					feed.media.url +
					"</a>"
					);
				}else{
					
					return feed.body;
				}
		
				}
				else if(feed.type == "review")
				{
					let introductoryText = this.introductoryText
					let body = '';

					if(feed.review && (feed.review.edited_text || feed.review.short_description))
					{
						body = feed.review.text; 
					}
					else
					{
						body = feed.body;
					}

					if(feed.review && feed.review.introductory_text)
					{
						introductoryText = feed.review.introductory_text;
					}
					else if(introductoryText)
					{
						introductoryText = introductoryText.replace('%customer_name%', feed.title) 
						introductoryText = introductoryText.replace('%client_company%', this.businessTitle)
					}

					if(introductoryText && body)
					{
						return `${introductoryText} ${body}`;
					}
					else
					{
						return feed.body;
					}
				}
				else if(feed.media && feed.media.url && !['mp4','mov','gif','jpg','jpeg','png'].includes(this.checkUrlExtension(feed.media.url)))
				{
					if(feed.media.url) 
					{
						return (feed.body + "<br><br>" + '<a target="_blank" href="' + feed.media.url +'">' + feed.media.url + "</a>");
					}
					else
					{
						return feed.body;
					}
				}
				else
				{
					return feed.body;
				}
			}
		},
		updateLimit() {
		this.$emit("updatedLimit");
		},
		LogoErrorToggle(val){
		this.$emit('LogoError', val)
		},
		formatDateTime:function(dateTime){
				return moment.utc(dateTime).tz(`${localStorage.getItem("time_zone") != '' ? localStorage.getItem("time_zone") : moment.tz.guess()}`).format("YYYY-MM-DD hh:mm A");
		},
		editBtnWarningMsg:function(){
			alert('Please cancel the feed first in order to update it.')
		},
		formatDate:function(date){
			//hh:mm A
			return moment(date).format("ddd MMM DD, YYYY ")
		},
		getVideoUrl:function(){
			if(this.feeds.video_data)
			{
				let videoData = JSON.parse(this.feeds.video_data)
				if(videoData)
				{
					let videoPlayer = document.querySelector(`video.video_player_${this.feeds.id}`)
					
					if(videoPlayer)
					{
						setTimeout(function(){
							videoPlayer.load()
						},1000)
					}

					if(videoData.videoValidateMode == 'SIMPLE')
					{
						return videoData.validateVideo.SIMPLE+`?${Math.random()}=${Math.random()}sample`
					}
					else if(videoData.videoValidateMode == 'ADVANCED')
					{
						if(videoData.successPlatforms.ADVANCED && videoData.successPlatforms.ADVANCED.length > 0)
						{
							return videoData.validateVideo.ADVANCED[videoData.successPlatforms.ADVANCED[0]]+`?${Math.random()}=${Math.random()}advanced`
						}
						else if(videoData.errorPlatforms.ADVANCED && videoData.errorPlatforms.ADVANCED.length > 0)
						{
							return videoData.validateVideo.ADVANCED[videoData.errorPlatforms.ADVANCED[0]]+`?${Math.random()}=${Math.random()}advanced`
						}
					}
				}
			}
			return '';
		},
		cancelScheduleReview:function(id){
			if(confirm("Are you sure ?"))
			{
				this.$helper.getRequest(`user/monthly-reviews/${id}/cancel-schedule`).then((resp) => {
					if(resp.status)
					{
						this.feeds.scheduled_reviews_is_cancelled = resp.is_cancelled
						this.$notify(resp.message)
					}
					else
					{
						this.$notify(resp.message ? resp.message : 'something went wrong please try in some time','danger')
					}
				})
			}
		},
		checkUrlExtension:function(url){
			if(url)
			{
				if(url.includes('youtube'))
				{
					return 'youtube';
				}
				else
				{
					url = url.split('?')[0]
					let urlArray = url.split('.');
					if(urlArray.length > 0)
					{
						return urlArray[urlArray.length-1];
					}
				}
			}
			return '';
		}
	}
};
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
.wbox-stats {
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  color: #444444;
}
.content-img1 {
  height: 100% !important;
  max-height: none;
  max-width: 100% !important;
  cursor: pointer;
  image-rendering: -webkit-optimize-contrast;
}
.observance_only {
  background-color: aliceblue;
  border: 1px solid grey;
}
.open_observance {
  text-decoration: none;
}
.icons-profile i {
  cursor: pointer;
}
.is-invalid {
  border: 1px solid red !important;
  border-radius: 5px;
}

.profile-input {
  position: relative;
  display: block;
  cursor: pointer;
  height: 200px;
  width: 200px;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  background: #f3f3f3;
}
.hoverable .hover-text {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.hoverable .background {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
}
.hoverable:hover .hover-text {
  display: block;
}
.hoverable:hover .background {
  display: block;
}

.fileInput,
#fileInput {
  display: none;
}
.profile-edit-sec {
  background: #2f9ef7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-pick .input-group {
  flex-wrap: nowrap !important;
}

.popup-img-div {
  text-align: center;
}
.popup-d {
  width: fit-content;
}
.custom-btn {
  position: absolute;
  right: -37px;
  z-index: -1;
}
.feed-body img {
  height: 40px;
}
.external-videos .video {
  height: 198px;
}
.custom-checkbox:checked {
  background-color: #198754 !important;
}
.custom-checkbox {
  left: 16px;
  top: -2px;
}
.custom-spinner {
  left: 8px;
  top: 5px;
}
</style>
