<template> 
    <!-- Modal -->
    <div class="modal fade" id="monthlyFeedModal" tabindex="-1" aria-labelledby="monthlyFeedModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md  modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="monthlyFeedModalLabel">{{ feedItems.title }}</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="visible">           
             <div v-if="feedItems.media_type == 'video' ||feedItems.media_type == 'url'">              
                <p class="mt-2" v-html="feedItems.body"></p>
                <p>{{ feedMedia.media.url }}</p>
              </div>
              <div  v-else>
                <div class="d-flex align-items-center justify-content-center" id="feedViewImg" style="min-height: 400px;">
                      <img :src="feedMedia.media.url" class="img-fluid">
                </div>               
                <p class="mt-2" v-html="feedItems.body"></p>
              </div>
          </div>       
        </div>
      </div>
    </div>
  </template>  
  <script>
  import videoCard from "./../../user/video.vue";
  export default {
   components: {    
    videoCard,
  },
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      feedItems: {
        type: Object,
      
      },
      feedMedia: {
        type: '',      
      }
    },
      data() {
      return {      
       loading: false,
      }
    },
    methods: {
      close() {
        this.$emit('close'); // Emit an event to close the modal
      },
    
    }
  };
  </script>
  
<style>
#feedViewImg .img-fluid {
    max-width: 100%;
    height: auto !important;
}
  </style>
  