<template>
   <div>
      
      <!-- Show Scheduled Feed History-->
      <div class="modal fade" id="scheduledFeedModal" tabindex="-1" aria-labelledby="scheduledFeedModalLabel" aria-hidden="true">
         <div class="modal-dialog  modal-dialog-scrollable modal-fullscreen" >
            <div class="modal-content ">
               <div class="modal-header">
                  <h6 class="modal-title" id="scheduledFeedModalLabel">Monthly Feed History  </h6>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
               
                  <nav>
                     <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="upcoming-feed-tab"  data-bs-toggle="tab"  data-bs-target="#upcoming-feed" type="button" role="tab" aria-controls="upcoming-feed" aria-selected="true" @click="scheduledFeeds()">Upcoming</button>
                        <button class="nav-link" id="past-feed-tab" data-bs-toggle="tab" data-bs-target="#past-feed" type="button" role="tab" aria-controls="past-feed" aria-selected="false" @click="scheduledFeeds()">Past</button>
                     </div>
                  </nav>
                  <div >
                  <div class="tab-content" id="nav-tabContent" >
                     <div class="tab-pane fade show active" id="upcoming-feed" role="tabpanel" aria-labelledby="upcoming-feed-tab" >
                      <vue-good-table
                        :columns="upcomingColumns"
                        :rows="upcomingFeed"
                        :isLoading="isLoading"                        
                        :fixed-header="true"                      
                        :sort-options="{
                        enabled: true,
                        initialSortBy: { field: 'scheduled_date', type: 'desc' },
                        }"
                        :search-options="{ enabled: true, placeholder: 'Search ' }"
                        :pagination-options="{
                        enabled: true,
                        dropdownAllowAll: false,
                        perPageDropdown: [10, 20, 30, 40, 50],
                        perPage: 10,
                        mode: 'pages',
                        }"
                        >
                 <template slot="table-row" slot-scope="props">                 
                  <span v-if="props.column.field === 'title'">                               
                        {{ props.formattedRow['title']}}
                     <div><strong>Total Clients:</strong> 
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#scheduledClients"  @click="openScheduledClient(props.row)">
                              [{{ props.row.total_clients}}]
                        </a>
                     </div>
                  </span>
                  <span v-if="props.column.field === 'post'">
                     <span v-html="props.formattedRow['post']"></span>                             
                  </span>
                  <span v-if="props.column.field === 'scheduled_date'">
                        {{ changeTimeFormate(props.formattedRow[props.column.field]) }}
                  </span>
                  <span v-if="props.column.field === 'scheduled_status'" class="badge bg-info">
                        {{ props.formattedRow[props.column.field] }}
                  </span>
                   <span v-if="props.column.field === 'actions'">
                     <a href="#" data-bs-toggle="modal" v-tooltip="'Edit Monthly Feeds'"    
                        data-bs-target="#editScheduledFeedModal" @click="editScheduledFeed(props.row.feed_id,props.formattedRow['scheduled_date'])" aria-label="Edit">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="25px" height="25px">
                           <linearGradient id="0O0q6J4HBgQKyT39nvTa~a" x1="46.807" x2="46.807" y1="9.849" y2="24.215" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
                              <stop offset="0" stop-color="#6dc7ff" />
                              <stop offset="1" stop-color="#e6abff" />
                           </linearGradient>
                           <path fill="url(#0O0q6J4HBgQKyT39nvTa~a)" d="M49.482,24.392l-9.874-9.874l4.232-4.232c0.39-0.39,1.021-0.39,1.411,0l8.464,8.464c0.39,0.39,0.39,1.021,0,1.411L49.482,24.392z" />
                           <linearGradient id="0O0q6J4HBgQKyT39nvTa~b" x1="32" x2="32" y1="8.084" y2="55.83" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
                              <stop offset="0" stop-color="#1a6dff" />
                              <stop offset="1" stop-color="#c822ff" />
                           </linearGradient>
                           <path fill="url(#0O0q6J4HBgQKyT39nvTa~b)" d="M50.697,25.999l4.428-4.428c1.167-1.167,1.167-3.065,0-4.232l-8.464-8.464c-1.167-1.167-3.065-1.167-4.232,0l-4.428,4.428c-0.664-0.175-1.4-0.011-1.92,0.509l-1.411,1.411c-0.52,0.52-0.684,1.256-0.509,1.92L11.198,40.106l-0.508,0.508l-0.2,0.2l-2.373,9.967c-0.343,1.442,0.078,2.928,1.126,3.976s2.534,1.469,3.976,1.125l9.967-2.373l0.2-0.2l0.508-0.508l22.964-22.964c0.664,0.175,1.4,0.011,1.92-0.509l1.411-1.411C50.708,27.399,50.872,26.663,50.697,25.999z M47.367,27.92L36.081,16.634l1.411-1.411l11.285,11.285L47.367,27.92z M23.46,50.414c-0.28-1.063-0.682-2.077-1.198-3.034l20.872-20.872l2.116,2.116L23.46,50.414z M14.916,53.428c-0.12-1.074-0.58-2.115-1.405-2.939c-0.825-0.825-1.865-1.285-2.939-1.405l0.698-2.931c1.649,0.266,3.173,1.036,4.357,2.22c1.184,1.184,1.954,2.709,2.22,4.357L14.916,53.428z M17.038,46.962c-1.447-1.447-3.301-2.396-5.306-2.75l0.463-1.943c2.382,0.441,4.533,1.562,6.254,3.282s2.841,3.872,3.282,6.254l-1.943,0.463C19.433,50.263,18.485,48.409,17.038,46.962z M19.859,44.141c-0.477-0.477-0.987-0.907-1.517-1.304l20.561-20.561l2.821,2.821L21.163,45.658C20.766,45.128,20.336,44.618,19.859,44.141z M16.62,41.738c-0.957-0.516-1.971-0.918-3.034-1.198l21.79-21.79l2.116,2.116L16.62,41.738z M43.84,10.286c0.389-0.389,1.022-0.389,1.411,0l8.464,8.464c0.389,0.389,0.389,1.022,0,1.411l-4.232,4.232l-9.874-9.874L43.84,10.286z" />
                        </svg>
                     </a>
                     <a href="#" v-tooltip="'Cancel Monthly Feeds'"    @click.prevent="cancelScheduledFeed(props.row.feed_id,props.formattedRow['scheduled_date'])" aria-label="Delete">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px">
                           <linearGradient id="GCWVriy4rQhfclYQVzRmda" x1="9.812" x2="38.361" y1="9.812" y2="38.361" gradientUnits="userSpaceOnUse">
                              <stop offset=".443" stop-color="#ee3d4a" />
                              <stop offset="1" stop-color="#e52030" />
                           </linearGradient>
                           <path fill="url(#GCWVriy4rQhfclYQVzRmda)" d="M24,4C12.955,4,4,12.955,4,24s8.955,20,20,20s20-8.955,20-20C44,12.955,35.045,4,24,4z M24,38c-7.732,0-14-6.268-14-14s6.268-14,14-14s14,6.268,14,14S31.732,38,24,38z" />
                           <linearGradient id="GCWVriy4rQhfclYQVzRmdb" x1="6.821" x2="41.08" y1="6.321" y2="40.58" gradientTransform="translate(-.146 .354)" gradientUnits="userSpaceOnUse">
                              <stop offset="0" stop-color="#f44f5a" />
                              <stop offset=".443" stop-color="#ee3d4a" />
                              <stop offset="1" stop-color="#e52030" />
                           </linearGradient>
                           <polygon fill="url(#GCWVriy4rQhfclYQVzRmdb)" points="13.371,38.871 9.129,34.629 34.629,9.129 38.871,13.371" />
                        </svg>
                     </a>
                  </span>             
                </template>
                </vue-good-table>                                      
                     </div>
                  <div class="tab-pane fade" id="past-feed" role="tabpanel" aria-labelledby="past-feed-tab">
                     <vue-good-table
                        :columns="pastColumns"
                        :rows="pastFeed"  
                        :isLoading="isLoading"                        
                        :fixed-header="true"
                        :sort-options="{
                        enabled: true,
                        initialSortBy: { field: 'scheduled_date', type: 'desc' },
                        }"
                        :search-options="{ enabled: true, placeholder: 'Search ' }"
                        :pagination-options="{
                        enabled: true,
                        dropdownAllowAll: false,
                        rowsPerPageLabel: 'Feeds per page',
                        perPageDropdown: [10, 20, 30, 40, 50],
                        perPage: 10,
                        mode: 'records',
                        }"
                        >
                        <template slot="table-row" slot-scope="props">
                           <span
                              v-if="props.column.field === 'title'"                               
                              >
                           {{ props.formattedRow[props.column.field ]}}                          
                           </span>
                           <span
                              v-if="props.column.field === 'post'"                               
                              >
                             <span v-html="props.formattedRow[props.column.field ]"></span>
                         
                           </span>
                           <span
                              v-if="props.column.field === 'scheduled_date'"
                              >
                           {{ changeTimeFormate(props.formattedRow[props.column.field]) }}
                           </span>
                           <span v-if="props.column.field === 'post_response_data'">
                              <span v-for="(responseData, index) in props.formattedRow['post_response_data']" :key="index">
                                 <span v-if="index === 'success'">
                                    <span class="badge bg-success">                               
                                    <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#openFeedClient" class="text-white" @click="openFeedClient(responseData,index)">
                                       {{ index }}  [{{ Object.keys(responseData).length }}]
                                    </a>
                                    </span>
                                 </span>                              
                                 <span v-else-if="index === 'errors'">
                                    <span class="badge bg-danger">
                                   
                                    <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#openFeedClient" class="text-white" @click="openFeedClient(responseData,index)">
                                       {{ index }}  [{{ Object.keys(responseData).length }} ]
                                    </a>
                                    </span>
                                 </span>
                              </span>                           
                              <span v-if="(props.row.scheduled_status === 'Cancel' && 
                                             !props.formattedRow['post_response_data']['success'] && 
                                             !props.formattedRow['post_response_data']['errors'])" 
                                    class="badge bg-warning">
                                 cancelled
                              </span>
                              </span>                           
                        </template>
                     </vue-good-table>
                  </div>
                  </div>
               </div>
                  
                 
               </div>
            </div>
         </div>
      </div>
      <!---- update Upcoming Scheduled Feeds-->
      <div class="modal fade" id="editScheduledFeedModal" tabindex="-1" aria-labelledby="editScheduledFeedModalLabel" aria-hidden="true" >
         <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="editScheduledFeedModalLabel">
                     <div class="d-flex flex-column"> <span>Edit Feed</span> <span class="badge bg-info small">Type: {{type }}</span></div>
                  </h5>
                  <button type="button" data-bs-toggle="modal"  class="btn btn-primary"
                     data-bs-target="#scheduledFeedModal" @click="scheduledFeeds()"> Back  to History</button>
               </div>
               <div class="modal-body">
                  <form @submit.prevent="updateScheduleFeed(getFeed.feed_id)">
                     <div class="row">
                        <!-- Title -->
                        <div class="form-group col-md-6">
                           <label for="modal-title">Title</label>
                           <input 
                              type="text" 
                              id="modal-title" 
                              v-model="getFeed.title" 
                              class="form-control" 
                              placeholder="Enter title"                    
                              >
                        </div>
                        <div class="form-group col-md-3">
                           <label for="modal-date">Select Date:</label>
                           <input 
                              type="date" 
                              id="modal-date" 
                              v-model="getFeed.date" 
                              class="form-control" 
                              placeholder="Choose Date"
                              :min="SelectedMinDate"
                              :max="SelectedMaxDate"
                              >
                        </div>
                        <div class="form-group col-md-3">
                           <label for="modal-date">Select time:</label>
                           <select class="form-select monthly_time w-auto"  @change="updateTime">
                              <option v-for="hour in feedTime" :key="hour.id" :selected="hour.value === getFeed.time">
                                 {{ hour.text }}
                              </option>
                           </select>
                        </div>
                     </div>
                     <div class="form-group" >
                        <label for="modal-description">Description:</label>
                         <quill-editor
                           v-model="getFeed.post"
                             ref="myQuillEditor"
                           :options="editorOption"
                        />             
                     </div>
                     <div class="modal-footer"><button type="submit" class="btn btn-primary"  > Update MonthlyFeed </button>         
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <!--- past Feed Client-->
      <div class="modal fade" id="openFeedClient" tabindex="-1" aria-labelledby="openFeedClientLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="openFeedClientLabel">
                     <div class="d-flex flex-column"> <span>Feed  </span> </div>
                  </h5>                  
                  <button type="button" data-bs-toggle="modal"  class="btn btn-primary"
                     data-bs-target="#scheduledFeedModal"> Back  to History</button>           
               </div>
               <div class="modal-body">
                  <div class="clients-list-grp">
                     <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(client, id) in postResponse" :key="id">
                           <div class="ms-2 me-auto">
                              <div >{{ id}} <strong>[{{ client.ClientName }}]</strong></div>
                           <div class="d-flex flex-row">
                          <span v-for="(data, dataKey) in client" :key="dataKey" class="me-3">                      
                           <span v-if="dataKey !== 'ClientName'">   
                             <i 
                             v-tooltip="{
                              content: data ? tooltipData(data, dataKey) : `${dataKey} Group`,
                              autoHide: data ? false : true,                           
                              html: true,     
                              placement:'bottom'
                                                      
                           }"                                                                                    
                           :class="`bi bi-${dataKey === 'gmb' ? 'google' : dataKey} ${dataKey === 'gmb' ? 'google' : dataKey}-icon fs-4`"></i>                                
                                              
                         </span>
                           </span>
                           </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal fade"  id="scheduledClients" tabindex="-1" aria-labelledby="scheduledClientLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl  modal-dialog-scrollable">
               <div class="modal-content">
                  <div class="modal-header">
                        <h5 class="modal-title" id="openFeedClientLabel">
                           <div class="d-flex flex-column"> <span>List of Client  </span> </div>
                        </h5>                  
                        <button type="button" data-bs-toggle="modal"  class="btn btn-primary"
                           data-bs-target="#scheduledFeedModal"> Back  to History</button>           
                  </div>
                  <div class="modal-body">
                  <div class="card-header d-flex justify-content-between align-items-center">           
                     <input type="text" class="form-control w-100 me-4" v-model="scheduledClientSearch" placeholder="Search" @input="searchScheduledClient
                     "> 
                  </div>
                     <div class="card-body mt-4">
                        <div class="clients-list-grp">
                           <ul class="list-group">
                              <li class="list-group-item d-flex justify-content-between align-items-start"  v-for="(client, index) in getScheduledClient" :key="index">
                                 <div class="ms-2 me-auto">
                                    <div>{{ client.user_id }} <strong>[{{ client.ClientName }}]</strong> </div>
                                 <div class="d-flex flex-row mt-1">
                                    <span class="badge bg-info small" v-if="client.scheduled_status =='Scheduled'">Scheduled</span>
                                    <span class="badge bg-warning small" v-if="client.scheduled_status =='Cancel'">Canceled</span>
                                 </div>
                                 </div>
                              </li>
                           </ul>                     
                        </div>
                     </div>
                  </div>        
               </div>
            </div>
      </div>
   </div>  
</template>
<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import "iview/dist/styles/iview.css";
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay"; 
import axios from 'axios'; 
export default {
    components: {   
      Loading,
      axios,
      quillEditor      
    },
    props: {
      visible: {
        type: Boolean,
        required: true
       },
      filterData: {
        type: Object,
      },
      feedItems: {
        type: Object,
      },
    },    
    data() {
      return {      
        loading: false,      
        page: 1,
        perPage: 10,
        pastData: [],
        scheduledFeed: [],
        isLoading: false,
        isCancel: false,
        isSubmitted: false,
        scheduledClientSearch: "",
        clientRow:"",    
        oldDate:"",
        oriTime: "",
        type: "",
        error: "",
        getFeed: {
         date: '',
         title:'',
         time: '',
         post: "",         
         },
         getScheduledClient:{},
         editorOption: {
            placeholder: "Type your post...",
            readOnly: true,
            theme: "snow",
            modules: {
               toolbar: [
                  ['bold', 'italic'],                  
                  ['clean']
              ],
               // toolbar: [
               //    ['bold', 'italic', 'underline', 'strike'], 
               //    ['blockquote', 'code-block'],
               //    [{ 'header': 1 }, { 'header': 2 }],           
               //    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
               //    [{ 'script': 'sub' }, { 'script': 'super' }],  
               //    [{ 'indent': '-1' }, { 'indent': '+1' }], 
               //    [{ 'direction': 'rtl' }],                        
               //    [{ 'size': ['small', false, 'large', 'huge'] }],
               //    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
               //    [{ 'color': [] }, { 'background': [] }], 
               //    [{ 'font': [] }],
               //    [{ 'align': [] }],
               //    ['clean']                                       
               // ],
            }
         },
   
        upcomingColumns: [         
          {
            label: "Title",
            field: "title",
            width: '20%',
          },
          {
            label: "Description",
            field: "post",
            html: true,
            width: '45%',
          },
          {
            label: "Scheduled Date",
            field: "scheduled_date",
            sortable: true,
            width: '15%',
          
          },
          {
            label: "Status",
            field: "scheduled_status",
            globalSearchDisabled: true,
             sortable: false,
             width: '10%',
          },
          {
            label: "Action",
            field: "actions",
            sortable: false,
            globalSearchDisabled: true,
            width: '10%',
          },
        ],
        pastColumns: [         
          {
            label: "Title",
            field: "title",
             width: '20%',
          },
          {
            label: "Description",
            field: "post",
             html: true,
            width: '55%',
          },
          {
            label: "Scheduled Date",
            field: "scheduled_date",
            sortable: true,
            width: '15%',
          },
          {
            label: "Status",
            field: "post_response_data",
            globalSearchDisabled: true,
            width: '10%',
          }
        ],
        feedTime: [
          { value: '02:00:00', text: '2:00 AM' },
          { value: '04:00:00', text: '4:00 AM' },
          { value: '06:00:00', text: '6:00 AM' },
          { value: '08:00:00', text: '8:00 AM' },
          { value: '10:00:00', text: '10:00 AM' },
          { value: '12:00:00', text: '12:00 PM' },
          { value: '14:00:00', text: '2:00 PM' },
          { value: '16:00:00', text: '4:00 PM' },
          { value: '18:00:00', text: '6:00 PM' },
          { value: '20:00:00', text: '8:00 PM' },
          { value: '22:00:00', text: '10:00 PM' },
        ],
         postResponse: {},
         upcomingFeed:[],  
         pastFeed: [],
         isLoading: true , 
         SelectedMinDate: "", 
         SelectedMaxDate: "",        
      };
    },
    computed: {
       ...mapGetters(["historyData", "editMonthlyFeedData", "CancelScheduledFeedRes"]),
        
    },
    mounted() {
      const modal = document.getElementById('scheduledFeedModal');
      modal.addEventListener('shown.bs.modal', () => {
         this.scheduledFeeds(); 
      });
      modal.addEventListener('hidden.bs.modal', () => {
         this.isLoading = true;
      });
   },  
    methods: {
      ...mapActions(["fetchMonthlyFeedHistory", "editMonthlyFeed","cancelScheduledFeeds","fetchMonthlyFeeds"]),
      scheduledFeeds() {      
        this.fetchMonthlyFeedHistory().then(() => {
           this.upcomingFeed = this.historyData.upcoming_scheduled_feeds.data;        
           this.pastFeed = this.historyData.past_scheduled_feeds.data;
           this.isLoading = false 
        });      
  
      },
      editScheduledFeed(feed_id, date) {       
         axios.get(`${process.env.VUE_APP_BASEURL}/editMonthlyFeed?feed_id=${feed_id}&scheduled_date=${date}`, {
            headers: {
               'Content-type': 'application/json',
               'token': `${localStorage.getItem('token')}`
            }
         })
           .then((response) => {                   
            this.getFeed = response.data.edit_scheduled_feeds;
            this.type = response.data.edit_scheduled_feeds.get_feed.type;
            this.oldDate = response.data.edit_scheduled_feeds.date;
            this.oriTime = response.data.edit_scheduled_feeds.time;
            const [year, months] = this.oldDate.split("-").map(Number); 
            let month = months > 9 ? months : '0' + months;
            this.SelectedMinDate = new Date(Date.now() + 86400000).toISOString().split('T')[0];
            this.SelectedMaxDate =  year + '-' + month + '-'+ new Date(year, month, 0).getDate();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
          }); 
        
      },
      updateScheduleFeed(feed_id) {
        this.loading = true;
        const payload = {
          title: this.getFeed.title,
          date: this.getFeed.date,
          time: this.getFeed.time,          
          post: this.getFeed.post,                 
          oldDate: this.oldDate,
          oriTime: this.oriTime,   
          feed_id: feed_id,
        };        
        const requestData = {
          updateFeeds: payload,
        };
        var confirmation = confirm('Are You update Monthly Feed Data ?');
        if (!confirmation) {
          return;
        }
        this.isSubmitted = true;
        try {
          axios.post(`${process.env.VUE_APP_BASEURL}/update-scheduled-monthly-feeds`, requestData, {
            headers: {
              'Content-type': 'application/json',
              'token': `${localStorage.getItem('token')}`
            }
          }).then(response => {
            if (response.data.status === true) {
              this.$toasted.show(response.data.message, {
                theme: "bubble",
                type: "success",
                position: "top-right",
                duration: 3000,
                singleton: true,
              });
            this.scheduledFeeds();
            } else {
              this.$toasted.show(response.data.error_message, {
                theme: "bubble",
                type: "error",
                position: "top-center",
                duration: 2000,
                singleton: true,
              });
            }
          }).catch(error => {
            this.$toasted.show(response.data.error_message, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2000,
              singleton: true,
            });
          });
        } finally {
          this.isSubmitted = false;
          this.loading = false;
          $("#editScheduledFeedModal").modal("hide");
          $("#scheduledFeedModal").modal("show");
        }
      },
      updateTime(event) {
        this.getFeed.time = event.target.value;
       },
      changeTimeFormate(dateTime) {       
         const date = new Date(dateTime);
         let hours = date.getHours();
         const minutes = date.getMinutes().toString().padStart(2, '0');
         const ampm = hours >= 12 ? 'PM' : 'AM';
         hours = hours % 12 || 12; 
         var sDate = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());    
            return `${date.getFullYear()}-${date.getMonth() + 1}-${sDate} ${hours}:${minutes} ${ampm}`;
      },
      cancelScheduledFeed(feed_id, scheduled_date) {
        let obj = {
          feed_id: feed_id,
          scheduled_date: scheduled_date,
        };
        const requestData = {
          cancelFeeds: obj,
        };
        var confirmation = confirm('Are you sure you want to cancel this monthly feed ?');
        if (!confirmation) {
          return;
        }
      this.isCancel = true;
      this.cancelScheduledFeeds(requestData).then(() => {      
         this.$toasted.show(this.CancelScheduledFeedRes?.message, {
          theme: "bubble",
          type: "success",
          position: "top-right",
          duration: 3000,
          singleton: true,
         });        
         this.scheduledFeeds();   
         this.fetchMonthlyFeeds(this.filterData);       
      }).catch(() => {
        this.isCancel = false;
        this.$toasted.show(`Something Went Wrong`, {
          theme: "bubble",
          type: "error",
          position: "top-right",
          duration:2000,
          singleton: true,
        });
      });        
      },
      openFeedClient(postResponse, ResponseType) {
        this.error = ResponseType;
        this.postResponse = postResponse;
       }, 
      searchScheduledClient(){
         this.openScheduledClient(this.clientRow);
       },
       openScheduledClient(row) { 
          this.clientRow = row;  
          axios.get(`${process.env.VUE_APP_BASEURL}/get-scheduled-clients?feed_id=${row.feed_id}&scheduled_date=${row.scheduled_date}&user_id=${row.user_id}&search=${this.scheduledClientSearch}`, {
            headers: {
               'Content-type': 'application/json',
               'token': `${localStorage.getItem('token')}`
            }
          })
          .then((response) => {                   
           this.getScheduledClient = response.data.scheduled_client_data;
    
         })
         .catch(() => {
         this.loading = false;
         this.error = true;
         }); 
      },     
      tooltipData(data, dataKey) {  
         const defaultText = `${dataKey} Page`;
         return this.error === 'errors' ? (data || defaultText) : (data ? this.showLink(data, dataKey) : defaultText);      
      },
      showLink(link, platform) {
        let html = ``;      
      if (platform === "twitter") {
         if (Array.isArray(link) && link.length > 1) {
            link.forEach((val, index) => {
            html += `<span>Thread ${index + 1}:</span>
               <a href="${val}" target="_blank" class="px-2 text-white">${val}</a><br>`;               
            });
         } else {
            html = `<a href="${link}" target="_blank" class="px-2 text-white">${link}</a>`;
         }
      } else {
         html = `<a href="${link}" target="_blank" class="px-2 text-white">${link}</a>`;
      }

      return html;
      }
    },
  };
</script>

<style>
   w-100 {
   width: 100% !important;
   }
   .img-fluid {
   max-width: 100%;
   height: auto;
   }
 .clients-list-grp ul {
   flex-direction: row;
   gap: 20px;
   flex-wrap: wrap;
   }
   .clients-list-grp ul li {
      border: 1px solid #dfdfdf;
      border-top-width: 1px !important;
      width:220px
   }  
   .hover-details {
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      padding: 10px;
      margin-top: 5px;
      white-space: nowrap;
      z-index: 100;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      top: 30px; /* Adjust based on your icon size */
      left: 0;   /* Adjust positioning */
}

</style>