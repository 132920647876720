import { getRequest, postRequest } from '@/helper'

let tagsModule = {
    state:{
        tags: {},
        tag: [],
        tagsDropDown:{},
    },
    getters:{
        tags: (state) => {
            return state.tags
        },
        tag: (state) => {
            return state.tag
        },
        tagsDropDown: (state) => {
            return state.tagsDropDown
        },
    },
    actions:{
        getTags: async ({ commit }, {page = 1, limit = '',field = '' ,direction = '', search = '', category_id = ''}) => {
            if(category_id == null)
            {
                category_id = ''
            }

            let resp = await getRequest(`tags?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}&category_id=${category_id}`)
            if(resp && resp.status)
            {
                commit('setTags', resp.data)
                return resp.data
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getTagsDropDown: async ({ commit }, {category_id = ''}) => {
            
            let resp = await getRequest(`tags/drop-down?category_id=${category_id}`)
            if(resp && resp.status)
            {
                commit('setTagsDropDown', resp.data)
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getUsersTagsDropDown: async ({ commit }) => {
            
            let resp = await getRequest(`users/tags/drop-down`)
            if(resp && resp.status)
            {
                commit('setTagsDropDown', resp.data)
                return resp
            }
            else
            {
                console.log(resp && resp.message ? resp.message : 'Something went wrong please try after some time')
            }
            return {}
        },
        getTag: async ({ commit }, id) => {
            let resp = await getRequest(`tags/view/${id}`)
            commit('setTag', resp.data)
            return resp
        },
        addTag: async({commit}, data) => {
            let {category_id, title, status} = data
            let resp = await postRequest(`tags/add`, {category_id, title, status})
            return resp;
        },
        updateTag: async({commit}, data) => {
            let {id, category_id, title, status} = data
            let resp = await postRequest(`tags/${id}/edit`, {category_id, title, status})
            return resp;
        },
        deleteTag: async ({ commit }, id) => {
            let resp = await getRequest(`tags/${id}/delete`)
            if(resp && resp.status)
            {
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getUserTags: async ({ commit }, {page = 1, limit = '',field = '' ,direction = '', search = ''}) => {
            let resp = await getRequest(`users/tags?page=${page}&limit=${limit}&field=${field}&direction=${direction}&search=${search}`)
            if(resp && resp.status)
            {
                return resp.data
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        },
        getUserTag: async ({ commit }, id) => {
            let resp = await getRequest(`users/tags/${id}/view`)
            commit('setTag', resp.data)
            return resp
        },
        addUserTag: async({commit}, data) => {
            let {title, status} = data
            let resp = await postRequest(`users/tags/add`, {title, status})
            return resp;
        },
        updateUserTag: async({commit}, data) => {
            let {id, category_id, title, status} = data
            let resp = await postRequest(`users/tags/${id}/update`, {category_id, title, status})
            return resp;
        },
        deleteUserTag: async ({ commit }, id) => {
            let resp = await getRequest(`users/tags/${id}/delete`)
            if(resp && resp.status)
            {
                return resp
            }
            else
            {
                console.log(resp.message)
                console.error('Data not found')
            }
        }
    },
    mutations:{
        setTags: (state, data) => {
            return state.tags = data
        },
        setTag: (state, data) => {
            return state.tag = data
        },
        setTagsDropDown: (state, data) => {
            return state.tagsDropDown = data
        }
    }
}

export default tagsModule