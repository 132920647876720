import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import helper from './helper'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

//Bootstrap icons/css
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css';


import 'vue-select/dist/vue-select.css';
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import VueGoodTablePlugin from 'vue-good-table';
import ToggleButton from 'vue-js-toggle-button'
import IdleVue from 'idle-vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
  moment,
})
const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 200000
})
Vue.use(ToggleButton)

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'
import Toasted from 'vue-toasted';

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

Vue.use(Toasted)
Vue.use(VueGoodTablePlugin);

Vue.use(VueClipboard)
Vue.use(Vuelidate)

Vue.config.productionTip = false
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)
Vue.prototype.$helper = helper
Vue.prototype.$ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

let instance = null
Vue.prototype.$notify = (msg, type = 'success', time = 2500) => {
	if(!instance)
  {
    instance  = new Vue();
  }
  
  let options = {
		type:type,
		theme:'bubble',
		duration:time,
		singleton:true,
		position:'top-center',
    className:"notification_toasted"
	}

  let notifyNode = $('body').find(".notification_toasted");
  if(notifyNode.length > 2)
  {
    $('body').find(".notification_toasted").remove()
  }
	return instance.$toasted.show(msg, options)
};

Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  },
})

new Vue({
  router,
  store,
  onIdle() {
  },
  render: h => h(App)
}).$mount('#app')

import "bootstrap/dist/js/bootstrap.js"
