<template>
  <div>
    <div class="page-content page-container" id="page-content">
      <div class="padding container">
        <div class="row d-flex justify-content-center">
          <div class="col-xl-12 col-md-12">
            <div class="card user-card-full">
              <div class="row gx-0">
                <div class="col-lg-4 bg-c-lite-green user-profile-div">
                  <loading
                    :active.sync="isLoading"
                    color="#157DEC"
                    :is-full-page="false"
                  ></loading>
                  <div class="card-block text-center text-white pt-md-4">
                    <!-- <div class="m-b-25"> -->
                    <!-- <img src="https://img.icons8.com/bubbles/100/000000/user.png" class="img-radius" alt="User-Profile-Image"> 
                             </div> -->
                    <h6 class="f-w-600 text-capitalize">
                      {{ formFields.first_name + " " + formFields.last_name }}
                    </h6>
                    <div v-if="planData">
                      <p>Subscribed plan detail</p>
                      <p>Charge: ${{ planData.amount / 100 }}/month</p>
                      <p v-if="new Date(subData.trial_end) > new Date()">
                        Free Trail End:
                        {{
                          new Date(subData.trial_end).toLocaleString("en-us", {
                            month: "long",
                            year: "numeric",
                            day: "numeric",
                          })
                        }}
                      </p>
                      <p v-else>
                        Plan End:
                        {{
                          new Date(subData.current_period_end).toLocaleString(
                            "en-us",
                            { month: "long", year: "numeric", day: "numeric" }
                          )
                        }}
                      </p>
                      <!-- <p>Monthly Copy Limit Used : {{60-userData.quota_limit}}</p> -->
                    </div>
                    <div v-else>
                      <p>Subscription Plan Status: Forever</p>
                    </div>
                    <i
                      class="mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"
                    ></i>
                    <button
                      v-if="planData"
                      type="button"
                      class="btn btn-primary"
                      @click="openReasonModal"
                    >
                      Deactivate
                    </button>
                  </div>
                </div>
                <div class="col-lg-8">
                  <form @submit.prevent="submitForm">
                    <div class="card-block position-relative">
                      <div
                        class="justify-content-between b-b-default mb-3 btn-header-top"
                      >
                        <div class="heading-left">
                          <h6 class="mt-2 pb-2 f-w-600">
                            Information
                            <span
                              class="fw-light ps-2 text-danger"
                              v-if="cancellationStatus"
                            >
                              ( Your subscription will be cancelled in
                              {{ cancel_at | moment("from", "now", true) }} )
                            </span>
                          </h6>
                        </div>
                        <div class="heading-right" v-if="bool">
                          <router-link v-if="formFields.fetch_reviews_enable" to="/business" class="btn btn-primary px-2 py-1 mx-2 ">
                            Review Settings
                          </router-link>
                          <a
                            href="javascript:;"
                            v-if="skipLogo == true || !showStep2"
                            class="btn btn-primary px-2 py-1"
                            @click="openUploadLogo"
                            v-tooltip="'Upload logo for social feeds'"
                          >
                            Upload logo
                            <i class="bi text-white bi-file-earmark-arrow-up fs-6 d-none" ></i>
                          </a>
                          <a
                          v-tooltip="'Edit Account'"
                          title="Edit Account"
                          class="edit_account_btn btn btn-primary px-2 py-1 mx-2"
                          @click="toggleField()"
                          >
                            <i class="edit_icon bi bi-pencil-square fs-6"></i>
                             <span class="edit_account_text">Edit Account</span>
                          </a>
                        </div>
                      </div>

                      <div class="row" v-if="bool">
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">First Name:</p>
                          <p class="mb-4">{{ formFields.first_name }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Last Name:</p>
                          <p class="mb-4">{{ formFields.last_name }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Email:</p>
                          <p class="mb-4">{{ formFields.email }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Phone:</p>
                          <p class="mb-4">{{ formFields.phone }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Company Name:</p>
                          <p class="mb-4">{{ formFields.company }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Website:</p>
                          <p class="mb-4">{{ formFields.website }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Country:</p>
                          <p class="mb-4">{{ formFields.country }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Time Zone:</p>
                          <p class="mb-4">{{ formFields.time_zone }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">City:</p>
                          <p class="mb-4">{{ formFields.city }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Address:</p>
                          <p class="mb-4">{{ formFields.address }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0 f-w-600">Enable Reviews:</p>
                          <p class="mb-4">
                            <span class="badge bg-success" v-if="formFields.fetch_reviews_enable">Active</span>
                            <span class="badge bg-danger" v-if="!formFields.fetch_reviews_enable">In-Active</span>
                          </p>
                        </div>
                      </div>

                      <div class="row" v-if="!bool">
                        <div class="col-sm-12 d-flex justify-content-end">
                          <a
                          class="btn btn-sm btn-primary position-absolute px-2 py-1"
                          style="top: 15px"
                          @click="toggleField()"
                          >
                          <!-- v-tooltip="'Show Profile'" -->
                            <!-- <i class="bi bi-eye"></i> -->
                            <i class="bi bi-caret-left-fill"></i>
                            Back To My Account
                          </a>
                        </div>

                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="fname">
                            First Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.formFields.first_name.$error,
                            }"
                            v-model="formFields.first_name"
                            :disabled="bool"
                            id="fname"
                            placeholder="Enter First Name"
                          />
                        </div>

                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="lname">
                            Last Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.formFields.last_name.$error,
                            }"
                            id="lname"
                            :disabled="bool"
                            v-model="formFields.last_name"
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="email">
                            Email <span class="required">*</span>
                          </label>
                          <input
                            type="email"
                            readonly
                            class="form-control"
                            id="email"
                            v-model="formFields.email"
                            :class="{
                              'is-invalid': $v.formFields.email.$error,
                            }"
                            :disabled="bool"
                            placeholder="rntng@gmail.com"
                            autocomplete="username"
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="pno">
                            Phone <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            :disabled="bool"
                            :class="{
                              'is-invalid': $v.formFields.phone.$error,
                            }"
                            v-model="formFields.phone"
                            class="form-control"
                            id="pno"
                            placeholder="Enter phone"
                          />
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="cmp_name">
                            Company Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.formFields.company.$error,
                            }"
                            id="cmp_name"
                            :disabled="bool"
                            v-model="formFields.company"
                            placeholder="Enter Company Name"
                          />
                        </div>
                        <div class="col-sm-6 mb-3" for="website">
                          <label class="mb-0 f-w-600"
                            >Website <span class="required">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                parseUrl == false ||
                                $v.formFields.website.$error,
                            }"
                            id="website"
                            :disabled="bool"
                            v-model="formFields.website"
                            placeholder="Enter website"
                          />
                          <span
                            class="text-danger"
                            style="font-size: 11px"
                            v-if="parseUrl == false"
                            >Enter a valid url</span
                          >
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="country">
                            Country <span class="required">*</span>
                          </label>
                          <v-select
                            :disabled="bool"
                            label="name"
                            id="country"
                            v-model="formFields.country"
                            placeholder="select country"
                            :options="options"
                             @input="updateTimeZones"
                            :class="{
                              'is-invalid': $v.formFields.country.$error,
                            }"
                          ></v-select>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label class="mb-0 f-w-600" for="timezone">
                              Select Time Zone <span class="required">*</span>
                            </label>
                            <v-select
                              label="name"                                           
                                      id="timezone"
                                      v-model="formFields.time_zone"
                                      :options="timeZones"         
                                          :class="{
                                          'is-invalid': $v.formFields.time_zone.$error,
                                      }"
                                      placeholder="Select a time zone"  
                            ></v-select>
                         </div>                        
                        <div class="col-sm-6 mb-3">
                          <label class="mb-0 f-w-600" for="city"> City </label>
                          <input
                            type="text"
                            :disabled="bool"
                            class="form-control"
                            id="city"
                            v-model="formFields.city"
                            placeholder="Enter City"
                            :class="{
                              'is-invalid': $v.formFields.city.$error,
                            }"
                          />
                        </div>
                        <div class="col-sm-6 mb-3 password-container">
                          <label class="mb-0 f-w-600" for="password"
                            >Password</label
                          >
                          <input
                            :disabled="bool"
                            :type="isPasswordVisible ? 'text' : 'password'"                            
                            class="form-control"
                            id="password"
                            v-model="fields.password"
                            placeholder="Enter Password"
                            autocomplete="off"
                          />
                          <span id="togglePassword" class="eye-icon" @click="togglePasswordVisibility"> 
                            <i :class="isPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'" v-tooltip="isPasswordVisible ? 'hide' : 'show'"></i>
                          </span>
                        </div>
                        <div class="col-sm-6 mb-3 password-container">
                          <label class="mb-0 f-w-600" for="cpassword"
                            >Confirm Password</label
                          >
                          <input
                            :disabled="bool"
                            :type="isCPasswordVisible ? 'text' : 'password'"
                            class="form-control"
                            id="cpassword"
                            v-model="fields.confirmPassword"
                            placeholder="Enter Password"
                            autocomplete="off"
                          />
                           <span id="togglePassword" class="confirm-eye-icon" @click="toggleCPasswordVisibility"> 
                              <i :class="isCPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'" v-tooltip="isCPasswordVisible ? 'hide' : 'show'"></i> <!-- Change icon based on visibility -->
                           </span>
                          <span
                            v-if="$v.fields.confirmPassword.$error"
                            style="color: red"
                            >Must be same as password</span
                          >
                        </div>
                        <div class="col-sm-12 mb-3">
                          <label class="mb-0 f-w-600" for="address">
                            Address
                          </label>
                          <input
                            type="text"
                            :disabled="bool"
                            class="form-control"
                            id="address"
                            v-model="formFields.address"
                            placeholder="Enter Address"
                          />
                        </div>
                        <div class="col-sm-12 mb-3">
                          <label class="form-check-label mb-0 f-w-600" for="fetch_reviews_enable">
                            Enable Reviews 
                          </label>
                          <div class="form-check form-switch">
                              <input
                                  id="fetch_reviews_enable"
                                  type="checkbox"
                                  :disabled="bool"
                                  v-model="formFields.fetch_reviews_enable"
                                  class="form-check-input"
                              />
                          </div>
                        </div>
                        <p class="text-danger" style="font-size: 11px">
                          Leave password field empty, if you don't want to
                          change
                        </p>
                        <!-- <div
                          class="col-sm-1 mt-3 f-w-600"
                          
                          v-if="!bool"
                        >
                       
                        </div> -->
                        <div class="col-lg-4 mt-3 d-flex" v-if="!bool">
                          <button
                            class="btn btn-success d-flex me-2"
                            :class="loading ? 'disabled' : ''"
                            type="submit"
                            @click="submitForm"
                          >
                            <span>Save </span>
                            <span
                              v-if="loading"
                              class="spinner-border spinner-border-sm m-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                          <button class="btn btn-secondary" @click="cancel()">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="border rounded shadow-sm p-4 text-center"
              v-show="showStep2"
            >
              <h3>Step 2:</h3>
              <p class="fw-light fs-6" v-if="skipLogo == false">
                Upload Your Logo ( We will apply your logo to social feeds )
              </p>
              <p class="fw-light fs-7" v-if="skipLogo == true">
                In future you can upload logo from profile using this
                <i
                  class="bi bg-primary p-2 rounded text-white bi-file-earmark-arrow-up fs-6"
                ></i>
                button at the top
              </p>
              <a
                href="javascript:;"
                v-if="skipLogo == false"
                class="btn btn-info rounded text-white"
                @click="openUploadLogo"
              >
                <i class="bi bi-upload pe-2"></i>
                Upload
              </a>
              <div class="py-2">
                <a
                  v-if="skipLogo == false"
                  @click.prevent="skipLogoUpload"
                  class="text-muted fs-5"
                  style="font-weight: 500"
                >
                  <i class="bi bi-chevron-double-right text-muted"></i> Skip
                  this for now and access posts
                </a>
                <router-link
                  v-if="skipLogo == true"
                  class="btn btn-info text-white fs-5"
                  style="font-weight: 500"
                  aria-current="page"
                  to="/dashboard"
                  >Access posts on your dashboard
                </router-link>
                <div class="py-2">
                  <a
                    v-if="skipLogo == true"
                    @click.prevent="backToLogoUpload"
                    class="text-muted fs-5"
                    style="font-weight: 500"
                  >
                    <i class="bi bi-chevron-double-right text-muted"></i> Back
                    to logo upload
                  </a>
                </div>
              </div>
            </div>
            <div v-show="!showStep2" class="text-center">
              <router-link
                class="text-muted mx-auto fs-5"
                style="font-weight: 500"
                aria-current="page"
                to="/dashboard"
              >
                <i class="bi bi-chevron-double-right"></i> Access posts on your
                dashboard
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Deactivate Subscription
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-danger">{{ mailResponse }}</p>
            <div class="col-sm-12">
              <label class="f-w-600" for="reason">
                Reason <span class="required">*</span>
              </label>
              <textarea
                type="text"
                style="height: 115px"
                class="form-control"
                id="reason"
                v-model="dForm.reason"
                placeholder="Enter reason"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" @click="sendEmail" class="btn btn-primary">
              Send
              <span
                v-if="isSendMail"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      id="Image-upload"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header py-3 w-100">
            <h3 class="modal-title w-100 text-center">Select your logo</h3>
          </div>
          <div class="progress" v-if="selectedFile">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              v-bind:style="{ width: length }"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="modal-body position-relative">
            <img
              v-tooltip="'uploaded logo'"
              v-if="customLogo"
              :src="showUploadedLogo(customLogo)"
              class="popup-logo position-absolute shadow has-tooltip p-2 border-2" :style="{ 'border-color': colour }"
              alt="logo"
            />
            <form>
              <div class="row justify-content-center pt-3">
                <label
                  class="hoverable profile-input p-0 m-0"
                  style="width: 31%; height: 31%"
                  for="fileInput"
                >
                  <img
                    v-if="imgSrc"
                    :src="imgSrc"
                    class="img-fluid rounded-circle selected-logo w-100 h-100"
                  />
                  <img
                    v-if="!imgSrc"
                    src="../../assets/old_logo.png"
                    class="img-fluid rounded-circle w-100 h-100 object-fit-cover"
                  />
                  <div class="hover-text">Choose file</div>
                  <div class="background"></div>
                </label>
                <br />

                <input
                  id="fileInput"
                  type="file"
                  @change="readURL($event)"
                  accept="image/*"
                />
                <div class="mt-4 mb-2">
					<p class="text-danger mx-auto m-0 mb-3" style="width: fit-content; font-size: 12px">
						The image format should be .png, .jpg, or .jpeg,<br/> with a recommended size of 250x250 pixels.
					</p>
                  <p
                    class="text-dark mx-auto border-bottom border-2 border-dark m-0 h3"
                    style="width: fit-content; font-size: 12px"
                  >
                    Background Color Behind Logo (Optional)
                  </p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center pt-3 flex-wrap"
                >
                  <label for="head" class=""
                    ><strong>Pick Color</strong> :&nbsp;&nbsp;
                  </label>
                  <input
                    type="color"
                    id="head"
                    name="head"
                    @change="changecoloroption()"
                    v-model="colour"                    
                    class="form-control form-control-color p-0 border-dark"
                  />
                </div>
                <small class="text-center py-2"
                  >The background color will only be applied if the logo is configured to appear below the feed image <a :href="dummyImage" target="_blank">( like this )</a>. This setting is determined automatically based on the feed's configuration.</small
                >
              </div>
              <div class="card-footer col-12 border-0 mt-3 text-center py-2">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm me-3"
                  data-bs-dismiss="modal"
                  :class="isLoad ? 'disabled' : ''"
                  @click="cancelImageUpload()"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  :class="isLoad ? 'disabled' : ''"
                  class="btn btn-success btn-sm"                  
                  @click="uploadLogo()"
                >
                  Save & Upload
                  <span
                    v-if="isLoad"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { countryTimeZones } from './../admin//timezoneData';
import {
  required,
  email,
  url,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Loading from "vue-loading-overlay";

export default {
  name: "MyProfile",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      options: [
        { name: "Canada", code: "CA" },
        { name: "Australia", code: "AU" },
        { name: "United States", code: "US" },
        { name: "United Kingdom", code: "UK" },
        { name: "Other", code: "other" },
      ],
      parseUrl: true,
      showStep2: false,
      bool: true,
      customLogo: "",
      mailResponse: "",
      isLoading: false,
      isSendMail: false,
      isReasonSubmitted: false,
      subData: {},
      cancellationStatus: false,
      cancel_at: "",
      planData: {},
      loading: false,
      imgSrc: "",
      selectedFile: "",
      length: "",
      isLoad: false,
      colour: "#ffffff",
      servercolour: "",
      colourchanged: 0,
      userData: [],
      skipLogo: false,
      isPasswordVisible: false,
      isCPasswordVisible: false,
      dForm: {
        reason: "",
      },
      formFields: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        website: "",
        address: "",
        country: "",
        time_zone:'',
        city: "",
        fetch_reviews_enable:''
      },
      fields: {
        password: "",
        confirmPassword: "",
      },
      timeZones: [],
      dummyImage:''
    };
  },
  computed: { ...mapGetters(["userList"]) },
  created() {
    this.dummyImage = process.env.VUE_APP_DUMMY_LOGO_SET_IMAGE;
    this.isLoading = true;
    let id = localStorage.getItem("id");
    //console.log(id)
    let st = localStorage.getItem("pay_status");
    if (st == 0) {
      this.$router.push("/payments/due");
    }
    this.fetchUser(id)
      .then(() => {
        this.formFields = this.userList?.user_data;
        this.timeZones = countryTimeZones[this.formFields.country];  
        if (this.userList?.user_data?.plan_name == "Cancelation Scheduled") {
          this.cancellationStatus = true;
          this.cancel_at = this.userList?.user_data?.cancel_at;
        }
        this.userData = this.userList?.user_data;
        this.subData = this.userList?.subscription_data;        
        this.planData = this.userList?.subscription_data?.plan_data;
        this.bool = true;
        this.loading = false;
        this.dForm.email = this.userList?.user_data?.email;
        this.colour =  this.userList?.user_data?.color_code;
        this.servercolour = this.userList?.user_data?.color_code;
        this.customLogo = this.userList?.user_data?.custom_logo;
        if (this.customLogo == "") {
          this.showStep2 = true;
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  validations: {
    formFields: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required },
      company: { required },
      website: { required },
      address: "",
      country: { required },
      time_zone: { required },
      city: { required },
    },
    fields: {
      password: { minLength: 6 },
      confirmPassword: {
        minLength: 6,
        sameAsPassword: sameAs(function () {
          return this.fields.password;
        }),
      },
    },
  },

  methods: {
    ...mapActions(["fetchUser", "updateUser"]),
    openReasonModal() {
      this.mailResponse = "";
      $("#exampleModal").modal("show");
    },
    updateTimeZones() {             
      this.timeZones = countryTimeZones[this.formFields.country.name];      
      this.time_zone = ""; 
    },
    sendEmail() {
      this.isSendMail = true;
      this.isReasonSubmitted = true;
      let obj = {
        body: this.dForm.reason,
      };
      if (this.dForm.reason) {
        axios
          .post(process.env.VUE_APP_BASEURL + "/accountDeactivateMail", obj, {
            headers: {
              "Content-type": "application/json",
              token: `${localStorage.getItem("token")}`,
            },
          })
          .then(() => {
            // $('#exampleModal').modal('hide')
            this.dForm.reason = "";
            this.mailResponse = "Your request has been sent to admin";

            // this.$toasted.show(
            //   `Deactivate request send to admin`,
            //   {
            //     theme: "bubble",
            //     type: "info",
            //     position: "top-center",
            //     duration: 1500,
            //     singleton: true,
            //   }
            // );
            this.isSendMail = false;
            this.isReasonSubmitted = false;
          })
          .catch((err) => {
            this.isSendMail = false;
            this.isReasonSubmitted = false;
            if (err.message == "Request failed with status code 401") {
              localStorage.removeItem("token");
              this.$router.push("/login");
            } else {
              this.$toasted.show(`${err.message}`, {
                theme: "bubble",
                type: "error",
                position: "top-center",
                duration: 1500,
                singleton: true,
              });
            }
          });
      } else {
        this.isSendMail = false;
        //  console.log(this.isSendMail)
      }
    },
    parseLink(href) {
      var res = href.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    editUser() {
      if (this.formFields.website != "") {
        this.parseUrl = this.parseLink(this.formFields.website);
        
        if (this.parseUrl == false) {
          return false;
        }
      }
      this.loading = true;      
      var temp = {
        first_name: this.formFields.first_name,
        last_name: this.formFields.last_name,
        email: this.formFields.email,
        company: this.formFields.company,
        phone: this.formFields.phone,
        confirmPassword: this.fields.confirmPassword,
        password: this.fields.password,
        country: this.formFields.country.name
          ? this.formFields.country.name
          : this.formFields.country,
        time_zone: this.formFields.time_zone,
        city: this.formFields.city,
        address: this.formFields.address,
        website: this.formFields.website,
        fetch_reviews_enable:this.formFields.fetch_reviews_enable
      };
      this.updateUser(temp)
        .then(() => {
          this.formFields = this.userList?.user_data;
          this.subData = this.userList?.subscription_data;
          this.planData = this.userList?.subscription_data?.plan_data;
          this.bool = true;
          this.loading = false;
          this.dForm.email = this.userList?.user_data?.email;
          $(".profile_name span").text(this.formFields.first_name);
          localStorage.setItem("name", this.formFields.first_name);
          this.$toasted.show(`Profile updated`, {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 4000,
            singleton: true,
          });
        })
        .catch(() => {
          this.loading = false;
          this.$toasted.show(`Something went wrong,plz try again later`, {
            theme: "bubble",
            type: "Error",
            position: "top-center",
            duration: 1500,
            singleton: true,
          });
        });
    },
    submitForm(e) {
      e.preventDefault();

      this.$v.$touch();
      // console.log(this.formFields);
      if (!this.$v.$invalid) {
        this.editUser();
        //alert("form submitted Successfully");
      } else {
        this.loading = false;
        // alert("form submission failed");
      }
    },
    toggleField() {
      let id = localStorage.getItem("id");
      this.bool = !this.bool;
      this.fetchUser(id).then(() => {
        this.formFields = this.userList.user_data;
        this.subData = this.userList?.subscription_data;
        this.planData = this.userList?.subscription_data?.plan_data;
        this.dForm.email = this.userList?.user_data?.emailil;
      });
    },
    cancel() {
      this.bool = true;
      let id = localStorage.getItem("id");
      this.fetchUser(id).then(() => {
        this.formFields = this.userList.user_data;
        this.subData = this.userList?.subscription_data;
        this.dForm.email = this.userList?.user_data?.email;
        this.planData = this.userList?.subscription_data?.plan_data;
      });
    },
    openUploadLogo() {
      $(`#fileInput`).val("");
      $("#Image-upload").modal("show");
    },
    readURL(event) {
      	if(event.target.files && event.target.files[0])
	  	{
        	this.selectedFile = event.target.files[0];

			if(this.selectedFile.type == 'image/png' || this.selectedFile.type == 'image/jpg' || this.selectedFile.type == 'image/jpeg')
			{
				var reader 	= new FileReader();
				reader.onload = (event) => {
					this.imgSrc = event.target.result;
				};
				reader.readAsDataURL(event.target.files[0]);
			}
			else
			{
				this.$toasted.show("The image format should be .png, .jpg, or .jpeg.", {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 2500,
					singleton: true
				});
			}
		}
    },
    changeoloroption(){
      this.colourchanged = 1;
    },
    changecoloroption(){
      console.log('ewsfwe')
      this.colourchanged = 1;
    },
    cancelImageUpload() {      
      $("#fileInput").val("");
      this.selectedFile = "";
      this.imgSrc = "";

      if ( this.servercolour != '' && this.colour != this.servercolour ){
          this.colour = this.servercolour;
      }      
    },
    uploadLogo() {              
      if ( this.colourchanged == 0 && this.selectedFile == ""){
        let uploadmsg = "Logo is required";
        if (this.customLogo != "") {
           uploadmsg = "Either upload logo or change color before save";
        }
        this.$toasted.show(uploadmsg, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
        return;
      }
      this.length = "25%";
      this.isLoad = true;
      let data = new FormData();
      data.append("file", this.selectedFile);
      data.append("apply_to", true);
      data.append("color_code", this.colour);

      axios
        .post(process.env.VUE_APP_BASEURL + "/upload-user-logo", data, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.length = "50%";
          }, 1000);
          setTimeout(() => {
            this.length = "75%";
          }, 2000);
          setTimeout(() => {
            this.length = "100%";
            this.customLogo = res?.data?.logo;
          }, 3000);
          this.refresh++;
          //console.log( this.refresh)
          setTimeout(() => {
            this.showStep2 = false;
            this.skipLogo = false;
            this.isLoad = false;
            this.length = "0%";            
            this.imgSrc = "";
            let msg = 'Logo uploaded successfully';
            if ( this.selectedFile == "" ){
                msg = 'Color updated successfully';
            }
            this.selectedFile = "";
            this.$toasted.show(msg, {
              theme: "bubble",
              type: "success",
              position: "top-center",
              duration: 1500,
              singleton: true,
            });
          }, 3200);
          setTimeout(() => {
            $("#Image-upload").modal("hide");
            $("#fileInput").val("");
            this.servercolour = '';
            //$("#head").val("#ffffff");
            //this.colour = "#ffffff";
          }, 3600);
        })
        .catch((err) => {
          //console.log('errr',err)
          this.length = "0%";
          this.isLoad = false;
          this.selectedFile = "";
          if (err?.response?.data?.status == false) {
            this.$toasted.show(`${err?.response?.data?.error_message}`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
          } else {
            this.$toasted.show(`Internal Server Error`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
          }
        });
    },
    showUploadedLogo(logo) {
      return (
        process.env.VUE_APP_BASEUPLOADURL +
        logo +
        "?" +
        Math.floor(Math.random() * 10000000)
      );
    },
    skipLogoUpload() {
      this.skipLogo = true;
    },
    backToLogoUpload() {
      this.skipLogo = false;
    },
    togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible; // Toggle visibility
    },
    toggleCPasswordVisibility() {
        this.isCPasswordVisible = !this.isCPasswordVisible; // Toggle visibility
    },
  },
};
</script>

<style>
body {
  background-color: #f9f9fa;
}

.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: visible !important;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

@media (min-width: 992px) {
  .user-card-full .user-profile-div {
    border-radius: 5px 0 0 5px;
  }
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  );
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.required {
  color: red;
}
.popup-logo {
  max-height: 120px;
  image-rendering: -webkit-optimize-contrast;
  max-width: 100px;
  height: auto;
  width: auto;
  top: 6px;
  border: solid;
}
.selected-logo {
  object-fit: contain;
  max-width: 100% !important;
  max-height: 149px;
  min-width: 149px;
  min-height: 149px;
}
.password-container {
    position: relative;
    display: inline-block;
}
.password-container input {
    padding-right: 30px; /* Add space for the eye icon */
}
.eye-icon {
    position: absolute;
    right: 15px; /* Position the icon to the right */
    top: 70%;
    transform: translateY(-50%); /* Center vertically */
    cursor: pointer; /* Change cursor to pointer on hover */
}
.confirm-eye-icon {
    position: absolute;
    right: 15px; 
    top: 70%;
    transform: translateY(-50%); 
    cursor: pointer; 
}

.btn-header-top{
  display: flex;
}

.edit_account_btn .edit_icon {
  display: none;
}

.edit_account_btn .edit_account_text {
  display: block;
}

@media screen and (max-width:540px) {
  .btn-header-top{
    display: grid !important;
  }

  .edit_account_btn .edit_icon {
    display: block;
  }
  
  .edit_account_btn .edit_account_text {
    display: none;
  }
  
}
</style>
