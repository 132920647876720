
import business from '../../components/user/addBusiness/index.vue'

let businessRoutes = [
    {
        path: "/business",
        name: 'Business',
        component: business,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
];

export default businessRoutes