<template>
    <div class="hello m-5 reviews-admin-listing">
        <div class="d-flex justify-content-between">
            <h4 class="m-1">Reviews</h4>
            <div class="d-flex justify-content-end">
                <a
                    href="javascript:;"
                    class="text-white btn btn-primary m-2 d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#addModal"
                    @click="resetFormData"
                >
                    Add
                </a>
                <div class="m-3" v-if="filter.isRecordFiltered">
                    <small>
                        Displaying records filtered by the following fields:- (
                        <span
                            v-for="(item, index) in this.filter.filteredFields"
                        >
                            <span v-if="index > 0">,</span>
                            <span> {{ item.label }} - {{ item.value }} </span>
                        </span>
                        )
                    </small>
                </div>
                <div>
                    <a
                        :class="[
                            autoTagAsignStatus
                                ? 'pointer-disabled btn-info'
                                : 'btn-primary pointer-cursor',
                        ]"
                        v-tooltip="
                            autoTagAsignStatus
                                ? 'Assigning Already in Process'
                                : 'Auto Assign Tags, Smart Tags to reviews'
                        "
                        @click="
                            () => {
                                if (!autoTagAsignStatus) {
                                    autoAsignTagsAll()
                                }
                            }
                        "
                        style="text-decoration: none"
                        class="text-white btn m-2 flex-start-end"
                        aria-current="page"
                    >
                        Auto Assign Tags
                    </a>
                    <a
                        :class="[
                            reviewMoveStatus
                                ? 'pointer-disabled btn-info'
                                : 'btn-primary pointer-cursor',
                        ]"
                        v-tooltip="
                            reviewMoveStatus
                                ? 'Already in Process'
                                : 'Move Reviews To Feeds'
                        "
                        @click="
                            () => {
                                if (!reviewMoveStatus) {
                                    moveReviewsStart()
                                }
                            }
                        "
                        style="text-decoration: none"
                        class="text-white btn m-2 flex-start-end"
                        aria-current="page"
                    >
                        Convert Reviews To Feeds
                    </a>
                    <a
                        :class="[
                            reviewFetchStatus
                                ? 'pointer-disabled btn-info'
                                : 'btn-primary pointer-cursor',
                        ]"
                        v-tooltip="
                            reviewFetchStatus
                                ? 'Sync Already in Process'
                                : 'Sync Reviews'
                        "
                        @click="
                            () => {
                                if (!reviewFetchStatus) {
                                    fetchReviewsStart()
                                }
                            }
                        "
                        style="text-decoration: none"
                        class="text-white btn m-2 flex-start-end"
                        aria-current="page"
                    >
                        Sync Reviews
                    </a>
                </div>
                <div class="dropdown">
                    <button
                        class="btn btn-primary m-2 dropdown-toggle position-relative"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="false"
                        aria-expanded="false"
                        data-bs-reference="#filter_submit"
                    >
                        Filter
                        <div
                            v-if="filter.isRecordFiltered"
                            class="bg-danger rounded-circle"
                        ></div>
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton2"
                        style="
                            width: 300px;
                            background: linear-gradient(#f4f5f8, #f1f3f6);
                        "
                    >
                        <div class="row m-1 position-relative">
                            <i
                                class="bi bi-x-lg position-absolute"
                                @click="closeFilter"
                                style="top: -6px; left: 89%"
                            ></i>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label for="users" class="form-label"
                                        >Users</label
                                    >
                                    <v-select
                                        id="users"
                                        label="name"
                                        v-model="filter.user_id"
                                        placeholder="Select User"
                                        :options="users"
                                        :reduce="(users) => users.id"
                                    >
                                    </v-select>
                                </div>
                                <div class="mb-3">
                                    <label for="tags" class="form-label"
                                        >Tags</label
                                    >
                                    <v-select
                                        id="tags"
                                        label="title"
                                        v-model="filter.tag_id"
                                        placeholder="Select Tag"
                                        :options="tags"
                                        :reduce="(tags) => tags.id"
                                        @input="onTagsDropDownChange"
                                    >
                                    </v-select>
                                </div>
                                <div class="mb-3">
                                    <label for="smartTags" class="form-label"
                                        >Smart Tags</label
                                    >
                                    <v-select
                                        id="smartTags"
                                        label="title"
                                        v-model="filter.smart_tag_id"
                                        placeholder="Select Smart Tag"
                                        :options="smartTags"
                                        :reduce="(smartTags) => smartTags.id"
                                    >
                                    </v-select>
                                </div>
                                <div class="mb-3">
                                    <label for="platform" class="form-label"
                                        >Platform</label
                                    >
                                    <v-select
                                        id="platform"
                                        v-model="filter.platform"
                                        placeholder="Select Platform"
                                        :options="platforms"
                                    >
                                    </v-select>
                                </div>
                                <div class="mb-3">
                                    <label for="rating" class="form-label"
                                        >Rating</label
                                    >
                                    <v-select
                                        id="rating"
                                        label="title"
                                        v-model="filter.rating"
                                        placeholder="Select Rating"
                                        :options="ratings"
                                        :reduce="(ratings) => ratings.id"
                                    >
                                    </v-select>
                                </div>
                                <div class="mb-3">
                                    <label for="status" class="form-label"
                                        >Status</label
                                    >
                                    <v-select
                                        id="status"
                                        label="title"
                                        v-model="filter.status"
                                        placeholder="Select Status"
                                        :options="status"
                                        :reduce="(status) => status.id"
                                    >
                                    </v-select>
                                </div>
                                <div class="mb-3">
                                    <label for="status" class="form-label"
                                        >Short Description</label
                                    >
                                    <br />
                                    <div class="form-check form-check-inline">
                                        <input
                                            value=""
                                            id="all"
                                            type="radio"
                                            class="form-check-input"
                                            name="short_description"
                                            @click="
                                                () => {
                                                    filter.short_description =
                                                        ''
                                                }
                                            "
                                            :checked="
                                                filter.short_description == ''
                                                    ? true
                                                    : false
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="all"
                                            >All</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            id="empty"
                                            value="empty"
                                            type="radio"
                                            class="form-check-input"
                                            name="short_description"
                                            @click="
                                                () => {
                                                    filter.short_description =
                                                        'empty'
                                                }
                                            "
                                            :checked="
                                                filter.short_description ==
                                                'empty'
                                                    ? true
                                                    : false
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="empty"
                                            >Empty</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            id="filled"
                                            value="filled"
                                            type="radio"
                                            name="short_description"
                                            class="form-check-input"
                                            @click="
                                                () => {
                                                    filter.short_description =
                                                        'filled'
                                                }
                                            "
                                            :checked="
                                                filter.short_description ==
                                                'filled'
                                                    ? true
                                                    : false
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="filled"
                                            >Filled</label
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        @click="resetFilter"
                                        class="btn btn-secondary m-1"
                                    >
                                        Reset
                                    </button>
                                    <button
                                        type="submit"
                                        @click="filterData"
                                        id="filter_submit"
                                        class="btn btn-primary m-1"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end m-2">
            <div class="w-50">
                <div class="d-flex justify-content-end">
                    <div style="width: 70%">
                        <div class="d-flex">
                            <v-select
                                class="m-1"
                                style="width: 50%"
                                id="fields"
                                v-model="sort.field"
                                placeholder="Select Field"
                                :options="sortFields"
                                :reduce="(sortFields) => sortFields.field"
                            >
                            </v-select>
                            <v-select
                                class="m-1"
                                style="width: 50%"
                                id="sortType"
                                v-model="sort.type"
                                placeholder="Select Type"
                                :options="['ASC', 'DESC']"
                            ></v-select>
                            <button
                                class="m-1 btn btn-primary"
                                @click="sortData"
                            >
                                Sort
                            </button>
                            <button
                                v-if="sort.field || sort.type"
                                class="m-1 btn btn-info text-white"
                                @click="resetSort"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-1 me-2">
            <p>
                Records {{ listingCount.from }} to {{ listingCount.to }} out of {{ listingCount.total }}
            </p>
        </div>
        <vue-good-table
            mode="remote"
            @on-search="onSearch"
            @on-sort-change="onSortChange"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :rows="listing"
            :columns="columns"
            :isLoading="isLoading"
            :totalRows="totalRows"
            :sort-options="{
                enabled: true
            }"
            :search-options="{
                enabled: true,
                placeholder: 'Search',
            }"
            :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: [10, 20, 30, 40, 50],
                perPage: 10,
                mode: 'pages',
            }"
        >
            <template slot="table-row" slot-scope="props">
                {{
                    props.column.field == 'short_description'
                        ? props.formattedRow[props.column.field]
                            ? props.formattedRow[props.column.field].substring(
                                  0,
                                  100
                              ) + '...'
                            : ''
                        : ''
                }}
                <template v-if="props.column.field == 'text'">
                    <div class="mb-3">
                        <!-- <textarea class="form-control" name="" id="" rows="8" v-model="props.formattedRow.text"></textarea> -->
                        <div class="wrapper">
                            <div
                                v-html="props.formattedRow.highlight_text"
                                class="highlighted-text"
                            ></div>
                            <div class="d-flex justify-content-end">
                                <small>
                                    {{ props.formattedRow.text ? props.formattedRow.text.length : 0 }}/{{ maxReviewDescription }}
                                </small>
                            </div>
                        </div>
                        <template v-if="props.formattedRow.text && props.formattedRow.text.length > 150" >
                            <label class="form-label mt-4">Short Description</label>
                            <textarea
                                @input="
                                    (e) => {
                                        updateCount(props.formattedRow.id, e.target.value)
                                    }
                                "
                                class="form-control"
                                :class="[(shortDescriptionTextCount[props.formattedRow.id] >= 0 ? shortDescriptionTextCount[props.formattedRow.id] : formFields.short_description[props.formattedRow.id].length) > maxReviewShortDescription ? 'is-invalid' : '',]"
                                rows="4"
                                v-model="
                                    formFields.short_description[
                                        props.formattedRow.id
                                    ]
                                "
                            ></textarea>
                            <div class="d-flex justify-content-end">
                                <small
                                    >{{
                                        shortDescriptionTextCount[
                                            props.formattedRow.id
                                        ] >= 0
                                            ? shortDescriptionTextCount[
                                                  props.formattedRow.id
                                              ]
                                            : formFields.short_description[
                                                  props.formattedRow.id
                                              ].length
                                    }}/{{ maxReviewShortDescription }}</small
                                >
                            </div>
                            <button
                                v-tooltip="'Update the short description'"
                                class="btn btn-sm btn-primary mt-2"
                                :disabled="
                                    shortDescriptionLoading ==
                                    props.formattedRow.id
                                        ? true
                                        : false
                                "
                                @click="
                                    updateShortDescription(
                                        props.formattedRow.id
                                    )
                                "
                            >
                                Update
                                <Loading
                                    v-if="
                                        shortDescriptionLoading ==
                                        props.formattedRow.id
                                            ? true
                                            : false
                                    "
                                />
                            </button>
                            <button
                                v-tooltip="
                                    formFields.short_description[
                                        props.formattedRow.id
                                    ]
                                        ? 'Re-Generate short description'
                                        : 'Generate short description'
                                "
                                class="btn btn-sm btn-primary mt-2 ms-2"
                                :disabled="
                                    fetchShortDescriptionLoading ? true : false
                                "
                                @click="
                                    getReviewDescription(props.formattedRow.id)
                                "
                            >
                                {{
                                    formFields.short_description[
                                        props.formattedRow.id
                                    ]
                                        ? 'Re-Generate short description'
                                        : 'Generate short description'
                                }}
                                <Loading
                                    v-if="
                                        fetchShortDescriptionLoading ==
                                        props.formattedRow.id
                                            ? true
                                            : false
                                    "
                                />
                            </button>
                        </template>
                    </div>
                </template>
                <template v-if="props.column.field == 'tags'">
                    <ul>
                        <template v-for="tag in tags">
                            <li v-tooltip="`${smartTagsStrs[tag.id]}`">
                                <input
                                    class="form-check-input m-1"
                                    type="checkbox"
                                    dataType="tags"
                                    :dataIndex="props.index"
                                    :dataParentId="props.formattedRow.id"
                                    :dataId="tag.id"
                                    :value="tag.title"
                                    :checked="
                                        checkedTagsIds[props.formattedRow.id] &&
                                        checkedTagsIds[props.formattedRow.id]
                                            .tags
                                            ? checkedTagsIds[
                                                  props.formattedRow.id
                                              ].tags.includes(tag.id)
                                                ? true
                                                : false
                                            : false
                                    "
                                    :id="`${props.formattedRow.id}-${tag.id}-${tag.title}`"
                                    @change="onChangeTag"
                                />
                                <label
                                    class="form-check-label m-1"
                                    :for="`${props.formattedRow.id}-${tag.id}-${tag.title}`"
                                >
                                    {{ tag.title }}
                                </label>
                            </li>
                        </template>
                        <li class="d-none" v-for="tag in smartTags">
                            <input
                                class="form-check-input m-1"
                                type="checkbox"
                                dataType="smartTags"
                                :dataIndex="props.index"
                                :dataParentId="props.formattedRow.id"
                                :dataId="tag.id"
                                :value="tag.title"
                                :checked="
                                    checkedTagsIds[props.formattedRow.id] &&
                                    checkedTagsIds[props.formattedRow.id]
                                        .smartTags
                                        ? checkedTagsIds[
                                              props.formattedRow.id
                                          ].smartTags.includes(tag.id)
                                            ? true
                                            : false
                                        : false
                                "
                                :id="`${props.formattedRow.id}-${tag.id}-${tag.title}`"
                                @change="onChangeTag"
                            />
                            <label
                                class="form-check-label m-1"
                                :for="`${props.formattedRow.id}-${tag.id}-${tag.title}`"
                            >
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </template>
                <template v-if="props.column.field == 'author'">
                    <div class="d-flex justify-content-start">
                        <!-- Avatar Section -->
                        <img :src="props.formattedRow.avatar ? props.formattedRow.avatar : '/img/user2.8a8f01f3.png'"
                            style=" border-radius: 50%; width: 40px; height: 40px;"
                            class="img-thumbnail"
                            alt="avatar"
                        />

                        <!-- User Info Section -->
                        <div style="margin-left: 10px">
                            <div style="font-weight: bold">
                                {{ props.formattedRow.author }}
                            </div>

                            <!-- Star Rating Section -->
                            <div>
                                <span v-for="n in 5" :key="n" class="star">
                                    {{
                                        n <= props.formattedRow.rating
                                            ? '★'
                                            : '☆'
                                    }}
                                </span>
                                <!-- Platform Logo Section -->
                                <img
                                    v-if="
                                        props.formattedRow.platform.toLowerCase() ===
                                        'yelp'
                                    "
                                    src="../../../assets/yelp.png"
                                    v-tooltip="'Yelp'"
                                    alt="Yelp"
                                    style="width: 20px; margin-left: 5px"
                                />
                                <img
                                    v-if="
                                        props.formattedRow.platform.toLowerCase() ===
                                        'google'
                                    "
                                    src="../../../assets/google.png"
                                    v-tooltip="'Google'"
                                    alt="Google"
                                    style="width: 20px; margin-left: 5px"
                                />
                                <img
                                    v-if="
                                        props.formattedRow.platform.toLowerCase() ===
                                        'facebook'
                                    "
                                    src="../../../assets/facebook.png"
                                    v-tooltip="'Facebook'"
                                    alt="Facebook"
                                    style="width: 20px; margin-left: 5px"
                                />
                            </div>
                        </div>
                        <div class="" style="margin-left: 30px; cursor:pointer">
                            <img v-if="props.row.feed && props.row.feed.media && JSON.parse(props.row.feed.media).url" :src="props.row.feed && props.row.feed.media && JSON.parse(props.row.feed.media).url ? JSON.parse(props.row.feed.media).url : ''"
                                style=" border-radius: 5%; width: 70px; height: 70px;"
                                class="img-thumbnail"
                                @click="showImgInPopup(JSON.parse(props.row.feed.media).url)"
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-4">
                        <span
                            v-if="props.formattedRow.status == 0"
                            class="badge bg-warning"
                            >Pending</span
                        >
                        <span
                            v-if="props.formattedRow.status == 1"
                            class="badge bg-success"
                            >Approved</span
                        >
                        <span
                            v-if="props.formattedRow.status == 2"
                            class="badge bg-danger"
                            >Declined</span
                        >
                        <span
                            v-if="!props.row.review_id"
                            class="badge bg-primary"
                            >Custom</span
                        >
                    </div>
                    <hr />
                    <div class="d-flex-inline justify-content-between mt-4">
                        <h6 class="m-1 w-100">
                            User Id: {{ props.row.user.id }}
                        </h6>
                        <h6 class="m-1 w-100">
                            User Name: {{ props.row.user.first_name }}
                            {{ props.row.user.last_name }}
                        </h6>
                    </div>
                </template>
                <template v-if="props.column.field == 'user'">
                    {{
                        `${props.formattedRow.user.first_name} ${props.formattedRow.user.last_name}`
                    }}
                    <br />
                    {{ props.formattedRow.user.email }}
                </template>
                <template v-if="props.column.field == 'status'">
                    <div class="form-check form-switch">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="props.formattedRow.status"
                            @change="
                                (e) => onStatusChange(e, props.formattedRow)
                            "
                        />
                    </div>
                </template>
                <template v-if="props.column.field == 'actions'">
                    <div class="d-flex justify-content-center">
                        <div class="btn-group-vertical">
                            <template v-if="props.formattedRow.status == 0">
                                <button
                                    v-tooltip="'Approved the Review'"
                                    class="btn btn-sm btn-primary m-1"
                                    @click="
                                        updateStatusReview(
                                            props.formattedRow.id,
                                            1,
                                            props.index
                                        )
                                    "
                                >
                                    {{
                                        props.formattedRow.status == 1
                                            ? 'Approved'
                                            : 'Approve'
                                    }}
                                    <i class="bi bi-check-lg"></i>
                                </button>
                                <button
                                    v-tooltip="'Declined the Review'"
                                    class="btn btn-sm btn-danger m-1"
                                    :data-id="props.formattedRow.id"
                                    @click="
                                        updateStatusReview(
                                            props.formattedRow.id,
                                            2,
                                            props.index
                                        )
                                    "
                                >
                                    {{
                                        props.formattedRow.status == 2
                                            ? 'Declined'
                                            : 'Decline'
                                    }}
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </template>
                            <vSelect
                                v-else
                                class="w-100 m-1"
                                v-model="props.formattedRow.status"
                                :reduce="(options) => options.value"
                                :clearable="false"
                                :options="[
                                    {
                                        label:
                                            props.formattedRow.status == 1
                                                ? 'Approved'
                                                : 'Approve',
                                        value: 1,
                                    },
                                    {
                                        label:
                                            props.formattedRow.status == 2
                                                ? 'Declined'
                                                : 'Decline',
                                        value: 2,
                                    },
                                ]"
                                @input="
                                    (value) => {
                                        updateStatusReview(
                                            props.formattedRow.id,
                                            value,
                                            props.index
                                        )
                                    }
                                "
                            />
                            <button
                                v-tooltip="
                                    'Auto Assign the relavent tags and smart tags for this review '
                                "
                                class="btn btn-sm btn-info m-1 text-white"
                                @click="
                                    autoAsignTagsToReviews(
                                        props.formattedRow.id,
                                        props.index
                                    )
                                "
                            >
                                Auto Assign Tags
                                <i class="bi bi-arrow-clockwise d-none"></i>
                            </button>
                            <button
                                v-if="props.formattedRow.status == 1 &&  props.row.is_use_for_feed == 1 && !recentUpdatedFeedStatusReviewsIds.includes(props.row.id)"
                                v-tooltip="'Convert Review to Feed '"
                                class="btn btn-sm btn-info m-1 text-white"
                                @click="
                                    moveReviewsDirectStart(
                                        props.formattedRow.id,
                                        props.index,
                                        props.row.feed && props.row.feed.id
                                            ? true
                                            : false
                                    )
                                "
                            >
                                {{
                                    props.row.feed && props.row.feed.id
                                        ? 'Re-convert'
                                        : 'Convert'
                                }}
                                To Feed
                                <i class="bi bi-arrow-clockwise d-none"></i>
                            </button>

                            <div class="d-flex justify-content-center w-100" v-tooltip="'Make the Review ready for publishing in the Social Media Feeds (ONLY if Auto Post Social Media Reviews is set to YES for this Client)'" v-if="props.formattedRow.status == 0 || props.formattedRow.status == 2">
                                <label class="form-check-label m-b-10 f-w-600" style="padding-top: 6%; margin-right: 0%;" for="is_use_for_feed">
                                    Is for Feed ?
                                </label>
                                <div class="form-check form-switch" style="padding-left:3.5em">
                                    <input
                                        class="form-check-input"
                                        v-model="props.row.is_use_for_feed"
                                        type="checkbox"
                                        id="is_use_for_feed"
                                        @change="updateFeedStatus(props.row.id, props.row.is_use_for_feed)"
                                    />
                                </div>
                            </div>
                            <div class="d-flex justify-content-center" v-else>
                                <span class="badge bg-danger" v-if="!props.row.is_use_for_feed || recentUpdatedFeedStatusReviewsIds.includes(props.row.id)">
                                    {{ (props.row.is_use_for_feed && !recentUpdatedFeedStatusReviewsIds.includes(props.row.id)) ? 'Review can be used both to create feed and in widgets.' : 'Review can only be used in widgets.' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </vue-good-table>

        <div class="modal fade" id="showImgInPopup" tabindex="-1" aria-labelledby="showImgInPopupLabel" aria-hidden="true" >
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-transparent border-0">
                    <div class="modal-body px-0" style="width: fit-content; margin: 0 auto; z-index: 0">
                        <div class="popup-img-div">
                            <div class="popup-d w-fit-content m-auto position-relative">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close btn btn-close custom-btn bg-white p-3"></button>
                                <img class="h-auto img-fluid" :src="showImgInPopUpSrc" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '@/components/user/reviews/loading.vue'
export default {
    name: 'reviews',
    components: {
        vSelect,
        Loading,
    },
    data() {
        return {
            reviewMoveStatus: false,
            reviewFetchStatus: false,
            autoTagAsignStatus: false,
            formFields: {
                short_description: {},
            },
            filter: {
                user_id: '',
                tag_id: '',
                smart_tag_id: '',
                platform: '',
                rating: '',
                status: '',
                isRecordFiltered: false,
                filteredFields: [],
                short_description: '',
            },
            sort: {
                field: '',
                type: '',
            },
            status: [
                {
                    id: '0',
                    title: 'Pending',
                },
                {
                    id: '1',
                    title: 'Approved',
                },
                {
                    id: '2',
                    title: 'Declined',
                },
            ],
            ratings: [
                {
                    id: '1',
                    title: '★☆☆☆☆',
                },
                {
                    id: '2',
                    title: '★★☆☆☆',
                },
                {
                    id: '3',
                    title: '★★★☆☆',
                },
                {
                    id: '4',
                    title: '★★★★☆',
                },
                {
                    id: '5',
                    title: '★★★★★',
                },
            ],
            platforms: [],
            columns: [
                {
                    label: 'highlight_text',
                    field: 'highlight_text',
                    hidden: true,
                },
                {
                    label: 'id',
                    field: 'id',
                    hidden: true,
                },
                {
                    label: 'rating',
                    field: 'rating',
                    hidden: true,
                },
                {
                    label: 'platform',
                    field: 'platform',
                    hidden: true,
                },
                {
                    label: 'avatar',
                    field: 'avatar',
                    hidden: true,
                },
                {
                    label: 'Client',
                    field: 'user',
                    sortable: false,
                    hidden: true,
                    width: '13%',
                },
                {
                    label: 'Author',
                    field: 'author',
                    sortable: true,
                    width: '17%',
                },
                {
                    label: 'Review',
                    field: 'text',
                    sortable: false,
                    width: '38%',
                },
                {
                    label: 'Short Description',
                    field: 'short_description',
                    sortable: true,
                    hidden: true,
                },
                {
                    label: 'Tags',
                    field: 'tags',
                    sortable: false,
                    width: '20%',
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: false,
                    hidden: true,
                },
                {
                    label: 'Action',
                    field: 'actions',
                    sortable: false,
                    width: '15%',
                    globalSearchDisabled: true,
                },
            ],
            listing: [],
            tagsCategories: [],
            tags: [],
            users: [],
            smartTags: [],
            totalRows: null,
            isLoading: true,
            checkedTagsIds: {},
            sortFields: [
                {
                    label: 'Saved At Date',
                    field: 'created_at',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Author',
                    field: 'author',
                },
                {
                    label: 'Platform',
                    field: 'platform',
                },
                {
                    label: 'Rating',
                    field: 'rating',
                },
            ],
            smartTagsStrs: {},
            listingCount: {
                from: 0,
                to: 0,
                total: 0,
            },
            shortDescriptionLoading: false,
            maxReviewDescription: 0,
            maxReviewShortDescription: 0,
            shortDescriptionTextCount: {},
            fetchShortDescriptionLoading: false,
            perPage: 10,
            recentUpdatedFeedStatusReviewsIds:[],
            showImgInPopUpSrc:''
        }
    },
    validations: {
        formFields: {
            category_id: {
                required,
            },
            title: {
                required,
            },
            status: {
                required,
            },
        },
    },
    computed: {},
    created: async function () {
        this.isLoading = true

        await this.getTagsDropDown({}).then((resp) => {
            if (resp.status) {
                this.tags = resp.data
                this.setSmartTagsStrs()
            } else {
                console.error(
                    resp.message
                        ? resp.message
                        : 'Something went wrong please try in some time'
                )
            }
        })

        await this.getSmartTagsDropDown({ id: '' }).then((resp) => {
            if (resp.status) {
                this.smartTags = resp.data
            } else {
                console.error(
                    resp.message
                        ? resp.message
                        : 'Something went wrong please try in some time'
                )
            }
        })

        await this.fetchUsersDropDown({is_for_admin_reviews:1}).then((resp) => {
            if (resp.status) {
                this.users = []
                resp.data.map((item, index) => {
                    this.users.push({
                        ...item,
                        name: ` ${item.first_name} ${item.last_name} `,
                    })
                })
                // this.users = resp.data
            } else {
                console.log(resp.message)
            }
        })

        await this.getSetting([
            'social_platforms',
            'is_move_review_in_process',
            'max_review_short_description',
            'max_review_description',
        ]).then((resp) => {
            this.platforms = []
            if (resp.social_platforms) {
                resp.social_platforms.map((item) => {
                    this.platforms.push(
                        item.charAt(0).toUpperCase() + item.slice(1)
                    )
                })
            }

            this.reviewMoveStatus = resp.is_move_review_in_process
                ? parseInt(resp.is_move_review_in_process)
                : 0
            this.maxReviewDescription = resp.max_review_description
            this.maxReviewShortDescription = resp.max_review_short_description
        })

        let data = localStorage.getItem('admin_review_filter_sync')
        if(data)
        {
            this.filter = {
                ...this.filter,
                ...JSON.parse(data)
            }
        }
        this.filterData()
    },
	mounted:function(){
		let removeEventListener = function(){
            document.removeEventListener("contextmenu",window.$disableRightClick, false);
        } 
        window.onload = removeEventListener
        removeEventListener()
    },
    beforeRouteLeave:function(to, from , next) {
        document.addEventListener("contextmenu",window.$disableRightClick, false);
        next()
    },
    methods: {
        ...mapActions([
            'addReview',
            'getReview',
            'deleteReview',
            'updateReview',
            'getReviews',
            'getTagsDropDown',
            'getSmartTagsDropDown',
            'tagMatchAction',
            'autoAsignTags',
            'updateReviewStatus',
            'fetchUsersDropDown',
            'getSetting',
            'genrateReviewShortDescription',
        ]),
        setSmartTagsStrs: function () {
            let smartTagsStrs = this.smartTagsStrs
            this.tags.map((item, key) => {
                if (!smartTagsStrs[item.id]) {
                    smartTagsStrs[item.id] = ''
                }

                if (item.smart_tags_linked) {
                    item.smart_tags_linked.map((smartTag, smartIndex) => {
                        if (smartTagsStrs[item.id]) {
                            smartTagsStrs[item.id] = `${
                                smartTagsStrs[item.id]
                            }, ${smartTag.title}`
                        } else {
                            smartTagsStrs[item.id] = smartTag.title
                        }
                    })

                    if (smartTagsStrs[item.id] == '') {
                        smartTagsStrs[item.id] = '-'
                    }
                }
            })
            this.smartTagsStrs = smartTagsStrs
        },
        add: function (e) {
            e.preventDefault()
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.addTag(this.formFields)
                    .then((resp) => {
                        if (resp.status) {
                            this.resetFormData()
                            this.closeModal('#addModal')
                            this.notify(resp.message)
                            this.refresh()
                        } else {
                            this.notify(resp.message, 'danger')
                        }
                    })
                    .catch((resp) => {
                        this.notify(
                            'Something went wrong please in some time',
                            'danger'
                        )
                    })
            }
        },
        update: function (e) {
            e.preventDefault()
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.updateTag(this.formFields)
                    .then((resp) => {
                        if (resp.status) {
                            this.resetFormData()
                            this.closeModal('#updateModal')
                            this.$notify(resp.message)
                            this.refresh()
                        } else {
                            this.$notify(resp.message, 'danger')
                        }
                    })
                    .catch((resp) => {
                        this.$notify(
                            'Something went wrong please in some time',
                            'danger'
                        )
                    })
            }
        },
        openUpdateModal: function (id) {
            this.getTag(id).then((resp) => {
                if (resp.status) {
                    let { data } = resp
                    if (data) {
                        this.formFields = {
                            id: data.id,
                            category_id: data.tag_category
                                ? data.tag_category.id
                                : '',
                            title: data.title,
                            status: data.status,
                        }
                    }
                } else {
                    this.$notify(resp.message, 'danger')
                }
            })
        },
        resetFormData: function () {
            this.formFields = {
                id: null,
                category_id: null,
                title: '',
                status: 1,
            }
            this.$v.$reset()
        },
        onPageChange: function (data) {
            this.fetchListing(data.currentPage)
        },
        onPerPageChange: function (data) {
            this.perPage = data.currentPerPage
            this.fetchListing(1, data.currentPerPage)
        },
        onSortChange: function (data) {
            this.fetchListing(1, '', data[0].field, data[0].type)
        },
        onSearch: function (data) {
            this.fetchListing(1, '', '', '', data.searchTerm)
        },
        fetchListing: function (page = 1, limit = '', field = '', direction = '', search = '', user_id = '', tag_id = '', smart_tag_id = '', rating = '', platform = '', status = '', short_description = ''){
            this.isLoading = true
            direction = direction != 'none' ? direction : ''

            user_id = !user_id ? this.filter.user_id : user_id
            tag_id = !tag_id ? this.filter.tag_id : tag_id
            smart_tag_id = !smart_tag_id
                ? this.filter.smart_tag_id
                : smart_tag_id
            rating = !rating ? this.filter.rating : rating
            status = !status ? this.filter.status : status
            platform = !platform ? this.filter.platform : platform
            short_description = !short_description
                ? this.filter.short_description
                : short_description
            limit = !limit ? this.perPage : limit

            this.getReviews({
                page,
                limit,
                field,
                direction,
                search,
                user_id,
                tag_id,
                smart_tag_id,
                rating,
                platform,
                status,
                short_description,
            })
            .then(({ resp, fullResp }) => {
                    this.totalRows = resp.total
                    this.listing = resp.data
                    this.isLoading = false
                    this.listing.map((item, index) => {
                        if (!this.formFields.short_description[item.id]) {
                            this.formFields.short_description[item.id] =
                                item.short_description
                        }
                    })
                    this.reviewFetchStatus = parseInt(
                        fullResp.review_fetch_status
                    )
                    this.autoTagAsignStatus = parseInt(
                        fullResp.auto_tag_asign_status
                    )

                    this.listingCount = {
                        from: resp.from,
                        to: resp.to,
                        total: resp.total,
                    }

                    this.setCheckedTags()
                    this.makeReviewHighLight()
                })
                .catch((e) => {
                    console.log(' Something went wrong please try later ', e)
                })
        },
        deleteRecord: async function (e) {
            let id = e.target.getAttribute('data-id')
            let resp = await this.deleteTag(id)
            if (resp && resp.status) {
                let deleteBtn = document.querySelector(
                    `button.open_delete_modal[data-id='${id}']`
                )
                deleteBtn.parentElement.parentElement.remove()
                this.notify(resp.message)
            } else {
                this.notify(resp.message, 'danger')
            }
        },
        onOpenDeleteModal: function (id) {
            let deleteModal = document.querySelector('div#deleteModal')
            if (deleteModal) {
                deleteModal
                    .querySelector('button.delete_record_btn')
                    .setAttribute('data-id', id)
            }
        },
        closeModal: function (modal) {
            let closeBtn = document
                .querySelector(modal)
                .querySelector('.close-icon')
            if (closeBtn) {
                closeBtn.click()
            }
        },
        refresh: function () {
            this.onSearch('')
        },
        notify: function (msg, type = 'success') {
            this.$toasted.show(msg, {
                theme: 'bubble',
                type: type,
                position: 'top-center',
                duration: 5000,
                singleton: true,
            })
        },
        onStatusChange: function (e, row) {
            let temp = {
                id: row.id,
                title: row.title,
                category_id: row.tag_category ? row.tag_category.id : '',
                status: e.target.checked ? 1 : 0,
            }
            this.updateTag(temp).then((resp) => {
                if (resp.status) {
                    this.$notify(resp.message)
                } else {
                    this.$notify(
                        resp.message
                            ? resp.message
                            : 'something went wrong please try later',
                        'danger'
                    )
                }
            })
        },
        filterData: function () {
            this.fetchListing(
                1,
                '',
                '',
                '',
                '',
                this.filter.user_id,
                this.filter.tag_id,
                this.filter.smart_tag_id,
                this.filter.rating,
                this.filter.platform,
                this.filter.status,
                this.filter.short_description
            )
            if(document.querySelector('#dropdownMenuButton2'))
            {
                let filterDropDown = document.querySelector('#dropdownMenuButton2');
                if(filterDropDown.classList.contains('show'))
                {
                    document.querySelector('#dropdownMenuButton2').click()
                }
            }
            localStorage.setItem('admin_review_filter_sync',JSON.stringify(this.filter))
            this.filter.filteredFields = []
            
            let lables = {
                user_id: 'User',
                tag_id: 'Tag',
                smart_tag_id: 'Smart Tag',
                rating: 'Rating',
                platform: 'Platform',
                status: 'Status',
                short_description: 'Short Description',
            }
            
            for (let key in this.filter) {
                if ( key != 'isRecordFiltered' && key != 'filteredFields' && (this.filter[key] || (this.filter[key] == 'status' && this.filter[key] >= 0)))
                {
                    this.filter.filteredFields.push({
                        field: key,
                        label: lables[key],
                        value: this.getFilterFieldLabel(key),
                    })
                }
            }

            if(this.filter.filteredFields.length > 0)
            {
                this.filter.isRecordFiltered = true
            }
            else
            {
                this.filter.isRecordFiltered = false
            }
        },
        getFilterFieldLabel: function (key) {
            let vars = {
                user_id: this.users,
                tag_id: this.tags,
                smart_tag_id: this.smartTags,
                rating: this.ratings,
                platform: this.platforms,
                status: this.status,
                short_description: ['All', 'Empty', 'Filled'],
            }
            let value

            for (let index in vars) {
                if (vars[key].length > 0 && vars[key].map) {
                    vars[key].map((item, index) => {
                        if (key == 'user_id') {
                            if (this.filter[key] == item.id) {
                                value = `${item.first_name} ${item.last_name}`

                                return value
                            }
                        } else if (
                            ['tag_id', 'smart_tag_id', 'status'].includes(key)
                        ) {
                            if (this.filter[key] == item.id) {
                                value = `${item.title}`

                                return value
                            }
                        } else {
                            value = this.filter[key]
                            return value
                        }
                        return value
                    })
                } else {
                    console.log(key)
                }
            }

            return value
        },
        resetFilter: function () {
            this.filter.user_id = ''
            this.filter.tag_id = ''
            this.filter.smart_tag_id = ''
            this.filter.rating = ''
            this.filter.platform = ''
            this.filter.status = ''
            this.filter.short_description = ''

            this.filterData()
        },
        resetSort: function () {
            this.sort.field = ''
            this.sort.type = ''

            this.refresh()
        },
        sortData: function () {
            this.fetchListing(1, '', this.sort.field, this.sort.type)
        },
        setCheckedTags: function () {
            for (let key in this.listing) {
                if (!this.checkedTagsIds[this.listing[key].id]) {
                    this.checkedTagsIds[this.listing[key].id] = {
                        tags: [],
                        smartTags: [],
                        title: [],
                        titles: {
                            tags: [],
                            smartTags: [],
                        },
                    }
                }

                if (this.listing[key] && this.listing[key].tags) {
                    this.listing[key].tags.map((item, index) => {
                        if (item.tag_id) {
                            if (
                                !this.checkedTagsIds[
                                    this.listing[key].id
                                ].tags.includes(item.tag_id)
                            ) {
                                this.checkedTagsIds[
                                    this.listing[key].id
                                ].tags.push(item.tag_id)
                            }

                            if (
                                item.tag &&
                                !this.checkedTagsIds[
                                    this.listing[key].id
                                ].titles.tags.includes(item.tag.title)
                            ) {
                                this.checkedTagsIds[
                                    this.listing[key].id
                                ].titles.tags.push(item.tag.title)
                            }
                        }

                        if (item.smart_tag_id) {
                            if (
                                !this.checkedTagsIds[
                                    this.listing[key].id
                                ].smartTags.includes(item.smart_tag_id)
                            ) {
                                this.checkedTagsIds[
                                    this.listing[key].id
                                ].smartTags.push(item.smart_tag_id)
                            }

                            if (item.smart_tag) {
                                if (
                                    !this.checkedTagsIds[
                                        this.listing[key].id
                                    ].titles.smartTags.includes(
                                        item.smart_tag.title
                                    )
                                ) {
                                    this.checkedTagsIds[
                                        this.listing[key].id
                                    ].titles.smartTags.push(
                                        item.smart_tag.title
                                    )
                                }
                            }
                        }
                    })
                }
            }
        },
        highlightWords: function (text, words) {
            words.tags.forEach((word) => {
                let regex = new RegExp(`(${word})`, 'gi')
                text = text.replace(
                    regex,
                    '<span title="Tag" style="background-color:orange">$1</span>'
                )
            })

            words.smartTags.forEach((word) => {
                let regex = new RegExp(`(${word})`, 'gi')
                text = text.replace(
                    regex,
                    '<span title="Smart Tag" style="background-color:yellow">$1</span>'
                )
            })
            return text
        },
        makeReviewHighLight: function () {
            this.listing.map((item, index) => {
                this.listing[index].highlight_text = this.highlightWords(
                    item.text,
                    this.checkedTagsIds[item.id].titles
                )
            })
        },
        onChangeEditableDiv: function (e, index) {
            let text = e.target.innerText
            this.listing[index].text = text
            let id = this.listing[index].id
            this.listing[index].highlight_text = this.highlightWords(text, this.checkedTagsIds[id].titles)
        },
        onChangeTag: async function (e) {
            let that = e.target
            let { value, checked } = that
            let dataId = that.getAttribute('dataId')
            let dataType = that.getAttribute('dataType')
            let dataParentId = that.getAttribute('dataParentId')
            let dataIndex = that.getAttribute('dataIndex')
            let action = checked ? 'add' : 'remove'
            let body = {
                action: action,
                review_id: dataParentId,
                type: 'tag',
                tag_id: dataId,
                // smart_tag_id:null
            }

            let resp = await this.tagMatchAction(body)
            if (resp.status) {
                let { titles, ids } = this.addRemoveSmartTags(resp.tag)
                if (checked) {
                    this.checkedTagsIds[dataParentId][dataType].push(dataId)
                    this.checkedTagsIds[dataParentId].titles[dataType].push(
                        value
                    )

                    if (titles.length > 0) {
                        let oldTitles =
                            this.checkedTagsIds[dataParentId].titles.smartTags
                        this.checkedTagsIds[dataParentId].titles.smartTags =
                            oldTitles.concat(titles)
                    }

                    if (ids.length > 0) {
                        let oldIds = this.checkedTagsIds[dataParentId].smartTags
                        this.checkedTagsIds[dataParentId].smartTags =
                            oldIds.concat(ids)
                    }
                } else {
                    let indexOfId = this.checkedTagsIds[dataParentId][
                        dataType
                    ].indexOf(parseInt(dataId))
                    if (indexOfId >= 0) {
                        this.checkedTagsIds[dataParentId][dataType].splice(
                            indexOfId,
                            1
                        )
                    }

                    let indexOfTitle =
                        this.checkedTagsIds[dataParentId].titles[
                            dataType
                        ].indexOf(value)
                    if (indexOfTitle >= 0) {
                        this.checkedTagsIds[dataParentId].titles[
                            dataType
                        ].splice(indexOfTitle, 1)
                    }

                    if (titles.length > 0) {
                        let indexOfTitle = null
                        titles.map((item, index) => {
                            indexOfTitle =
                                this.checkedTagsIds[
                                    dataParentId
                                ].titles.smartTags.indexOf(item)
                            if (indexOfTitle >= 0) {
                                this.checkedTagsIds[
                                    dataParentId
                                ].titles.smartTags.splice(indexOfTitle, 1)
                            }
                        })
                    }

                    if (ids.length > 0) {
                        let indexOfIds = null
                        ids.map((item, index) => {
                            indexOfIds =
                                this.checkedTagsIds[
                                    dataParentId
                                ].smartTags.indexOf(item)
                            if (indexOfIds >= 0) {
                                this.checkedTagsIds[
                                    dataParentId
                                ].smartTags.splice(indexOfIds, 1)
                            }
                        })
                    }
                }
                let text = this.listing[dataIndex].text
                this.listing[dataIndex].highlight_text = this.highlightWords(
                    text,
                    this.checkedTagsIds[dataParentId].titles
                )
                this.$notify(resp.message)
            } else {
                this.$notify(resp.message, 'danger')
            }
        },
        addRemoveSmartTags: function (tag) {
            let titles = []
            let ids = []
            if (tag.smart_tags) {
                tag.smart_tags.map((item, index) => {
                    if (item.smart_tag) {
                        titles.push(item.smart_tag.title)
                        ids.push(item.smart_tag.id)
                    }
                })
            } else if (tag.smart_tags_linked) {
                tag.smart_tags_linked.map((item, index) => {
                    titles.push(item.title)
                    ids.push(item.id)
                })
            }

            return {
                titles,
                ids,
            }
        },
        autoAsignTagsToReviews: async function (id, dataIndex) {
            if (confirm('Are You Sure ?')) {
                let resp = await this.autoAsignTags(id)
                if (resp.status) {
                    if (!this.checkedTagsIds[id]) {
                        this.checkedTagsIds[this.listing[key].id] = {
                            tags: [],
                            smartTags: [],
                            title: [],
                            titles: {
                                tags: [],
                                smartTags: [],
                            },
                        }
                    }

                    if (resp.tags) {
                        resp.tags.map((item, index) => {
                            if (item.tag_id) {
                                if (
                                    !this.checkedTagsIds[id].tags.includes(
                                        item.tag_id
                                    )
                                ) {
                                    this.checkedTagsIds[id].tags.push(
                                        item.tag_id
                                    )
                                }

                                if (
                                    item.tag &&
                                    !this.checkedTagsIds[
                                        id
                                    ].titles.tags.includes(item.tag.title)
                                ) {
                                    this.checkedTagsIds[id].titles.tags.push(
                                        item.tag.title
                                    )
                                }
                            }

                            if (item.smart_tag_id) {
                                if (
                                    !this.checkedTagsIds[id].smartTags.includes(
                                        item.smart_tag_id
                                    )
                                ) {
                                    this.checkedTagsIds[id].smartTags.push(
                                        item.smart_tag_id
                                    )
                                }

                                if (
                                    item.smart_tag &&
                                    !this.checkedTagsIds[
                                        id
                                    ].titles.smartTags.includes(
                                        item.smart_tag.title
                                    )
                                ) {
                                    this.checkedTagsIds[
                                        id
                                    ].titles.smartTags.push(
                                        item.smart_tag.title
                                    )
                                }
                            }
                        })

                        let text = this.listing[dataIndex].text
                        this.listing[dataIndex].highlight_text =
                            this.highlightWords(
                                text,
                                this.checkedTagsIds[id].titles
                            )
                    }
                    this.notify(resp.message)
                } else {
                    this.notify(resp.message, 'danger')
                }
            }
        },
        updateStatusReview: async function (id, status, index) {
            let resp = await this.updateReviewStatus({ id, status })
            if (resp.status)
            {
                this.listing[index].status = status
                this.$notify(resp.message)
            }
            else
            {
                this.$notify(resp.message, 'danger')
            }
        },
        updateShortDescription: async function (id) {
            let short_description = this.formFields.short_description[id]
            if (short_description) {
                this.shortDescriptionLoading = id
                let resp = await this.updateReview({ id, short_description })
                if (resp.status) {
                    this.$notify(resp.message)
                } else {
                    this.$notify(resp.message, 'danger')
                }
                this.shortDescriptionLoading = false
            } else {
                this.$notify('Please fill short description', 'danger')
            }
        },
        onTagsDropDownChange: function () {
            if (this.filter.tag_id) {
                this.filter.smart_tag_id = ''
                this.smartTags = []
                this.getSmartTagsDropDown({ id: this.filter.tag_id }).then(
                    (resp) => {
                        if (resp.status) {
                            this.smartTags = resp.data
                        } else {
                            console.error(
                                resp.message
                                    ? resp.message
                                    : 'Something went wrong please try in some time'
                            )
                        }
                    }
                )
            } else {
                this.filter.smart_tag_id = ''
                this.smartTags = []
                this.getSmartTagsDropDown({ id: '' }).then((resp) => {
                    if (resp.status) {
                        this.smartTags = resp.data
                    } else {
                        console.error(
                            resp.message
                                ? resp.message
                                : 'Something went wrong please try in some time'
                        )
                    }
                })
            }
        },
        isFilterOpen: function () {
            return $('body').find('#dropdownMenuButton2').hasClass('show')
                ? true
                : false
        },
        fetchReviewsStart: async function () {
            if (confirm('Are You Sure ?')) {
                let resp = await this.$helper.getRequest(
                    `users/fetch-reviews/all`
                )

                if (resp && resp.status) {
                    this.reviewFetchStatus = true
                    this.$notify(resp.message)
                } else {
                    this.$notify(resp.message, 'danger')
                }
            }
        },
        autoAsignTagsAll: function () {
            if (confirm('Are You Sure ?')) {
                this.autoTagAsignStatus = true
                this.autoAsignTags('all').then((resp) => {
                    if (resp.status) {
                        this.$notify(resp.message)
                    } else {
                        this.$notify(
                            resp.message
                                ? resp.message
                                : 'Something went wrong please try in some time',
                            'danger'
                        )
                    }
                    this.autoTagAsignStatus = false
                    this.refresh()
                })

                this.$notify('Process has been started please wait...')
            }
        },
        moveReviewsStart: async function () {
            if (confirm('Are You Sure ?')) {
                let resp = await this.$helper.getRequest(
                    `users/move-reviews/all`
                )

                if (resp && resp.status) {
                    this.reviewMoveStatus = true
                    this.$notify(resp.message)
                } else {
                    this.$notify(resp.message, 'danger')
                }
            }
        },
        moveReviewsDirectStart: async function (reviewId, index, reConvert = false) {
            let msg = ''
            if (reConvert) {
                msg = `Are you certain you want to regenerate the social media feed? \n\nThis action will overwrite any unscheduled posts from this review, but it will not impact posts that have already been published \n\n Click "OK" to proceed or "Cancel" to cancel the action.`
            } else {
                msg = `Are you certain you want to generate the social media feed?\n\nClick "OK" to proceed or "Cancel" to cancel the action.`
            }

            if (confirm(msg)) {
                this.reviewMoveStatus = true
                let resp = await this.$helper.getRequest(
                    `users/move-reviews-direct/${reviewId}`
                )

                if (resp && resp.status) {
                    this.refresh('')
                    this.$notify(resp.message)
                    this.reviewMoveStatus = false
                } else {
                    this.reviewMoveStatus = false
                    this.$notify(resp.message, 'danger')
                }
            }
        },
        closeFilter: function () {
            $('body').find('#dropdownMenuButton2').click()
        },
        onStatusUpdate: function (e) {
            console.log(e)
        },
        updateCount: function (id, text) {
            this.shortDescriptionTextCount = {
                ...this.shortDescriptionTextCount,
                [id]: text.length,
            }
        },
        getReviewDescription: async function (id) {
            this.fetchShortDescriptionLoading = id
            let resp = await this.genrateReviewShortDescription(id)
            if (resp.status)
            {
                this.formFields.short_description[id] =
                    resp.short_description
                this.$notify(resp.message)
            }
            else
            {
                this.$notify(resp.message, 'danger')
            }
            this.fetchShortDescriptionLoading = false
        },
        updateFeedStatus:function(reviewId, reviewFeedStatus){
            
            this.$helper.postRequest(`reviews/${reviewId}/update-feed-status`,{
                "is_use_for_feed":reviewFeedStatus
            }).then((resp) => {
                if(resp)
                {
                    if(resp.status)
                    {
                        if(reviewFeedStatus)
                        {
                            let indexOf = this.recentUpdatedFeedStatusReviewsIds.indexOf(reviewId)

                            if(indexOf >= 0)
                            {
                                this.recentUpdatedFeedStatusReviewsIds.splice(indexOf, 1)
                            }
                        }
                        else
                        {
                            this.recentUpdatedFeedStatusReviewsIds.push(reviewId)
                        }

                        this.$notify(resp.message,'success',5000)
                    }
                    else
                    {
                        this.$notify(resp.message,"danger")
                    }
                }
                else
                {
                    this.$notify("Something went wrong please try in sometime.", "danger")
                }
            })
        },
        showImgInPopup(img) {
            this.showImgInPopUpSrc = img;
            $("#showImgInPopup").modal("show");
        },
    },
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.form-switch .form-check-input {
    width: 50px !important;
    height: 2rem !important;
}
.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.openModalBusiness {
    cursor: pointer;
}

.rounded-circle {
    content: '';
    position: absolute;
    top: -1px;
    right: -3px;
    width: 8px;
    height: 8px;
}
.star {
    color: gold;
    font-size: 16px;
}

.wrapper {
    position: relative;
    width: 100%;
}

.highlighted-text {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    height: 150px;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.pointer-cursor {
    cursor: pointer;
}

.pointer-disabled {
    cursor: not-allowed;
}

.btn.btn-primary.dropdown-toggle.position-relative.show::after {
    border-top: 0 !important;
    border-bottom: 0.3em solid !important;
}
</style>

<style>
.reviews-admin-listing table#vgt-table tr {
    border-bottom-width: 4px !important;
}
</style>