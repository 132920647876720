<template>
  <div
    id="main"
    :class="
      $route.meta.showFrontLayout ? 'front-custom-layout' : 'dashboard-layout'
    "
  >
    <Navbar v-if="$route.meta.showDashLayout == true" />
    <FrontNavbar v-if="$route.meta.showFrontLayout == true" />
    <div
      :class="
        $route.meta.showFrontLayout
          ? 'front-custom-layout-inner'
          : 'dashboard-layout-inner'
      "
    >
      <router-view />
    </div>
    <GlobalFooter v-if="!$route.meta.hideFooter" />
  </div>
</template>

<script>
import Navbar from "./components/navbar.vue";
import GlobalFooter from "./components/globalFooter.vue";
import AdminNavbar from "./components/admin/adminNavbar.vue";
import FrontNavbar from "./components/user/frontHeader.vue";

export default {
  name: "App",
  components: { Navbar, GlobalFooter, AdminNavbar, FrontNavbar },
  data() {
    return {
      token: "",
      role: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
  },
};
</script>

<style>
@import "./assets/css/styles.css";
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.ProductSummaryTotalAmount
  flex-container
  direction-row
  align-items-baseline
  wrap-wrap {
  display: none !important;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  position: relative;
}

.dashboard-layout {
  padding-bottom: 70px;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
