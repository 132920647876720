
export const countryTimeZones = {
  "United States": [
    "America/New_York",    // Eastern Time
    "America/Chicago",     // Central Time
    "America/Denver",      // Mountain Time
    "America/Los_Angeles", // Pacific Time
    "America/Phoenix",     // Mountain Time (no DST)
    "America/Anchorage",   // Alaska Time
    "Pacific/Honolulu",    // Hawaii-Aleutian Time
  ],
  "Canada": [
    "America/Toronto",     // Eastern Time
    "America/Vancouver",   // Pacific Time
    "America/Winnipeg",    // Central Time
    "America/Halifax",     // Atlantic Time
    "America/St_Johns",    // Newfoundland Time
    "America/Edmonton",    // Mountain Time
    "America/Regina",      // Central Time (no DST)
    "America/Whitehorse",  // Pacific Time (no DST)
    "America/Iqaluit",     
  ],
  "India": ["Asia/Kolkata"],
  "United Kingdom": ["Europe/London"],
  "Australia": [
    "Australia/Sydney",    // Eastern Time (Daylight Saving)
    "Australia/Melbourne", // Eastern Time (Daylight Saving)
    "Australia/Brisbane",  // Eastern Time (no DST)
    "Australia/Adelaide",  // Central Time (Daylight Saving)
    "Australia/Perth",     // Western Time (no DST)
    "Australia/Hobart",    // Eastern Time (Daylight Saving)
    "Australia/Darwin",    // Central Time (no DST)
  ], 
  "Other": [
    "America/Toronto",     // Eastern Time
    "America/Vancouver",   // Pacific Time
    "America/Winnipeg",    // Central Time
    "America/Halifax",     // Atlantic Time
    "America/St_Johns",    // Newfoundland Time
    "America/Edmonton",    // Mountain Time
    "America/Regina",      // Central Time (no DST)
    "America/Whitehorse",  // Pacific Time (no DST)
    "America/Iqaluit",     
  ], 
};
