import reviewsClient from '../../components/user/reviews/index.vue'

let reviewsRoutes = [
	{
        path: '/reviews',
        name: 'ReviewsClient',
        component: reviewsClient,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
]

export default reviewsRoutes
