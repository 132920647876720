import axios from 'axios'
import Router from './router';

let getFormatedDate = (date) => {
    let newDate = new Date(date)
    let month = newDate.getMonth();
    let onlyDate = newDate.getDate()

    return `${month < 9 ? 0 : ''}${month+1}-${onlyDate <= 9 ? 0 : ''}${onlyDate}-${newDate.getFullYear()}`
}

let getFormatedDateTime = (date) => {
    let newDate = new Date(date)
    let month = newDate.getMonth();
    let onlyDate = newDate.getDate()

    let hr = newDate.getHours()
    let min = newDate.getMinutes()
    let time = `${hr <= 12 ? hr : (hr-12) }:${min > 0 ? min : '00'} ${hr > 12 ? 'PM' : 'AM'}`

    return `${month < 9 ? 0 : ''}${month+1}-${onlyDate <= 9 ? 0 : ''}${onlyDate}-${newDate.getFullYear()} ${time}`
}

let postRequest = async (url, body) => {
    let options = {
            headers:{
            'Content-type': 'application/json',
            token: `${localStorage.getItem('token')}`,
        }
    }
    let resp = await axios.post(`${process.env.VUE_APP_BASEURL}/${url}`, body, options).then(responseHandle).catch(errorHandle) 
    return resp ? resp : {}
}

let getRequest = async (url) => {
    let options = {
            headers:{
            'Content-type': 'application/json',
            token: `${localStorage.getItem('token')}`,
        }
    }
    let resp = await axios.get(`${process.env.VUE_APP_BASEURL}/${url}`,options).then(responseHandle).catch(errorHandle)
    return resp ? resp : {}
}

let downloadRequest = async (url) => {
    let options = {
            headers:{
            'Content-type': 'application/json',
            responseType: 'blob',
            token: `${localStorage.getItem('token')}`,
        }
    }

    return await axios.get(`${process.env.VUE_APP_BASEURL}/${url}`,options).then(responseHandle).catch(errorHandle)
}

let responseHandle = (response) => {
    return response.data;
}

let errorHandle = (err) => {
    if(err.message == 'Request failed with status code 401')
    {
        localStorage.removeItem('token')  
        if(Router.currentRoute.path != '/login')
        {
            Router.push('/login');
        }
    }
} 

export {
    getRequest,
    postRequest,
    getFormatedDate,
    getFormatedDateTime,
    downloadRequest
}

export default {
    getRequest,
    postRequest,
    getFormatedDate,
    getFormatedDateTime,
    downloadRequest
}