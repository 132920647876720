
import widget from '../../components/user/widget/index.vue'
import add from '../../components/user/widget/add.vue'
import edit from '../../components/user/widget/edit.vue'

let widgetRoutes = [
    {
        path: "/widget/update/:id",
        name: 'WidgetUpdate',
        component: edit,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
    {
        path: "/widget/add",
        name: 'WidgetAdd',
        component: add,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
    {
        path: "/widgets",
        name: 'Widget',
        component: widget,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    }
];

export default widgetRoutes