<template>
  <div ref="anyName" class="col-10 mx-auto mt-5 blog-post-creator card p-5 shadow-lg border rounded">
    <h4 class="m-2" style="text-align: center">Add New Feed</h4>
    <form class="m-3 p-3">
		<div class="">
			<div class="title-input">
				<input
					class="form-control"
					v-model="forms.title"
					placeholder="Enter a post title"
					:class="{ 'is-invalid': $v.forms.title.$error }"
				/>
				<p v-if="$v.forms.title.$error" class="text-left pt-1" style="color: red">
					Post must have a title
				</p>
			</div>
			<div class="mb-3 mt-3 media-input">
				<div class="select-media-type">
					<label>Select Media Type</label>
					<div class="d-flex gap-3 align-items-center">
						<div class="media-image d-flex gap-2 align-items-center" role="button" >
							<input type="radio" role="button"  name="select-media" id="select-image" value="image" :checked="true" @change="changeMediaType">
							<label for="select-image" role="button" > Image </label>
						</div>
						<div class="media-video d-flex gap-2 align-items-center" role="button" >
							<input type="radio" role="button"  name="select-media" id="select-video" value="video" @change="changeMediaType">
							<label for="select-video" role="button" > Video </label>
						</div>
						<div class="media-video d-flex gap-2 align-items-center" role="button" >
							<input type="radio" role="button"  name="select-media" id="select-url" value="url" @change="changeMediaType">
							<label for="select-url" role="button" >Embed Url </label>
						</div>
					</div>
					<p
						v-if="$v.forms.mediaType.$error"
						class="text-left"
						style="color: red"
					>
						Please select type of media
					</p>
				</div>
				<div class="inner-image-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'image'">
					<div class="image-input">
						<label
							class="ivu-btn ivu-btn-primary"
							for="postMedia"
							icon="ios-camera-outline"
						>
							Add Image
							<input
								type="file"
								id="postMedia"
								accept="image/*"
								@change="uploadMedia"
								style="display: none"
							/>
						</label>
						<div v-if="image_url == ''" class="mt-1">
							<small class="text-danger font-monospace">
								1. Max upload size: 5 mb
							</small>
						</div>
						<div v-if="image_url== ''" class="mt-1">
							<small class="text-danger font-monospace">2. Accepted Extensions (jpg or jpeg, png)</small> 
						</div>
						<p
							v-if="$v.forms.file.$error"
							class="text-left"
							style="color: red"
						>
							Post must have an image
						</p>
					</div>
					<div class="preview">
						<img
						:src="image_url"
						width="40px"
						class="mx-2"
						height="40px"
						v-if="image_url"
						/>
					</div>
				</div>
				<div class="inner-video-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'videoOld'">
					<div class="video-input">
						<label class="ivu-btn ivu-btn-primary" for="postMedia" icon="ios-camera-outline">
							Add Video
							<input
								type="file"
								id="postMedia"
								accept="video/*"
								@change="uploadMedia"
								style="display: none"
							/>
						</label>
						<div v-if="mediaSrc == ''" class="mt-1">
							<small class="text-danger font-monospace">1. Max upload size: 50 mb</small>
						</div>
						<div v-if="mediaSrc== ''" class="mt-1">
							<small class="text-danger font-monospace">2. Accepted Extensions (mov, mp4)</small>
						</div>
						<p
							v-if="$v.forms.media.$error"
							class="text-left"
							style="color: red"
						>
							Post must have an video
						</p>
					</div>
					<div class="video-video-preview">
						<video width="200" controls v-if="mediaSrc">
							<source :src="mediaSrc" id="media_preview">
							Your browser does not support HTML5 video.
						</video>
					</div>
				</div>
				<div class="inner-video-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'video'">
					<div class="col-md-12 video-upload-div">
						<div class="d-flex justify-content-between">
							<div class="">
								<h6>Upload Video By Platforms</h6>
							</div>	
							<div class="">
								<button class="btn btn-info btn-sm text-white" type="button" @click="changeVideoMode" ><i class="bi bi-gear me-1"></i> 
									{{ videoValidateMode == 'SIMPLE' ? 'Switch to advance mode' : 'Switch to simple mode' }}
								</button>
							</div>
						</div>
						<div class="row" for="simple_mode" :class="[videoValidateMode != 'SIMPLE' ? 'd-none' : '']">
							<div class="col-md-4">
								<label for="">
									Connected Accounts
								</label>
								<template>
									<div class="d-flex align-items-center flex-wrap platforms">
										<template v-if="user.ayr_connected_accounts">
											<div class="form-check" v-for='(item, index) of user.ayr_connected_accounts ? JSON.parse(user.ayr_connected_accounts) : []' :key="index">
												<div v-tooltip="$ucFirst(item)" class="platform-icons">
													<label>
														<input 
															type="checkbox"
															@change="handleCheckSocialAccounts"
															:value="item"
															:checked="selectedPlatforms[videoValidateMode].includes(item) ? true :false"
															/>
														<span>
															<img :src="getPlatformImage(item)" alt="">
														</span>
													</label>
												</div>
											</div>
										</template>
										<template v-else>
											<span class="text-danger">Connected social media accounts not found</span>
										</template>
									</div>
								</template>
							</div>
							<div class="col-md-8">
								<label for="">
									Validate Video
								</label>
								<div class="video-validate-outer-area">
									<div class="video-validate-inner-area" @drop="dropHandler" @dragover="dragOverHandler" @dragleave="dropLeave">
										<template v-if="!validateVideo[videoValidateMode]">
											<div class="w-100 d-flex justify-content-center mt-2" v-if="isDragOver">
												<label for="" style="font-size:xxx-large">Drop the file</label>
											</div>
											<div class="w-100 d-flex justify-content-center" v-if="!isDragOver">
												<button class="btn btn-primary btn-sm d-flex" type="button" @click="openVideoUploader">
													<i class="bi bi-upload me-1" v-if="!validateLoader"></i>
													<Loading style="margin-top: 2px;" v-if="validateLoader"/>
													Validate Video
												</button>
											</div>	
											<div class="w-100 d-flex justify-content-center mt-2" v-if="!isDragOver">
												<label for="">--OR--</label>
											</div>
											<div class="w-100 d-flex justify-content-center mt-1" v-if="!isDragOver">
												<label for="">Drag & Drop video inside to validate video for selected platforms</label>
											</div>
										</template>
										<template v-else-if="validateVideo[videoValidateMode]">
											<div class="w-100 d-flex justify-content-center position-relative">
												<i @click="deleteValidateVideo" class="bi bi-trash del_btn_video"></i>
												<video :src="validateVideo[videoValidateMode]" style="height: 220px;" controls></video>
											</div>
											<template v-if="successPlatforms[videoValidateMode].length > 0">
												<div class="w-100 d-flex justify-content-center mt-3">
													<label for=""><strong>Successfully validated for following platforms</strong></label>
												</div>
												<div class="w-100 d-flex justify-content-center mt-1">
													<img 
														v-for="platform in successPlatforms[videoValidateMode]"
														:src="getPlatformImage(platform)" 
														style="height:20px"
														class="ms-1 me-1"
														>
												</div>
											</template>
											<template v-if="errorPlatforms[videoValidateMode].length > 0">
												<div class="w-100 d-flex justify-content-center mt-3">
													<label for=""><strong>Failed to validate these following platform (hover to check the error)</strong></label>
												</div>
												<div class="w-100 d-flex justify-content-center mt-1">
													<img
														v-for="platform in errorPlatforms[videoValidateMode]" 
														:src="getPlatformImage(platform)" 
														v-tooltip="validateErrors[videoValidateMode][platform] ? validateErrors[videoValidateMode][platform] : '-'"
														class="ms-1 me-1"
														style="height:20px"
														>
												</div>
											</template>
										</template>
									</div>
								</div>
							</div>
						</div>
						<div class="row" for="advanced_mode" :class="[videoValidateMode != 'ADVANCED' ? 'd-none' : '']">
							<div class="col-md-2">
								<label for="" class="mb-2">
									Connected Accounts
								</label>
								<template v-if="user.ayr_connected_accounts">
									<div class="list-group box-shadow mt-1" v-for="platform in user.ayr_connected_accounts ? JSON.parse(user.ayr_connected_accounts) : []">
										<button 
											@click="setRecommendation(platform)"
											type="button"
											class="list-group-item list-group-item-action" 
											:class="[selectedRecommendations.slug == platform.toLowerCase() ? 'active' : '']"
											>
											{{ platform == 'gmb' ? 'Google Business' : platform == 'fbg' ? 'Facebook Business' : $ucFirst(platform)}}
										</button>
									</div>
								</template>
								<template v-else>
									<span class="text-danger">Connected social media accounts not found</span>
								</template>
							</div>
							<div class="col-md-5">
								<button 
									@click="deleteValidateVideo"
									v-if="selectedRecommendations.slug && validateVideo[videoValidateMode][selectedRecommendations.slug]"
									class="btn btn-danger w-100 mb-2 box-shadow" 
									type="button">
									<i class="bi bi-trash me-1" v-if="!validateLoader"></i>
									<Loading style="margin-top: 2px;" v-if="validateLoader"/>
									Delete
								</button>
								<button 
									v-else
									class="btn w-100 mb-2 box-shadow" 
									@click="openVideoUploader"
									type="button">
									<i class="bi bi-upload me-1" v-if="!validateLoader"></i>
									<Loading style="margin-top: 2px;" v-if="validateLoader"/>
									Validate & Upload Video
								</button>
								<div class="inner-video-upload-section-advanced">
									<label for=""><strong>Recommendations</strong></label>
									<ul style="list-style: none; padding-left: 0;">
										<li v-for="item in selectedRecommendations.data" v-html="item"></li>
									</ul>
								</div>
								<template v-if="successPlatforms[videoValidateMode].length > 0">
									<div class="w-100 d-flex justify-content-center mt-3">
										<label for=""><strong>Successfully validated for following platforms</strong></label>
									</div>
									<div class="w-100 d-flex justify-content-center mt-1">
										<img 
											v-for="platform in successPlatforms[videoValidateMode]"
											:src="getPlatformImage(platform)" 
											style="height:20px"
											class="ms-1 me-1"
											>
									</div>
								</template>
								<template v-if="errorPlatforms[videoValidateMode].length > 0">
									<div class="w-100 d-flex justify-content-center mt-3">
										<label for=""><strong>Failed to validate these following platform (hover to check the error)</strong></label>
									</div>
									<div class="w-100 d-flex justify-content-center mt-1">
										<img
											v-for="platform in errorPlatforms[videoValidateMode]" 
											:src="getPlatformImage(platform)" 
											v-tooltip="validateErrors[videoValidateMode][platform] ? validateErrors[videoValidateMode][platform] : '-'"
											class="ms-1 me-1"
											style="height:20px"
											>
									</div>
								</template>
							</div>
							<div class="col-md-5">
								<label for="">
									Validate Video
								</label>
								<video 
									controls 
									style="width: 485px; height: 209px;" 
									v-if="validateVideo[videoValidateMode][selectedRecommendations.slug]" 
									:src="validateVideo[videoValidateMode][selectedRecommendations.slug]"
									>
								</video>
								<div v-else style="
									display:flex;
									justify-content:center;
									padding-top:90px;
									background-color: black;
									color: white;
									width: 485px;
									height: 209px;
								">
									<span>Please Upload a Video</span>
								</div>
							</div>
						</div>
						<input type="file" class="validate_video_upload d-none" @change="validateVideoSimple"/>
					</div>
				</div>
				<div class="mt-2" v-if="forms.mediaType =='url'">
					<label class="w-100" for="postMedia" icon="ios-camera-outline">
						<input
							type="url"
							placeholder="Embed url"
							class="form-control"
							:class="{ 'is-invalid': (!isValid && embed_url!='') || $v.forms.embed_url.$error }"
							v-model="embed_url"
							@input="uploadMedia"
						/>
						<p v-if="!isValid && embed_url!=''" class="mt-1 text-danger">Please enter a valid url.</p>
						<p v-if="$v.forms.embed_url.$error" class="mt-1 text-left" style="color: red">
							Post must have an Embed video Url
						</p>
					</label>
					<div v-if="embed_url== ''" class="mt-1">
						<small class="text-danger font-monospace">
							1. URL with extension mp4, mov, jpg or jpeg, png will be embedded on social media. For other type of URL, it will be attached to the post as a link.
						</small>
					</div>
					<div v-if="embed_url== ''">
						<small class="text-danger font-monospace">
							2. For Image URL, size of the file should not be more than 5 MB
						</small>
					</div>
					<div v-if="embed_url== ''">
						<small class="text-danger font-monospace">
							3. For video URL,  size of the file should not be more than 50 MB
						</small>
					</div>
				</div>
				</div>
			</div>
			<div class="mb-2">
				<quill-editor
					v-model="forms.content"
					ref="myQuillEditor"
					@change="onEditorChange($event)"
					:class="{ 'is-invalid': $v.forms.content.$error }"
					:options="editorOption"
				/>
				<p v-if="$v.forms.content.$error" class="text-left" style="color: red">
					Post must have a description
				</p>
			</div>
			<div class="mt-3">
				<button class="ivu-btn ivu-btn-primary" @click="save">
					Save
					<span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
				</button>
				<button  v-if="user.ayr_connected_accounts"
					class="ivu-btn ivu-btn-primary mx-2"
					:class="isLoading1 ? 'disabled' : ''"
					@click="saveAndSchedule"
					>
						Save and Schedule
						<span
							v-if="isLoading1"
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
							>
						</span>
					</button>
					<button class="ivu-btn ivu-btn-secondary mx-2" @click="Cancel">
						Cancel
					</button>
			</div>
		</form>
		<save-and-schedule
			:data="user"
			:feed_id="feedId"
			:load="isLoading1"
		>
		</save-and-schedule>
	</div>
</template>

<script>
import axios from "axios";
import SaveAndSchedule from "./saveAndSchedule.vue";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import "iview/dist/styles/iview.css";
import { required } from "vuelidate/lib/validators";
import Loading from "./reviews/loading.vue";

import yelpImage from "../../assets/yelp.png"
import googleImage from "../../assets/google.png"
import facebookImage from "../../assets/facebook.png"
import twitterImage from "../../assets/twitter.png"
import linkedinImage from "../../assets/linkedin.png"
import instagramImage from "../../assets/instagram.png"
import pinterestImage from "../../assets/pinterest.png"
import telegramImage from "../../assets/telegram.png"
import googleBusinessImage from "../../assets/google_business.png"
import facebookGroupsImage from "../../assets/facebook_groups.png"

export default {
	name: "AddPost",
	components: {
		quillEditor,
		SaveAndSchedule,
		Loading
	},
	watch:{
		videoValidateMode:function(val,oldval){
			if(val == 'ADVANCED')
			{
				if(!(this.selectedRecommendations && this.selectedRecommendations.slug))
				{
					if(this.user && this.user.ayr_connected_accounts)
					{
						let accounts = JSON.parse(this.user.ayr_connected_accounts)
						if(accounts && accounts.length > 0)
						{
							this.setRecommendation(accounts[0])
						}
					}
				}
			}
		}
	},
	data() {
		return {
			regex:"[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}(.[a-z]{2,4})?\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?",
			time1: "",
			feedId: "",
			con: "",
			user: {},
			editorOption: {
				placeholder: "Type your post...",
				readOnly: true,
				theme: "snow",
				modules: {
				toolbar: [
						['bold', 'italic'],                 
						['clean']
					],
			}
			},
			isLoading: false,
			isLoading1: false,
			isValid:'',
			embed_url:'',
			showEmbedUrlMsg: false,
			forms: {
				title: "",
				file: "",
				media: "",
				embed_url:"",
				mediaType: "image",
				content: "",
			},
			image_url: "",
			mediaSrc: "",
			typeOptions: [
				{
				name: "external",
				value: "external",
				},
				{
				name: "education",
				value: "education",
				},
				{
				name: "inspirational",
				value: "inspirational",
				},
				{
				name: "funny",
				value: "funny",
				},
				{
				name: "observances",
				value: "events",
				},
			],
			allRecommendations:[
				{
					"platform":"GMB",
					"slug":"gmb",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Duration Max</strong> : Up to 30 seconds long',
						'<strong>Video Resolution</strong> : 720p or higher.'
					]
				},
				{
					"platform":"X",
					"slug":"twitter",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Duration </strong>: Must be between 0.5 seconds and 140 seconds.',
						'<strong>Video Resolution </strong>: 1280x720 px.'
					]
				},
				{
					"platform":"Facebook",
					"slug":"facebook",
					"data":[
						'<strong>Max video size </strong> : 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Video Resolution </strong>: 1280 x 720 for landscape and portrait.'
					]
				},
				{
					"platform":"Facebook Business",
					"slug":"fbg",
					"data":[
						'<strong>Max video size </strong> : 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Video Resolution </strong>: 1280 x 720 for landscape and portrait.'
					]
				},
				{
					"platform":"LinkedIn",
					"slug":"linkedin",
					"data":[
						'<strong>Max video size </strong> : 50 MB',
						'<strong>Supported formats</strong> : MP4',
						'<strong>Duration max </strong>: 30 minutes.',
						'<strong>Duration min </strong>: 3 seconds.',
						'<strong>Aspect ratio </strong>: Must be between 1:2.4 and 2.4:1.'
					]
				},
				{
					"platform":"Telegram",
					"slug":"telegram",
					"data":[
						'<strong>Max video size </strong>: 20 MB',
						'<strong>Supported formats </strong>: MP4'
					]
				},
				{
					"platform":"Instagram",
					"slug":"instagram",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats</strong> : MP4',
						'<strong>Duration max </strong>: 15 minutes.',
						'<strong>Duration min </strong>: 3 seconds.',
						'<strong>Video Resolution </strong>: 1920'
					]
				},
				{
					"platform":"Pinterest",
					"slug":"pinterest",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Duration max </strong>: 15 minutes.',
						'<strong>Duration min </strong>: 4 seconds.',
						'<strong>Video Resolution </strong>: 1280 x 720 for landscape and portrait'
					]
				}
			],
			selectedRecommendations:{},
			videoValidateMode:'SIMPLE',//SIMPLE|ADVANCED,
			validateVideo:{
				"SIMPLE":"",
				"ADVANCED":{},
			},//https://api-socialfeed.rudraserver.com/uploads/temp/dummy_video.mp4
			selectedPlatforms:{
				"SIMPLE":[],
				"ADVANCED":[],
			},
			successPlatforms:{
				"SIMPLE":[],
				"ADVANCED":[],
			},
			errorPlatforms:{
				"SIMPLE":[],
				"ADVANCED":[],
			},
			validateErrors:{
				"SIMPLE":{},
				"ADVANCED":{},
			},
			validateLoader:false,
			isDragOver:false
		};
	},
	validations() {
		if (this.forms.mediaType == "image" && this.image_url == "")
		{
			return {
				forms: {
					title: { required },
					file: { required },
					embed_url:{},
					media: {},
					mediaType: { required },
					content: { required },
				}
			};
		}
		else if (this.forms.mediaType == "video" && this.mediaSrc == "")
		{
			return {
				forms: {
					title: { required },
					file: {},
					embed_url:{},
					//media: { required },
					mediaType: { required },
					content: { required },
				}
			};
		}
		else if(this.forms.mediaType =="url" && this.embed_url == "")
		{
			return {
				forms: {
					title: { required },
					file: {},
					embed_url:{required},
					media: {  },
					mediaType: { required },
					content: { required },
				}
			};
		}
		else
		{
			return {
				forms: {
					title: { required },
					file: {},
					embed_url:{},
					media: {},
					mediaType: { required },
					content: { required },
				}
			};
		}
	},
	created() {
	axios
		.get(process.env.VUE_APP_BASEURL + "/get-user-data", {
		headers: {
			"Content-type": "application/json",
			token: `${localStorage.getItem("token")}`,
		},
		})
		.then((res) => {
		if (res.status) {
			this.user = res?.data?.user_data;
			if(this.user && this.user.ayr_connected_accounts)
			{
				let accounts = JSON.parse(this.user.ayr_connected_accounts);
				if(accounts.length > 0)
				{
					this.selectedPlatforms.SIMPLE = accounts;
				}
			}
		}
		})
		.catch(() => {});
	},
	mounted:function(){
		let removeEventListener = function(){
			document.removeEventListener("contextmenu",window.$disableRightClick, false);
		}
		window.onload = removeEventListener
		removeEventListener()
	},
	beforeRouteLeave:function(to, from , next) {
		document.addEventListener("contextmenu",window.$disableRightClick, false);
		next()
	},
	methods: {
		isURLValid(inputUrl) {
			var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			this.isValid = !!pattern.test(inputUrl);
		},
		saveAndSchedule(e) {
			e.preventDefault();

			
			this.$v.$touch();
			if ( this.embed_url != '' && this.isValid == false ){
			return false;
			}
			this.isLoading1 = true;

			//	console.log('sds',this.con,this.file)
			if (!this.$v.$invalid) {
			let formData = new FormData();
			if (this.forms.file != "") {
				formData.append("image", this.forms.file);
			}
			if (this.forms.media != "") {
				formData.append("media", this.forms.media);
			}
			if(this.embed_url!=''){
				formData.append("embed_url", this.embed_url);
			}
			formData.append("title", this.forms.title);
			formData.append("category", "custom");
			formData.append("mediaType", this.forms.mediaType);
			formData.append("body", this.con);

			let obj = {
				"validateVideo":this.validateVideo,
				"videoValidateMode":this.videoValidateMode,
				"selectedPlatforms":this.selectedPlatforms,
				"successPlatforms":this.successPlatforms,
				"errorPlatforms":this.errorPlatforms,
				"validateErrors":this.validateErrors,
			}
			formData.append("video_data", JSON.stringify(obj));

			if(this.forms.mediaType == 'video')
			{
				if(this.successPlatforms[this.videoValidateMode].length == 0)
				{
					this.$notify("There is no platform that has validated the video. Please ensure the video is validated by a platform before save and schedule this feed.",'danger',7000)
					return false;
				}
			}

			axios
				.post(process.env.VUE_APP_BASEURL + "/addFeed", formData, {
				headers: {
					"Content-type": "application/json",
					token: `${localStorage.getItem("token")}`,
				},
				})
				.then((res) => {
				//   this.forms.content='';
				//   this.forms.title='';
				//   this.forms.file='';

				this.image_url = "";
				//this.forms.category='';
				this.feedId = res?.data?.feed_id;
				$(`#saveandscheduledModal`).modal("show");
				this.isLoading1 = false;
				//this.resetForm();
				//  this.$v.$reset();
				//   this.$toasted.show(
				//   `Post Added Successfully `,
				//   {
				//     theme: "bubble",
				//     type: "success",
				//     position: "top-center",
				//     duration: 1500,
				//     singleton: true,
				//   }
				// );
				})
				.catch((err) => {
				this.isLoading1 = false;
				if (err?.response?.data?.error_message != "") {
					var error_msg = "";
					if (err.response.data.error_message.image) {
					error_msg = err.response.data.error_message.image[0];
					} else if (err.response.data.error_message.media) {
					error_msg = err.response.data.error_message.media[0];
					} else if (err.response.data.error_message.title) {
					error_msg = err.response.data.error_message.title[0];
					} else if (err.response.data.error_message.body) {
					error_msg = err.response.data.error_message.body[0];
					}
					this.$toasted.show(error_msg, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 4500,
					singleton: true,
					});
				} else {
					this.$toasted.show(`Internal Server Error `, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 1500,
					singleton: true,
					});
				}
				this.isLoading1 = false;
				});
			} else {
			this.isLoading1 = false;
			}
		},
		/* generateHashTags(){
			if(this.con != ""){
				var obj = {
					body : this.con
				}
				axios
				.post(process.env.VUE_APP_BASEURL + "/generateHashtag", obj, {
					headers: {
					"Content-type": "application/json",
					token:`${localStorage.getItem('token')}`
					},
				}).then((res)=>{
					if(res?.data?.success == true){
						this.forms.content = res?.data?.body
					}
				}).catch((err)=>{
				})
			}
		}, */
		onEditorChange({ quill, html, text }) {
			this.con = html;
		},
		addUrl(){

		},
		change(event) {},
		close(event) {},
		upload(event) {
			this.forms.file = event.target.files[0];
		},
		changeMediaType(event) {
			this.mediaSrc = "";
			this.image_url = "";
			this.embed_url=""
			this.forms.media = "";
			this.$v.$reset();
			this.forms.mediaType = event.target.value;
			

			
		},
		uploadMedia(event) {
			if (this.forms.mediaType == "image") {
			this.mediaSrc = "";
			this.embed_url=""
			this.forms.media = "";
			this.forms.file = event.target.files[0];
			var fileReader = new FileReader();
			fileReader.onload = (ev) => {
				this.image_url = ev.target.result;
			};
			fileReader.readAsDataURL(event.target.files[0]);
			}else if(this.forms.mediaType == "url"){
				this.mediaSrc = "";
			this.forms.media = "";
				
			this.isURLValid(this.embed_url)
			//return;
			} 
			else {
			this.forms.file = "";
			this.embed_url=""
			this.forms.media = event.target.files[0];
			this.image_url = "";
			this.mediaSrc = URL.createObjectURL(event.target.files[0]);
			setTimeout(() => {
				$("#media_preview").parent()[0].load();
			}, 300);
			}
		},
		resetForm() {
			var self = this; //you need this because *this* will refer to Object.keys below`

			//Iterate through each object field, key is name of the object field`
			Object.keys(this.forms).forEach(function (key, index) {
			self.forms[key] = "";
			});
		},
		save(e) {
			// TODO
			e.preventDefault();     
			this.$v.$touch();

			if ( this.embed_url != '' && this.isValid == false ){
			return false;
			}

			if (!this.$v.$invalid ) {
			
			this.isLoading = true;

			let formData = new FormData();
			formData.append("title", this.forms.title);
			if (this.forms.file != "") {
				formData.append("image", this.forms.file);
			}
			if (this.forms.media != "") {
				formData.append("media", this.forms.media);
			}
			if(this.embed_url!=''){
				formData.append("embed_url", this.embed_url);
			}
			formData.append("mediaType", this.forms.mediaType);
			formData.append("body", this.con);
			
			let obj = {
				"validateVideo":this.validateVideo,
				"videoValidateMode":this.videoValidateMode,
				"selectedPlatforms":this.selectedPlatforms,
				"successPlatforms":this.successPlatforms,
				"errorPlatforms":this.errorPlatforms,
				"validateErrors":this.validateErrors,
			}
			formData.append("video_data", JSON.stringify(obj));

			if(this.forms.mediaType == 'video')
			{
				if(this.successPlatforms[this.videoValidateMode].length == 0)
				{
					this.isLoading = false;
					this.$notify("There is no platform that has validated the video. Please ensure the video is validated by a platform before saving this feed.",'danger',7000)
					return false;
				}
			}

			axios
				.post(process.env.VUE_APP_BASEURL + "/addFeed", formData, {
				headers: {
					"Content-type": "application/json",
					token: `${localStorage.getItem("token")}`,
				},
				})
				.then((res) => {
				//   this.forms.content='';
				//   this.forms.title='';
				//   this.forms.file='';
				this.image_url = "";
				this.embed_url=""
				//this.forms.category='';
				this.feedId = "";
				this.isLoading = false;
				this.resetForm();
				this.$v.$reset();
				this.$toasted.show(`Post Added Successfully `, {
					theme: "bubble",
					type: "success",
					position: "top-center",
					duration: 4500,
					singleton: true,
				});
				this.$router.push("/dashboard");
				})
				.catch((err) => {
					this.isLoading = false;
				if (err?.response?.data?.error_message != "") {
					var error_msg = "";
					if (err.response.data.error_message.image) {
					error_msg = err.response.data.error_message.image[0];
					} else if (err.response.data.error_message.media) {
					error_msg = err.response.data.error_message.media[0];
					} else if (err.response.data.error_message.title) {
					error_msg = err.response.data.error_message.title[0];
					} else if (err.response.data.error_message.body) {
					error_msg = err.response.data.error_message.body[0];
					}
					this.$toasted.show(error_msg, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 4500,
					singleton: true,
					});
				} else {
					this.$toasted.show(`Internal Server Error `, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 4500,
					singleton: true,
					});
				}
				this.isLoading = false;
				});
			} else {
			this.isLoading = false;
			}
		},
		Cancel() {
			this.$router.push("/dashboard");
		},
		setRecommendation:function(platform){
			this.allRecommendations.map((item) => {
				if(platform.toLowerCase() == item.slug)
				{
					this.selectedRecommendations = item;
					return this.selectedRecommendations		
				}
			})
			this.selectedPlatforms[this.videoValidateMode]= [platform]
		},
		changeVideoMode:function(){
			this.videoValidateMode = this.videoValidateMode == 'SIMPLE' ? 'ADVANCED' : 'SIMPLE';
		},
		getPlatformImage:function (platform){
			let images = {
				'yelp':yelpImage,
				'google':googleImage,
				'facebook':facebookImage,
				'instagram':instagramImage,
				'twitter':twitterImage,
				'linkedin':linkedinImage,
				'pinterest':pinterestImage,
				'telegram':telegramImage,
				'gmb':googleBusinessImage,
				'fbg':facebookGroupsImage
			}
			return platform ? images[platform] : '';
		},
		openVideoUploader:function(){
			if(this.selectedPlatforms[this.videoValidateMode].length > 0)
			{
				$('body').find('input.validate_video_upload').click()
			}
			else
			{
				this.$notify('Please Select Atleast one socail media account','danger')
			}
		},
		validateVideoSimple:function(e, file){
			this.validateLoader = true
			let temp = this
			let files = e ? e.target.files : [file];
			let reader = new FileReader()
			reader.onload = async (event) => {
				let base64 = event.target.result;
				let extension;
				if(base64)
				{
					let sp = base64.split(';');
					if(sp[0])
					{
						sp = sp[0].split('/')
						if(sp[1])
						{
							extension = sp[1]
						}
					}
				}

				let body = {
					'video':base64,
					'platform':temp.selectedPlatforms[temp.videoValidateMode],
					'video_name':`${Math.random()+2}.${extension}`
				}

				temp.sendRequestToValidateVideo(body)
				$('body').find('input.validate_video_upload').val('')
			}
			reader.readAsDataURL(files[0])
		},
		sendRequestToValidateVideo:function(body){
			this.$helper.postRequest('users/post-templates/validate-video',{
				'video': body.video,
				"platform" : body.platform,
				"video_name" : body.video_name,
			}).then((resp) => {
				this.validateLoader = false
				if(resp.media)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.validateVideo[this.videoValidateMode] = resp.media	
					}
					else
					{
						this.validateVideo[this.videoValidateMode][body.platform[0]] = resp.media
					}
				}

				if(resp.errorPlatforms && resp.errorPlatforms.length > 0)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.errorPlatforms[this.videoValidateMode] = resp.errorPlatforms
					}
					else
					{
						this.errorPlatforms[this.videoValidateMode] = [
							...this.errorPlatforms[this.videoValidateMode],
							...resp.errorPlatforms
						];
					}
				}

				if(resp.successPlatforms && resp.successPlatforms.length > 0)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.successPlatforms[this.videoValidateMode] = resp.successPlatforms
					}
					else
					{
						this.successPlatforms[this.videoValidateMode] = [
							...this.successPlatforms[this.videoValidateMode],
							...resp.successPlatforms
						]
					}
				}

				if(resp.errors)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.validateErrors[this.videoValidateMode] = resp.errors;
					}
					else
					{
						this.validateErrors[this.videoValidateMode] = {
							...this.validateErrors[this.videoValidateMode],
							...resp.errors
						}
					}
				}

				if(resp.error)
				{
					this.$notify(resp.error,'danger',5000)	
				}

				if(resp.success)
				{
					this.$notify(resp.success, "success",5000)	
				}
				
				if(!resp.status)
				{
					this.$notify(resp.message,'danger')
				}
				
			}).catch(() => {
				this.validateLoader = false
			})
		},
		handleCheckSocialAccounts:function(e){
			let {checked, value} = e.target;
			if(checked)
			{
				this.selectedPlatforms[this.videoValidateMode].push(value)
			}
			else
			{
				let index = this.selectedPlatforms[this.videoValidateMode].indexOf(value);
				if(index >= 0)
				{
					this.selectedPlatforms[this.videoValidateMode].splice(index,1);
				}
			}
		},
		deleteValidateVideo:function(){
			if(confirm("Are you sure? You want to delete this video?"))
			{
				let path;
				if(this.videoValidateMode == 'SIMPLE')
				{
					path = this.validateVideo[this.videoValidateMode].split('uploads');
					path = `uploads/${path[1]}`
				}
				else
				{
					if(this.validateVideo[this.videoValidateMode][this.selectedRecommendations.slug])
					{
						path = this.validateVideo[this.videoValidateMode][this.selectedRecommendations.slug].split('uploads');
						path = `uploads/${path[1]}`
					}
				}

				if(path)
				{
					this.$helper.getRequest(`users/post-templates/delete-validate-video?path=${path}`).then((resp) => {
						if(resp.status)
						{
							if(this.videoValidateMode == 'SIMPLE')
							{
								this.validateVideo[this.videoValidateMode] = '';
								this.errorPlatforms[this.videoValidateMode] = []
								this.successPlatforms[this.videoValidateMode] = []
								this.selectedPlatforms[this.videoValidateMode] = []
							}
							else
							{
								this.validateVideo[this.videoValidateMode][this.selectedRecommendations.slug] = '';
								let indexOf = this.errorPlatforms[this.videoValidateMode].indexOf(this.selectedRecommendations.slug)
								if(indexOf >= 0)
								{
									this.errorPlatforms[this.videoValidateMode].splice(indexOf,1)
								}

								indexOf = this.successPlatforms[this.videoValidateMode].indexOf(this.selectedRecommendations.slug)
								if(indexOf >= 0)
								{
									this.successPlatforms[this.videoValidateMode].splice(indexOf,1)
								}
							}
							
							this.$notify(resp.message)
						}
						else
						{
							this.$notify(resp.message ? resp.message : 'Something went wrong please try in some time','danger')
						}
					})
				}
				else
				{
					this.$notify('Something went wrong please try in some time','danger')
				}
			}
			else
			{
				return false;
			}
		},
		dropHandler:function(ev){
			if(!this.validateVideo[this.videoValidateMode])
			{
				this.isDragOver = false
				console.log("File(s) dropped");
				ev.preventDefault();

				if(ev.dataTransfer.items)
				{
					[...ev.dataTransfer.items].forEach((item, i) => {
						// If dropped items aren't files, reject them
						if (item.kind === "file")
						{
							let file = item.getAsFile();
							this.validateVideoSimple(null, file)
						}
					});
				}
				else
				{
					[...ev.dataTransfer.files].forEach((file, i) => {
						console.log(`… file[${i}].name = ${file.name}`);
						this.validateVideoSimple(null, file)
					});
				}
			}
		},
		dragOverHandler: function(ev) {
			if(!this.validateVideo[this.videoValidateMode])
			{
				this.isDragOver = true
				console.log("File(s) in drop zone");

				// Prevent default behavior (Prevent file from being opened)
				ev.preventDefault();
			}
		},
		dropLeave:function(){
			this.isDragOver = false
		}
	}
};
</script>

<style>
.cat-input,
.title-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.post-inputs {
  display: grid;
  width: 90%;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  padding-bottom: 15px;
  padding-top: 15px;
  /* padding: 20px; */
}

.inner-image-input {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-actions-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.ivu-icon-ios-camera-outline {
  font-size: 16px;
}
.btn-delete {
  color: #2d8cf0 !important;
  border: 1px solid #2d8cf0 !important;
}
.btn-delete:hover {
  color: red !important;
  border: 1px solid red !important;
}

.is-invalid1 {
  border: 1px solid red;
  border-radius: 5px;
}
.ql-editor {
  height: 30vh;
}
</style>

<style scoped>
.video-upload-div{
	padding:3%;
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	border-color: #dbd3d3;
}

.video-validate-outer-area {
	padding: 2%;
	border-radius: 5px;
	background-color: rgb(179, 213, 226);
}

.video-validate-inner-area{
	width: 100%;
	border-style: dashed;
	border-width: 2px;
	border-radius: 5px;
	padding: 5%;
}

.inner-video-upload-section-advanced{
	border-radius: 5px;
	width: 100%;
	background-color: skyblue;
	padding: 5%;
	color: rgb(5, 69, 95);
}

.box-shadow{
	box-shadow:0px 2px 24px -5px	
}

.platform-icons,
.platform-icons label{
	cursor: pointer;
}

.platform-icons {
	margin: 4px;
	border-radius: 4px;
	border: 1px solid #c7c7c7;
	overflow: hidden;
	float: left;
	box-shadow: 0px 0px 4px
}

.platform-icons label {
	float: left;
    width: 35px;
    height: 35px;
}

.platform-icons label input {
	position: absolute;
    display: none;
    color: #fff !important;
}

.platform-icons label input + span {
    color: #b7b7b7;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 0;
}

.platform-icons img {
	height: 20px !important;
    width: 20px !important;
}

.platform-icons input:checked + span{
	background-color: #d1d8e9;
}

.del_btn_video {
	position: absolute;
	top:0;
	right:0px;
	width:35px;
	height:35px;
	color:red;
	cursor: pointer;
	font-size: 18px;
	padding-top: 5px;
	padding-left: 8px;
	border-radius: 50%;
	background-color: white;
	box-shadow: 2px 2px 10px 2px black;
}

.del_btn_video:hover {
	box-shadow: 2px 2px 8px 2px black;
}
</style>