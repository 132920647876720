import smartTags from '../../components/admin/smartTags/index.vue'

let smartTagsRoutes = [
    {
        path: '/admin/smart-tags',
        name: 'SmartTags',
        component: smartTags,
        meta: {
            admin: true,
            auth: true,
            authOnly: true,
            showDashLayout: true,
            adminOnly: true,
            roles: ['Admin'],
        },
    },
]

export default smartTagsRoutes
